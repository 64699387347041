/* eslint-disable prettier/prettier */
import { getMe, login, register } from '../api/auth';
import { getListWorkspace } from '../api/workSpaceApi';
import {
	loginFailed,
	loginStart,
	loginSuccess,
	registerFailed,
	registerStart,
	registerSuccess,
} from './authSlice';
import { getProfileFailed, getProfileStart, getProfileSuccess } from './userSlice';
import { getWorkSpaceSuccess, getWorkSpaceValueSuccess } from './workspaceSlice';
import { startVideoPlayers, stopVideoPlayers } from './youtubeplayerSlice';

export const loginUser = async (user, dispatch, navigate) => {
	dispatch(loginStart());
	try {
		const res = await login(user);
		dispatch(loginSuccess(res.data));
		navigate('/');
	} catch (err) {
		dispatch(loginFailed());
	}
};
export const registerUser = async (user, dispatch, navigate) => {
	dispatch(registerStart());
	try {
		const res = await register(user);
		if (res.status === 200) {
			dispatch(registerSuccess());
			navigate('/');
		} else {
			dispatch(registerFailed());
		}
	} catch (err) {
		dispatch(registerFailed());
	}
};
export const getUser = async (accessToken, dispatch) => {
	dispatch(getProfileStart());
	try {
		const res = await getMe();
		if (res.status == 200) {
			dispatch(getProfileSuccess(res.data));
		} else {
			localStorage.removeItem('accessToken');
			localStorage.removeItem('workspaceId');
		}
	} catch (err) {
		dispatch(getProfileFailed());
		// localStorage.removeItem('accessToken');
		localStorage.removeItem('workspaceId');
	}
};
export const getWorkSpace = async (accessToken, dispatch) => {
	const input = {
		per_page: 2000,
	};
	try {
		const res = await getListWorkspace(input);
		const id = Number(window.localStorage.getItem('workspaceId'));
		const IdArray = res.data.data.map((item) => item.id);
		dispatch(getWorkSpaceSuccess(res.data.data));
		if (IdArray.includes(id)) {
			dispatch(getWorkSpaceValueSuccess(id));
		} else {
			dispatch(getWorkSpaceValueSuccess(res.data.data[res.data.data.length - 1].id));
		}
	} catch (err) {
		console.log(err);
	}
};
export const startVideoPlayer = (video, dispatch) => {
	dispatch(startVideoPlayers(video));
};

export const stopVideoPlayer = (dispatch) => {
	dispatch(stopVideoPlayers());
};
