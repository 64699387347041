import * as React from 'react';
import { SVGProps } from 'react';

const SvgCustomTelegram = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height='1em'
		viewBox='0 0 176 176'
		width='1em'
		xmlns='http://www.w3.org/2000/svg'
		className='svg-icon'
		fill='currentColor'

		{...props}>
		<g data-name='Layer 2'>
			<path d='M123.33 62.35 78.7 98.83l-2.1 1.72a2.27 2.27 0 0 0-.84 1.48l-.47 3.88-1.29 10.9a.5.5 0 0 1-1 .09L69.37 106l-3.75-11.15a2.24 2.24 0 0 1 1.08-2.66l46.44-26.62 8.74-5c1.27-.74 2.57.86 1.45 1.78z' />
			<path d='M152 0H24A24 24 0 0 0 0 24v128a24 24 0 0 0 24 24h128a24 24 0 0 0 24-24V24a24 24 0 0 0-24-24zm-9.11 50.94-17.18 75.91c-.81 3.56-5.33 5.17-8.5 3l-25.94-17.6-13.21 12.49a4.54 4.54 0 0 1-7.32-1.62l-4.77-14-4.77-14-25.57-7a3.32 3.32 0 0 1-.29-6.41l98.78-35.59 1.82-.65c3.83-1.34 7.79 1.76 6.95 5.47z' />
		</g>
	</svg>
);

export default SvgCustomTelegram;
