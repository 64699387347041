/* eslint-disable prettier/prettier */
import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { ThemeProvider } from 'react-jss';
import { ReactNotifications } from 'react-notifications-component';
import { useFullscreen, useWindowSize } from 'react-use';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import { TourProvider } from '@reactour/tour';
import ThemeContext from '../contexts/themeContext';

import Aside from '../layout/Aside/Aside';
import Wrapper from '../layout/Wrapper/Wrapper';
import Portal from '../layout/Portal/Portal';
import { dashboardMenu, demoPages, layoutMenu, Terms } from '../menu';
import { Toast, ToastContainer } from '../components/bootstrap/Toasts';
import useDarkMode from '../hooks/useDarkMode';
import COLORS from '../common/data/enumColors';
import { getOS } from '../helpers/helpers';
import steps, { styles } from '../steps';
import { getMe } from '../api/auth';
// import { SocketContext, socket } from '../contexts/socket';
import { LoadingProvider, useLoading } from '../contexts/loading';
import { SocketContextProvider } from '../contexts/socket';
import Icon from '../components/icon/Icon';

const App = () => {
	getOS();
	const token = window.localStorage.getItem('accessToken');
	const navigate = useNavigate();
	/**
	 * Dark Mode
	 */
	const { themeStatus, darkModeStatus, setDarkModeStatus } = useDarkMode();

	const theme = {
		theme: themeStatus,
		primary: COLORS.PRIMARY.code,
		secondary: COLORS.SECONDARY.code,
		success: COLORS.SUCCESS.code,
		info: COLORS.INFO.code,
		warning: COLORS.WARNING.code,
		danger: COLORS.DANGER.code,
		dark: COLORS.DARK.code,
		light: COLORS.LIGHT.code,
	};
	const windowsWidth = useWindowSize().width;

	useEffect(() => {
		if (darkModeStatus) {
			document.documentElement.setAttribute('theme', 'dark');
		}
		return () => {
			document.documentElement.removeAttribute('theme');
		};
	}, [darkModeStatus]);
	useEffect(() => {
		setDarkModeStatus(false);
	}, []);
	// useEffect(() => {
	// 	if (token === null) {
	// 		navigate('/login');
	// 	}
	// }, []);
	/**
	 * Full Screen
	 */
	// @ts-ignore
	const { fullScreenStatus, setFullScreenStatus, setAsideStatus } = useContext(ThemeContext);
	const { pathname } = useLocation();

	useEffect(() => {
		if (windowsWidth < Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)) {
			setAsideStatus(false);
		}
		if (pathname == '/inbox') {
			setAsideStatus(false);
		}
	}, [pathname]);
	const ref = useRef(null);
	useFullscreen(ref, fullScreenStatus, {
		onClose: () => setFullScreenStatus(false),
	});

	/**
	 * Modern Design
	 */
	useLayoutEffect(() => {
		if (process.env.REACT_APP_MODERN_DESGIN === 'true') {
			document.body.classList.add('modern-design');
		} else {
			document.body.classList.remove('modern-design');
		}
	});

	//	Add paths to the array that you don't want to be "Aside".
	const withOutAsidePages = [
		demoPages.login.path,
		demoPages.forgotPassword.path,
		,
		demoPages.signUp.path,
		Terms.terms.path,
		Terms.policy.path,
		dashboardMenu.landing.path,
		'/ve-chung-toi',
		'/dich-vu',
		'/affiliate',
		'/bang-gia',
		'/register',
	];

	return (
		<ThemeProvider theme={theme}>
			<SocketContextProvider>
				<ToastProvider components={{ ToastContainer, Toast }}>
					<TourProvider
						steps={steps}
						styles={styles}
						showNavigation={false}
						showBadge={false}>
						<div
							ref={ref}
							className='app'
							style={{
								backgroundColor: fullScreenStatus ? 'var(--bs-body-bg)' : undefined,
								zIndex: fullScreenStatus ? 1 : undefined,
								overflow: fullScreenStatus ? 'scroll' : undefined,
							}}>
							<Routes>
								{withOutAsidePages.map((path) => (
									<Route key={path} path={path} />
								))}
								<Route path='*' element={<Aside />} />
							</Routes>
							<Wrapper />
							{/* <div style={{ position: 'fixed', right: '1.5rem', bottom: '4rem' }}>
								<a href='https://amaicontent.com/huong-dan-su-dung/' target='_blank'>
									<Icon icon='Help' size='2x' color='secondary' />
								</a>
							</div> */}
						</div>
						<Portal id='portal-notification'>
							<ReactNotifications />
						</Portal>
					</TourProvider>
				</ToastProvider>
			</SocketContextProvider>
		</ThemeProvider>
	);
};

export default App;
