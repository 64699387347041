export const homeMenu = {
	intro: { id: 'intro', text: 'Intro', path: '#intro', icon: 'Vrpano', subMenu: null },
	bootstrap: {
		id: 'bootstrap',
		text: 'Bootstrap Components',
		path: '#bootstrap',
		icon: 'BootstrapFill',
		subMenu: null,
	},
	storybook: {
		id: 'storybook',
		text: 'Storybook',
		path: '#storybook',
		icon: 'CustomStorybook',
		subMenu: null,
	},
	formik: {
		id: 'formik',
		text: 'Formik',
		path: '#formik',
		icon: 'CheckBox',
		subMenu: null,
	},
	apex: {
		id: 'apex',
		text: 'Apex Charts',
		path: '#apex',
		icon: 'AreaChart',
		subMenu: null,
	},
};
export const trendingMenu = {
	// trending: {
	// 	id: 'trending',
	// 	text: 'Xu Hướng',
	// 	path: '/trending/facebook',
	// 	subMenu: null,
	// },
	explore: {
		id: 'explore',
		text: 'Khám phá',
		icon: 'Extension',
		path: '',
	},
	trendingfacebook: {
		id: 'trendingfacebook',
		text: 'Facebook Trending',
		path: '/trending/facebook',
		icon: 'TrendingUp',
		subMenu: null,
	},
	analyst: {
		id: 'analyst',
		text: 'Phân tích',
		icon: 'Extension',
		path: '',
	},
	analyticsSystem: {
		id: 'analyticsSystem',
		text: 'Tổng quan',
		path: '/System',
		icon: 'Info',
		subMenu: null,
	},
	analytics: {
		id: 'analytics',
		text: 'Báo cáo chi tiết',
		path: 'analytics',
		icon: 'Analytics',
		subMenu: null,
	},
	competitor: {
		id: 'competitor',
		text: 'Đối thủ',
		path: 'competitor',
		icon: 'PersonSearch',
		subMenu: null,
	},
	// trendinginstagram: {
	// 	id: 'trendinginstagram',
	// 	text: 'Instagram Trending',
	// 	path: '/trending/instagram',
	// 	icon: 'TrendingUp',
	// 	subMenu: null,
	// }
};
export const adminMenu = {
	adminManger: {
		id: 'admin',
		text: 'ADMIN MANAGER',
		path: 'admin',
		icon: 'AdminPanelSettings',
		subMenu: {
			adminAnalyst: {
				id: 'adminAnalyst',
				text: 'Thống kê hệ thống',
				path: 'admin/adminAnalyst',
				// icon: 'ManageAccounts',
			},
			notifications: {
				id: 'notifications',
				text: 'Cài đặt thông báo',
				path: 'admin/notifications',
				// icon: 'payment',
			},
			category: {
				id: 'category',
				text: 'Gắn danh mục target',
				path: 'admin/category',
				// icon: 'Category',
			},
			usermanger: {
				id: 'usermanager',
				text: 'Quản lý người dùng',
				path: 'admin/usermangement',
				// icon: 'ManageAccounts',
			},
			workspace: {
				id: 'workspace',
				text: 'Quản lý workspace',
				path: 'admin/workspace',
				// icon: 'Subscript',
			},
			account: {
				id: 'account',
				text: 'Danh sách tài khoản đăng',
				path: 'admin/account',
				// icon: 'Subscript',
			},
			autofb: {
				id: 'autofb',
				text: 'Danh sách nhóm tự động',
				path: 'admin/autofb',
				// icon: 'Subscript',
			},
			paymenthistory: {
				id: 'paymenthistory',
				text: 'Lịch sử giao dịch',
				path: 'admin/historypayment',
				// icon: 'payment',
			},
			managermentwithdraw: {
				id: 'managermentwithdraw',
				text: 'Quản lý rút tiền',
				path: 'admin/managermentwithdraw',
				// icon: 'ManageAccounts',
			},

			// fbpost: {
			// 	id: 'fbpost',
			// 	text: 'Facebook Post',
			// 	path: 'admin/fbpost',
			// 	// icon: 'Subscript',
			// },

			config: {
				id: 'config',
				text: 'Cài đặt',
				path: 'admin/config',
				// icon: 'Category',
			},
			paymentMethod: {
				id: 'paymentMethod',
				text: 'Phương thức nạp tiền',
				path: 'admin/paymentMethod',
				// icon: 'Subscript',
			},
			bannerRef: {
				id: 'bannerRef',
				text: 'Quản lý banner affilate',
				path: 'admin/bannerRef',
				// icon: 'payment',
			},
			extension: {
				id: 'extension',
				text: 'Extension',
				path: 'admin/extension',
				// icon: 'Extension',
			},

			script: {
				id: 'script',
				text: 'SCRIPT',
				path: 'admin/script',
				// icon: 'Subscript',
			},
		},
	},
};
export const dashboardMenu = {
	// workspace: {
	// 	id: 'workspace',
	// 	text: 'Không gian làm việc',
	// 	path: '/workspace',
	// 	icon: 'Workspaces',
	// 	subMenu: null,
	// },
	allpost: {
		id: 'allpost',
		text: 'Đăng bài',
		icon: 'Extension',
		path: '',
	},
	landing: {
		id: 'landing',
		text: 'Landing Page',
		path: '/',
		icon: 'Workspaces',
		subMenu: null,
	},
	facebook: {
		id: 'facebook',
		text: 'Tài khoản',
		path: 'account/*',
		icon: 'ManageAccounts',
		subMenu: null,
	},
	calendarpage: {
		id: 'calendarpage',
		text: 'Lịch',
		path: '/calendar',
		icon: 'EditCalendar',
		subMenu: null,
	},
	uploadpage: {
		id: 'upload',
		text: 'Đăng Bài',
		path: 'uploadpage',
		icon: 'UploadFile',
		subMenu: null,
	},
	uploadhistory: {
		id: 'uploadhistory',
		text: 'Danh sách bài đã đăng',
		path: 'uploadhistory',
		icon: 'History',
		subMenu: null,
	},
	postauto: {
		id: 'postauto',
		text: 'Hệ thống tự động',
		path: '/postauto',
		icon: 'MotionPhotosAuto',
		subMenu: null,
		// {
		// 	// autoreaction: {
		// 	// 	id: 'autoreaction',
		// 	// 	text: 'Tự động tương tác',
		// 	// 	path: 'autoreaction',
		// 	// 	icon: 'AddReaction',
		// 	// },
		// 	autoFbpost: {
		// 		id: 'autoFbpost',
		// 		text: 'Tự động đăng bài Facebook',
		// 		path: '/postauto',
		// 		icon: 'facebook',
		// 	},
		// 	// autoIgpost: {
		// 	// 	id: 'autoIgpost',
		// 	// 	text: 'Tự động đăng bài Instagram',
		// 	// 	path: '/instagram/igpostauto',
		// 	// 	icon: 'CustomInstagram2',
		// 	// },
		// 	// autoFbTokenPost: {
		// 	// 	id: 'autofbtokenpost',
		// 	// 	text: 'FB (Access token)',
		// 	// 	path: '/postautotoken',
		// 	// 	icon: 'facebook',
		// 	// },
		// },
	},
	follow: {
		id: 'follow',
		text: 'Theo dõi',
		icon: 'Extension',
		path: '',
	},
	followlistpage: {
		id: 'postfollow',
		text: 'Danh sách theo dõi',
		path: 'postfollow',
		icon: 'ViewList',
		subMenu: {
			followListFacebook: {
				id: 'followListFacebook',
				text: 'Danh sách Facebook',
				path: '/postfollow',
				icon: 'facebook',
			},
			followListInstagram: {
				id: 'followListInstagram',
				text: 'Danh sách Instagram',
				path: '/instagram/igpostfollow',
				icon: 'CustomInstagram2',
			},
			// followListYoutube: {
			// 	id: 'followListYoutube',
			// 	text: 'Danh sách Youtube',
			// 	path: '/youtube/ytpostfollow',
			// 	icon: 'CustomYoutube',
			// },
			followListTiktok: {
				id: 'followListTiktok',
				text: 'Danh sách Tiktok',
				path: '/tiktok/ttpostfollow',
				icon: 'CustomTiktok',
			},
			followListDouyin: {
				id: 'followListDouyin',
				text: 'Danh sách Douyin',
				path: '/douyin/douyinpostfollow',
				icon: 'CustomTiktok',
			},
			// followListKouaishou: {
			// 	id: 'followListKouaishou',
			// 	text: 'Danh sách Kuaishou',
			// 	path: '/kuaishou/kuaishoupostfollow',
			// 	icon: 'CustomTiktok',
			// },
		},
	},
	post: {
		id: 'post',
		text: 'Kho nội dung',
		path: 'fbpost',
		icon: 'PostAdd',
		subMenu: {
			facebookpost: {
				id: 'facebookpost',
				text: 'Bài viết Facebook',
				path: '/fbpost',
				icon: 'facebook',
			},
			instagrampost: {
				id: 'instagrampost',
				text: 'Bài viết Instagram',
				path: '/instagram/igpost',
				icon: 'CustomInstagram2',
			},
			// youtubepost: {
			// 	id: 'youtubepost',
			// 	text: 'Bài viết Youtube',
			// 	path: '/youtube/ytpost',
			// 	icon: 'CustomYoutube',
			// },
			tiktokpost: {
				id: 'tiktokpost',
				text: 'Bài viết Tiktok',
				path: '/tiktok/tiktok',
				icon: 'CustomTiktok',
			},
		},
	},
	gallery: {
		id: 'gallery',
		text: 'Chủ đề yêu thích',
		path: 'gallery',
		icon: 'Collections',
		subMenu: null,
	},
	// contentPost: {
	// 	id: 'contentPost',
	// 	text: 'Phân tích nội dung',
	// 	path: '/content-post',
	// 	icon: 'ContentPaste',
	// 	subMenu: null,
	// },
	message: {
		id: 'message',
		text: 'Tin nhắn',
		icon: 'Extension',
		path: '',
	},
	inbox: {
		id: 'inbox',
		text: 'Hộp thư',
		path: 'inbox',
		icon: 'Inbox',
		subMenu: null,
	},

	// facebooktool: {
	// 	id: 'facebooktool',
	// 	text: 'Facebook Tool',
	// 	path: 'facebooktool',
	// 	icon: 'ShopTwo',
	// 	subMenu: null,
	// },
	// poststore: {
	// 	id: 'poststore',
	// 	text: 'Kho nội dung',
	// 	path: 'poststore',
	// 	icon: 'Store',
	// 	subMenu: null,
	// },
	profile: {
		id: 'profile',
		text: 'Cá nhân',
		icon: 'Extension',
		path: '',
	},
	settingpage: {
		id: 'setting',
		text: 'Thông tin cá nhân',
		path: 'setting/*',
		icon: 'Settings',
		subMenu: null,
	},
	memberpage: {
		id: 'member',
		text: 'Quản lý nhân sự',
		path: 'member',
		icon: 'SupervisedUserCircle',
		subMenu: null,
	},
	paymentpage: {
		id: 'payment',
		text: 'Thanh toán',
		path: 'payment',
		icon: 'Payment',
		subMenu: null,
	},
	bonus: {
		id: 'bonus',
		text: 'Bổ trợ',
		icon: 'Extension',
		path: '',
	},
	autocomment: {
		id: 'autocomment',
		text: 'Diễn đàn cảm xúc nhanh',
		path: 'dien-dan-cam-xuc-nhanh',
		icon: 'Comment',
		subMenu: null,
	},
	autoreaction: {
		id: 'autoreaction',
		text: 'Chăm sóc bài viết',
		path: 'cham-soc-bai-viet',
		icon: 'AddReaction',
		subMenu: null,
	},
	extra: {
		id: 'extra',
		text: 'Hỗ trợ',
		icon: 'Extension',
		path: '',
	},
	help: {
		id: 'help',
		text: 'Trợ giúp',
		path: 'https://amaicontent.com/huong-dan-su-dung/',
		icon: 'Help',
		subMenu: null,
	},

	// dashboard: {
	// 	id: 'dashboard',
	// 	text: 'Extra',
	// 	path: 'dashboard',
	// 	icon: 'Dashboard',
	// 	subMenu: null,
	// },
	// dashboardBooking: {
	// 	id: 'dashboard-booking',
	// 	text: 'Extra',
	// 	path: 'dashboard-booking',
	// 	icon: 'emoji_transportation',
	// 	subMenu: null,
	// },
};

export const demoPages = {
	auth: {
		id: 'auth',
		text: 'Auth Pages',
		icon: 'Extension',
	},
	login: {
		id: 'login',
		text: 'Login',
		path: 'login',
		icon: 'Login',
	},
	forgotPassword: {
		id: 'forgotPassword',
		text: 'Forgot Password',
		path: 'forgot-password',
		icon: 'PersonAdd',
	},
	signUp: {
		id: 'signUp',
		text: 'Sign Up',
		path: 'sign-up',
		icon: 'PersonAdd',
	},

	page404: {
		id: 'Page404',
		text: '404 Page',
		path: 'auth-pages/404',
		icon: 'ReportGmailerrorred',
	},
};

export const layoutMenu = {};

export const componentsMenu = {};

export const productsMenu = {};
export const ToolMenu = {
	carouselvideo: {
		id: 'carouselvideo',
		text: 'carouselvideo',
		path: 'facebooktool/carouselvideo',
		subMenu: null,
	},
	cloneFBReel: {
		id: 'cloneFBReel',
		text: 'cloneFBReel',
		path: 'facebooktool/cloneFBReel',
		subMenu: null,
	},
	clonecarousel: {
		id: 'clonecarousel',
		text: 'cloneCarousel',
		path: 'facebooktool/clonecarousel',
		subMenu: null,
	},
};
export const Terms = {
	terms: { id: 'terms', text: 'terms', path: '/terms', subMenu: null },
	policy: { id: 'policy', text: 'policy', path: '/policy', subMenu: null },
};
