/* eslint-disable prettier/prettier */
import React from 'react';
import {
	adminMenu,
	componentsMenu,
	dashboardMenu,
	demoPages,
	layoutMenu,
	Terms,
	ToolMenu,
	trendingMenu,
} from '../menu';
import DefaultHeader from '../pages/common/Headers/DefaultHeader';
import FacebookHeader from '../pages/common/Headers/FacebookHeader';
import { exact } from 'prop-types';
import CustomHeader from '../pages/common/Headers/CustomHeader';
import LandingHeader from '../pages/common/Headers/LandingHeader';

const headers = [
	{ path: '*', element: <FacebookHeader /> },

	{ path: demoPages.forgotPassword.path, element: null, exact: true },
	{ path: demoPages.login.path, element: null, exact: true },
	{ path: '/register', element: null, exact: true },
	{ path: demoPages.signUp.path, element: null, exact: true },
	{ path: demoPages.page404.path, element: null, exact: true },
	{ path: dashboardMenu.landing.path, element: <LandingHeader />, exact: true },

	{ path: trendingMenu.trendingfacebook.path, element: <FacebookHeader />, exact: true },
	// { path: trendingMenu.trendinginstagram.path,
	// 	element: <FacebookHeader />,
	// 	exact:true,
	// },
	// {
	// 	path: dashboardMenu.workspace.path,
	// 	element: <FacebookHeader />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.post.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: trendingMenu.analytics.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.calendarpage.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.uploadpage.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.facebook.path,
		element: <FacebookHeader />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.facebook.subMenu.instagram.path,
	// 	element: <FacebookHeader />,
	// 	exact: true
	// },
	{
		path: dashboardMenu.settingpage.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.paymentpage.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.followlistpage.path,
		element: <FacebookHeader />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.facebooktool.path,
	// 	element: <FacebookHeader />,
	// 	exact: true,
	// },
	{
		path: ToolMenu.carouselvideo.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: ToolMenu.cloneFBReel.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: ToolMenu.clonecarousel.path,
		element: <FacebookHeader />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.poststore.path,
	// 	element: <FacebookHeader />,
	// 	exact: true
	// },
	{
		path: dashboardMenu.help.path,
		element: <FacebookHeader />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.facebook.subMenu.account.path,
	// 	element: <FacebookHeader />,
	// 	exact: true
	// },
	// {
	// 	path: dashboardMenu.facebook.subMenu.page.path,
	// 	element: <FacebookHeader />,
	// 	exact: true
	// },
	{
		path: dashboardMenu.postauto.path,
		element: <FacebookHeader />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.postauto.subMenu.autoIgpost.path,
	// 	element: <FacebookHeader />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.post.subMenu.facebookpost.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.post.subMenu.instagrampost.path,
		element: <FacebookHeader />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.post.subMenu.youtubepost.path,
	// 	element: <FacebookHeader />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.post.subMenu.tiktokpost.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.followlistpage.subMenu.followListFacebook.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.followlistpage.subMenu.followListTiktok.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.followlistpage.subMenu.followListInstagram.path,
		element: <FacebookHeader />,
		exact: true,
	},

	{
		path: '/signin/app/*',
		element: <FacebookHeader />,
		exact: true,
	},
	
	{
		path: adminMenu.adminManger.subMenu.usermanger.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: dashboardMenu.uploadhistory.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.paymentMethod.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.autofb.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.workspace.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.account.path,
		element: <FacebookHeader />,
		exact: true,
	},
	// {
	// 	path: adminMenu.adminManger.subMenu.fbpost.path,
	// 	element: <FacebookHeader />,
	// 	exact: true,
	// },
	{
		path: adminMenu.adminManger.subMenu.category.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.paymenthistory.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.extension.path,
		element: <FacebookHeader />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.script.path,
		element: <FacebookHeader />,
		exact: true,
	},
	
	{
		path: Terms.terms.path,
		element: <CustomHeader />,
		exact: true,
	},
	{
		path: Terms.policy.path,
		element: <CustomHeader />,
		exact: true,
	},
	
	{
		path: `*`,
		element: <DefaultHeader />,
	},
	{
		path: `/ve-chung-toi`,
		element: <LandingHeader />,
	},
	{
		path: `/dich-vu`,
		element: <LandingHeader />,
	},
	{
		path: `/affiliate`,
		element: <LandingHeader />,
	},
	{
		path: `/bang-gia`,
		element: <LandingHeader />,
	},
	
];

export default headers;
