/* eslint-disable react/prop-types */
/* eslint-disable prettier/prettier */
import React, { createContext, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from '../components/bootstrap/Spinner';
import Button from '../components/bootstrap/Button';
import './loading.css';

export const LoadingContext = createContext();
export const useLoading = () => useContext(LoadingContext);

const LoadingSpiner = styled.div`
	// position: absolute;
	z-index: 100;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background: transparent;
	// margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
export const Loading = ({ isInbox }) => {
	return (
		<>
			<LoadingSpiner>
				{isInbox && (
					<div className='loader' style={{marginBottom: '4rem'}}>
						<div className='ms-2'>Đang</div>
						<div className='ms-2'>đồng </div>
						<div className='ms-2'>bộ </div>
						<div className='ms-2'>dữ</div>
						<div className='ms-2'>liệu ...</div>
					</div>
				)}
				<div className='loader'>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
					<span></span>
				</div>
			</LoadingSpiner>
		</>
	);
};

export const LoadingProvider = (props) => {
	const [loading, setLoading] = useState(false);
	return (
		<LoadingContext.Provider
			value={{
				loading: loading,
				show: () => setLoading(false),
				hide: () => setLoading(false),
			}}>
			{loading && <Loading />}
			{props.children}
		</LoadingContext.Provider>
	);
};
LoadingProvider.propTypes = {
	children: PropTypes.node,
};
