import * as React from 'react';
import { SVGProps } from 'react';

const SvgCustomTiktok = (props: SVGProps<SVGSVGElement>) => (
	<svg
		height='1em'
		viewBox='0 0 512.6 512.6'
		width='1em'
		xmlns='http://www.w3.org/2000/svg'
		className='svg-icon'
		fill='currentColor'

		{...props}>
		<path d='M457.21 0H55.39C24.8 0 0 24.8 0 55.39v401.82c0 30.59 24.8 55.39 55.39 55.39h401.82c30.59 0 55.39-24.8 55.39-55.39V55.39C512.6 24.8 487.8 0 457.21 0zm-42.79 223.3c-32.45 0-62.52-10.38-87.07-28v127.17c0 63.52-51.67 115.19-115.18 115.19-24.54 0-47.3-7.74-66.01-20.88-29.7-20.85-49.17-55.34-49.17-94.31 0-63.51 51.67-115.18 115.18-115.18 5.27 0 10.44.43 15.52 1.12v63.89c-4.91-1.54-10.11-2.43-15.52-2.43-29.01 0-52.6 23.6-52.6 52.6 0 20.2 11.45 37.75 28.2 46.56 7.3 3.85 15.59 6.05 24.4 6.05 28.34 0 51.45-22.54 52.5-50.63l.1-250.79h62.58c0 5.42.53 10.72 1.48 15.87 4.42 23.85 18.57 44.3 38.17 57.09 13.66 8.9 29.94 14.1 47.42 14.1z' />
	</svg>
);

export default SvgCustomTiktok;
