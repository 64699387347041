import * as React from 'react';
import { SVGProps } from 'react';

const SvgCustomComment = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 19.288 19.474'
		className='svg-icon'
		{...props}>
		<path
			d='M234.915 400.617h-12.374a2.966 2.966 0 0 0-2.957 2.957v9.1a2.965 2.965 0 0 0 2.957 2.957h2.837v3.459l3.689-3.459h5.848a2.965 2.965 0 0 0 2.957-2.957v-9.1a2.966 2.966 0 0 0-2.957-2.957Z'
			transform='translate(-219.084 -400.117)'
			style={{
				fill: '#fff',
				stroke: '#606060',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
	</svg>
);

export default SvgCustomComment;
