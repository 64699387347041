import * as React from 'react';
import { SVGProps } from 'react';

const SvgCustomShare = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 21.677 18.928'
		className='svg-icon'
		{...props}>
		<path
			d='m329.292 410.3-8.6-8.6v5.339h-.361c-6.953 0-11.721 5.637-11.721 12.589a16.357 16.357 0 0 1 6.077-5.209 11.636 11.636 0 0 1 6.005-.984v5.455Z'
			transform='translate(-308.115 -401.205)'
			style={{
				fill: '#fff',
				stroke: '#606060',
				strokeLinecap: 'round',
				strokeLinejoin: 'round',
			}}
		/>
	</svg>
);

export default SvgCustomShare;
