import React from 'react';
import Card, {
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../../../components/bootstrap/Card';
import Button from '../../../../components/bootstrap/Button';
import useDarkMode from '../../../../hooks/useDarkMode';

const AppItem = ({ id, name, category, img, type, onSubmit }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();

	return (
		<Card>
			<CardHeader>
				<CardLabel>
					<CardTitle>{name} </CardTitle>
					<CardSubTitle>{category}</CardSubTitle>
				</CardLabel>
			</CardHeader>
			<CardBody>
				<img
					src={img}
					alt=''
					width={128}
					height={128}
					className='mx-auto d-block img-fluid mb-3'
				/>
			</CardBody>
			<CardFooter className='shadow-3d-container'>
				<Button
					onClick={() => {
						onSubmit({
							type: type,
						});
					}}
					color='dark'
					className={`w-100 mb-4 shadow-3d-up-hover shadow-3d-${
						darkModeStatus ? 'light' : 'dark'
					}`}
					size='lg'>
					Đăng nhập ngay
				</Button>
			</CardFooter>
		</Card>
	);
};

export default AppItem;
