import React, { useEffect } from 'react';
import '../AboutUs/aboutus.css';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { FaLongArrowAltRight } from 'react-icons/fa';
import img1 from '../../../assets/AboutUs/signup 1.svg';
import img2 from '../../../assets/AboutUs/share 1.svg';
import img3 from '../../../assets/AboutUs/earn 1.svg';
import img4 from '../../../assets/AboutUs/Nhiem vu 1.svg';
import img5 from '../../../assets/AboutUs/Gia tri cot loi 1.svg';
import img6 from '../../../assets/AboutUs/euro.svg';
import img7 from '../../../assets/AboutUs/shareandroid.svg';
import img8 from '../../../assets/AboutUs/database.svg';
import img9 from '../../../assets/AboutUs/hourglass.svg';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

const Affiliate = () => {
	const isWide = useMedia('(min-width: 769px)');

	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<div className='d-flex flex-column w-100'>
			<div
				className={classNames({
					affiliate__container: isWide,
					affiliate__container__mobile: !isWide,
				})}>
				<div className='affiliate__section1'>
					<div className='mb-3' style={{ fontSize: '32px', fontWeight: '400' }}>
						Tham gia Affiliate cùng AMAI
					</div>
					<div className='mb-3' style={{ fontSize: '32px', fontWeight: '700' }}>
						Kiếm tiền Online không cần bỏ vốn
					</div>
					<div className='mb-5'>
						<h4 style={{ fontWeight: '400' }}>
							Nếu bạn đang sử dụng AMAI Content  và yêu thích phần mềm này, hãy giới
							thiệu với bạn bè, người thân tham gia Affiliate cùng Amai Content để có
							cơ hội kiếm tiền triệu mỗi tháng. Chương trình Amai Content Affiliate là
							network kiếm tiền online uy tín với mức hoa hồng vô cùng hấp dẫn.
						</h4>
					</div>
					<div className='d-flex justify-content-center'>
						<Link to='/register'>
							<button className='about__us__section1__button'>
								Đăng ký ngay <FaLongArrowAltRight className='ms-2' />
							</button>
						</Link>
					</div>
				</div>
				<div className='affiliate__section2'>
					<div className='mb-3' style={{ fontSize: '48px', fontWeight: '700' }}>
						Cách thức hoạt động
					</div>
					<div className='mb-4' style={{ fontSize: '24px', fontWeight: '400' }}>
						Để tham gia Amai Content Affiliate Marketing, bạn chỉ cần tuân thủ 3 bước
						đơn giản sau:
					</div>
					<div className='row g-3 mb-3 mt-4'>
						<div className='col-12 col-lg-4 d-flex flex-column align-items-center '>
							<img src={img1} alt='' />
							<div className='mt-4' style={{ fontSize: '32px' }}>
								Bước 1:
							</div>
							<h5 style={{ fontWeight: '400' }}>
								Tạo một liên kết giới thiệu (affiliate link) với tên người dùng của
								bạn. Điều này đảm bảo rằng liên kết của bạn là duy nhất và không
								trùng lặp.
							</h5>
						</div>
						<div className='col-12 col-lg-4 d-flex flex-column align-items-center '>
							<img src={img2} alt='' />
							<div className='mt-4' style={{ fontSize: '32px' }}>
								Bước 2:
							</div>
							<h5 style={{ fontWeight: '400' }}>
								Chia sẻ thông tin về AMAI Content với bạn bè, gia đình hoặc bất kỳ
								ai trên internet bằng cách sử dụng liên kết giới thiệu của bạn.
							</h5>
						</div>
						<div className='col-12 col-lg-4 d-flex flex-column align-items-center '>
							<img src={img3} alt='' />
							<div className='mt-4' style={{ fontSize: '32px' }}>
								Bước 3:
							</div>
							<h5 style={{ fontWeight: '400' }}>
								Nhận hoa hồng hàng tháng (30% giá trị giao dịch) khi có người đăng
								ký và sử dụng gói trả phí của AMAI Content thông qua liên kết giới
								thiệu của bạn.
							</h5>
						</div>
					</div>
				</div>
			</div>
			<div
				className={classNames({
					about__us__container: isWide,
					about__us__container__moblie: !isWide,
				})}>
				<div className='about__us__section3'>
					<div className='d-flex flex-column align-items-center'>
						<div className='row align-items-center g-5 mt-3' data-aos='fade-right'>
							<div className='col-12 col-lg-6'>
								<img src={img4} alt='' />
							</div>
							<div className='col-12 col-lg-6 about__us__section3__description'>
								<h2 className='about__us__section3__description__header'>
									Điều kiện tham gia
								</h2>
								<div className='mt-3 text-justify'>
									Chương trình Amai Content Affiliate áp dụng cho khách hàng của
									Amai Content đang sử dụng gói phần mềm Amai Content có trả phí.
								</div>
								<div className='text-justify'>
									Bạn có thể vào trang Affiliate và tạo cho mình một username duy
									nhất. Tên username này không thể đổi được, chính vì vậy hãy suy
									nghĩ thật kỹ trước khi chọn bạn nhé! Sau đó, bạn chỉ việc copy
									đường link này và chia sẻ với bạn bè của mình.
								</div>
							</div>
						</div>

						<div className='row align-items-center g-5 mt-3' data-aos='fade-left'>
							<div className='col-12 col-lg-6 about__us__section3__description'>
								<h2 className='about__us__section3__description__header'>
									Bạn nhận được bao nhiêu
								</h2>
								<div className='mt-3 text-justify'>
									Khi bạn giới thiệu thành công một người dùng (người được giới
									thiệu), bạn sẽ nhận được 30% hoa hồng trên mọi giao dịch của
									người đó. Tỷ lệ hoa hồng 30% này sẽ áp dụng vĩnh viễn cho tất cả
									các giao dịch trong tương lai.
								</div>
								<div className='text-justify'>
									Ví dụ, nếu bạn giới thiệu người dùng A và họ mua gói  299K/tháng
									và sử dụng trong vòng 6 tháng. Tổng giá trị của giao dịch đó sẽ
									là: 1,299,000 x 6 = 7,404,300 (giảm giá 5% khi trả 6 tháng).
								</div>
								<div className='text-justify'>
									Tổng số hoa hồng bạn nhận được từ giao dịch này sẽ là: 7,404,300
									x 30% = 2,221,290. Nếu sau 6 tháng, người dùng A quyết định gia
									hạn sử dụng gói dịch vụ trên thêm 6 tháng nữa, thì bạn sẽ tiếp
									tục nhận được 2,221,290 tiền hoa hồng.
								</div>
							</div>
							<div className='col-12 col-lg-6'>
								<img src={img5} alt='' />
							</div>
						</div>
						<div className='row align-items-center g-5 mt-3' data-aos='fade-right'>
							<div className='col-12 col-lg-6'>
								<img src={img4} alt='' className='affiliate__image' />
							</div>
							<div className='col-12 col-lg-6 about__us__section3__description'>
								<h2 className='about__us__section3__description__header'>
									Điều kiện xác nhận người dùng được giới thiệu
								</h2>

								<div className='mt-3 text-justify'>
									Khi một người dùng nhấp vào đường link liên kết đến tài khoản cá
									nhân của bạn và đăng ký sử dụng Amai Content trong vòng 30 ngày
									kể từ thời điểm nhấp vào liên kết, họ sẽ được coi là người dùng
									được giới thiệu bởi bạn.
								</div>
								<div className='text-justify'>
									Trong trường hợp người dùng nhấp vào nhiều liên kết liên kết
									khác nhau, chỉ liên kết liên kết mới nhất sẽ được tính.
								</div>
							</div>
						</div>
						<div className='row align-items-center g-5 mt-3' data-aos='fade-left'>
							<div className='col-12 col-lg-6 about__us__section3__description'>
								<h2 className='about__us__section3__description__header'>
									Nhận hoa hồng
								</h2>
								<div className='mt-3 text-justify'>
									Ngày thanh toán sẽ diễn ra vào ngày đầu tiên của mỗi tháng. Tiền
									hoa hồng sẽ được chuyển vào tài khoản ngân hàng mà bạn đã chọn.
									Tuy nhiên, số tiền tối thiểu để nhận thanh toán là 100,000đ. Nếu
									số tiền này chưa đạt được, nó sẽ được tích lũy và thanh toán vào
									tháng tiếp theo.
								</div>
								<div className='text-justify'>
									Bạn có thể kiểm tra số tiền mà bạn sẽ nhận vào đầu tháng tiếp
									theo trong mục "Đã ghi nhận".
								</div>
							</div>
							<div className='col-12 col-lg-6'>
								<img src={img5} alt='' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				data-aos='zoom-in'
				className={classNames({
					affiliate__container: isWide,
					affiliate__container__mobile: !isWide,
				})}>
				<div className='affiliate__section3'>
					{/* <div className='mb-4'></div> */}
					<h1
						style={{
							fontWeight: '700',
							color: 'rgba(64, 12, 176, 1)',
						}}>
						Lợi ích khi tham gia AMAI Content Affiliate
					</h1>
					<div className='row g-3 mb-3  align-items-stretch'>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img6} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>Mức hoa hồng hấp dẫn</h2>
							</div>

							<div className='mt-3'>
								Amai Content cung cấp mức hoa hồng hấp dẫn cho người tham gia
								Affiliate, lên đến 30% hoa hồng trọn đời, không giới hạn. Điều này
								có nghĩa là bạn sẽ nhận được hoa hồng 30% trên tất cả các giao dịch
								của người được giới thiệu, kể cả những giao dịch diễn ra trong tương
								lai.
							</div>
						</div>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img7} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>Cách thức giới thiệu sản phẩm dễ dàng</h2>
							</div>

							<div className='mt-3'>
								Để giới thiệu sản phẩm của Amai Content, bạn chỉ cần sử dụng đường
								link giới thiệu của mình. Đường link giới thiệu này có thể được chia
								sẻ trên các nền tảng mạng xã hội, website, blog,...
							</div>
						</div>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img8} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>Thống kê giao dịch trực quan</h2>
							</div>

							<div className='mt-3'>
								Amai Content cung cấp hệ thống quản lý Affiliate trực quan, giúp bạn
								theo dõi thu nhập của mình một cách dễ dàng. Bạn có thể xem số lượt
								click, số đơn hàng, số hoa hồng đã nhận,...
							</div>
						</div>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img9} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>Cơ hội hợp tác lâu dài</h2>
							</div>
							<div className='mt-3'>
								Amai Content luôn chào đón các đối tác Affiliate lâu dài. Khi trở
								thành đối tác Affiliate lâu dài, bạn sẽ nhận được nhiều ưu đãi và hỗ
								trợ đặc biệt từ Amai Content.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				data-aos='zoom-in'
				className={classNames({
					about__us__container: isWide,
					about__us__container__moblie: !isWide,
				})}>
				<div className='about__us__section3'>
					<div className='d-flex flex-column align-items-center'>
						<h2 className='about__us__section3__text__description'>
							Đừng bỏ lỡ cơ hội tiền tiền vô cùng hấp dẫn với Affiliate của Amai
							Content nhé. Hãy để Amai Content cùng bạn chinh phục mục tiêu tài chính.
						</h2>
						<div className='d-flex align-items-center mt-5'>
							<Link to='/register'>
								<button className='about__us__section1__button'>
									Đăng ký ngay <FaLongArrowAltRight className='ms-2' />
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Affiliate;
