/* eslint-disable prettier/prettier */
import axios from 'axios';
import queryString from 'query-string';
import { useLoading } from '../contexts/loading';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { checkExpire } from '../redux/userSlice';

const axiosClient = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		'content-type': 'application/json',
	},
	paramsSerializer: (params) => queryString.stringify(params),
});

// axiosClient.interceptors.request.use(async (config) => {
//   // const token = await getFirebaseToken();
//   // if (token) {
//   //   config.headers.Authorization = `Bearer ${token}`;
//   // }
//   const token = await window.localStorage.getItem('accessToken');
//   const key = await window.localStorage.getItem('app-key');
//   // config.headers.Authorization = `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwicm9sZV9pZCI6MiwiZW1haWwiOiJkYW9taW5odHUyNDVAZ21haWwuY29tIiwicGFzc3dvcmQiOiIkMmEkMTAkbS90UXAyemZUUTBRNTY4ck4wenkzLnJoeEVrUWd4SUdzNDRxbmJZeTlKbDZ2S1VnQkhrSXUiLCJmdWxsX25hbWUiOiJEYW8gTWluaCBUdSIsInRva2VuIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmxiV0ZwYkNJNkltUmhiMjFwYm1oMGRUSTBOVEZBWjIxaGFXd3VZMjl0SWl3aWNHRnpjM2R2Y21RaU9pSWtNbUVrTVRBa2JTOTBVWEF5ZW1aVVVUQlJOVFk0Y2s0d2Vua3pMbkpvZUVWclVXZDRTVWR6TkRSeGJtSlplVGxLYkRaMlMxVm5Ra2hyU1hVaUxDSnBZWFFpT2pFMk5qUXhPRGN4TURVc0ltVjRjQ0k2TVRZMk5qYzNPVEV3TlgwLnRyalVvd0VfbHg4SC1sb0VYbzkwTXRoTmJKbmFjMEttT0JzV3lxcmxKUFUiLCJyZWZfY29kZSI6IjBjMWIzYTMxZWEwNDllNDQ5NTYzODRlNGRmM2UzZTdiYjAyMmYwZDEiLCJjb2luIjpudWxsLCJyZXdhcmRfbW9uZXkiOjAsInRva2VuX2V4cGlyZWRfYXQiOm51bGwsInBhY2thZ2VfZXhwaXJlZF9hdCI6IjIwMjItMDktMjlUMTA6MTE6NDUuMDAwWiIsInNlbmRfZW1haWxfYXQiOiIyMDIyLTA5LTI2VDEwOjExOjQ1LjAwMFoiLCJ2ZXJpZmllZF9hdCI6IjIwMjItMDktMjZUMTA6MTE6NDUuMDAwWiIsImJhbmtfbmFtZSI6bnVsbCwiYmFua19icmFuY2giOm51bGwsImJhbmtfbnVtYmVyIjpudWxsLCJiYW5rX2FjY291bnQiOm51bGwsImNyZWF0ZWRBdCI6IjIwMjItMDktMjZUMTA6MTE6NDUuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjItMDktMjZUMTA6MTE6NDUuMDAwWiIsImRlbGV0ZWRBdCI6bnVsbCwiaWF0IjoxNjY0MTg3MTc0LCJleHAiOjE2NjY3NzkxNzR9.EsUqyAzMNqCCt2tGDOE2hRFHGdkXaTFXd6XUcHaUyyY`;
//   config.headers.Authorization = token;
//   config.headers.browser_key = key;
//   return config;
// });

// axiosClient.interceptors.response.use((response) => {
//   console.log(response);
//   if(response && response.data) {
//     return response.data;
//   }
//   return response;
// },
//   (error)=>{
//     if(error.response.status === 401) {
//       window.localStorage.removeItem('accessToken');
//     }
//     throw error;
//   },
// );
const AxiosInterceptor = ({ children }) => {
	const navigate = useNavigate();
	const [isSet, setIsSet] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		const interceptor = axiosClient.interceptors.request.use(async (config) => {
			// const token = await getFirebaseToken();
			// if (token) {
			//   config.headers.Authorization = `Bearer ${token}`;
			// }
			const token = await window.localStorage.getItem('accessToken');
			const key = await window.localStorage.getItem('app-key');
			// config.headers.Authorization = `JWT eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwicm9sZV9pZCI6MiwiZW1haWwiOiJkYW9taW5odHUyNDVAZ21haWwuY29tIiwicGFzc3dvcmQiOiIkMmEkMTAkbS90UXAyemZUUTBRNTY4ck4wenkzLnJoeEVrUWd4SUdzNDRxbmJZeTlKbDZ2S1VnQkhrSXUiLCJmdWxsX25hbWUiOiJEYW8gTWluaCBUdSIsInRva2VuIjoiZXlKaGJHY2lPaUpJVXpJMU5pSXNJblI1Y0NJNklrcFhWQ0o5LmV5SmxiV0ZwYkNJNkltUmhiMjFwYm1oMGRUSTBOVEZBWjIxaGFXd3VZMjl0SWl3aWNHRnpjM2R2Y21RaU9pSWtNbUVrTVRBa2JTOTBVWEF5ZW1aVVVUQlJOVFk0Y2s0d2Vua3pMbkpvZUVWclVXZDRTVWR6TkRSeGJtSlplVGxLYkRaMlMxVm5Ra2hyU1hVaUxDSnBZWFFpT2pFMk5qUXhPRGN4TURVc0ltVjRjQ0k2TVRZMk5qYzNPVEV3TlgwLnRyalVvd0VfbHg4SC1sb0VYbzkwTXRoTmJKbmFjMEttT0JzV3lxcmxKUFUiLCJyZWZfY29kZSI6IjBjMWIzYTMxZWEwNDllNDQ5NTYzODRlNGRmM2UzZTdiYjAyMmYwZDEiLCJjb2luIjpudWxsLCJyZXdhcmRfbW9uZXkiOjAsInRva2VuX2V4cGlyZWRfYXQiOm51bGwsInBhY2thZ2VfZXhwaXJlZF9hdCI6IjIwMjItMDktMjlUMTA6MTE6NDUuMDAwWiIsInNlbmRfZW1haWxfYXQiOiIyMDIyLTA5LTI2VDEwOjExOjQ1LjAwMFoiLCJ2ZXJpZmllZF9hdCI6IjIwMjItMDktMjZUMTA6MTE6NDUuMDAwWiIsImJhbmtfbmFtZSI6bnVsbCwiYmFua19icmFuY2giOm51bGwsImJhbmtfbnVtYmVyIjpudWxsLCJiYW5rX2FjY291bnQiOm51bGwsImNyZWF0ZWRBdCI6IjIwMjItMDktMjZUMTA6MTE6NDUuMDAwWiIsInVwZGF0ZWRBdCI6IjIwMjItMDktMjZUMTA6MTE6NDUuMDAwWiIsImRlbGV0ZWRBdCI6bnVsbCwiaWF0IjoxNjY0MTg3MTc0LCJleHAiOjE2NjY3NzkxNzR9.EsUqyAzMNqCCt2tGDOE2hRFHGdkXaTFXd6XUcHaUyyY`;
			config.headers.Authorization = token;
			config.headers.browser_key = key;
			return config;
		});

		const interceptor2 = axiosClient.interceptors.response.use(
			(response) => {
				if (response.data.status === 406) {
					dispatch(checkExpire(true));
				}
				if (response && response.data) {
					return response.data;
				}
				return response;
			},
			(error) => {
				if (error.response.status === 401) {
					navigate('/login');
					window.localStorage.removeItem('accessToken');
				}
				// else if(error.code == "ERR_NETWORK"){
				//   navigate('/login')
				// }
				throw error;
			},
		);
		setIsSet(true);
		return () => axiosClient.interceptors.response.eject(interceptor, interceptor2);
	}, []);

	return isSet && children;
};
export const sendGet = (url, params) => axiosClient.get(url, { params });
export const sendPost = (url, params, queryParams) =>
	axiosClient.post(url, params, { params: queryParams });
export const sendPut = (url, params) => axiosClient.put(url, params);
export const sendPatch = (url, params) => axiosClient.patch(url, params);
export const sendDelete = (url, params) => axiosClient.delete(url, params);
export default axiosClient;
export { AxiosInterceptor };
