import {sendGet, sendPost} from './axiosClients'

export const getListPackage = (data) => sendGet('/packages/all',data);
export const createPackage = (data) => sendPost('/packages/store',data);
export const updatePackage = (data) => sendPost('/packages/update',data);
export const extendPackage = (data) => sendPost('/packages/extend', data);
export const buyAddOns = (data) => sendPost('/packages/buy-more-target-account', data);
export const packageDiscount = (data) => sendGet('/discount', data);
export const getListRef = (data) => sendGet('/user/list-ref', data);
export const getRefCount = (data) => sendGet('/user/ref-count', data);
export const updateRefClick = (data) => sendPost('/user/update-ref-click', data)
export const updateRef = (data) => sendPost('/user/update-ref', data);
export const updateBank = (data) => sendPost('/user/update-bank', data);
export const updateStatusRequest = (data) => sendPost('/user/update-status-request' , data);
export const listRequest = (data) => sendGet('/user/list-request-withdraw-admin', data);
export const userListRequest = (data) => sendGet('/user/list-request-withdraw', data);
export const withdraw = (data) => sendPost('/user/withdraw-ref', data);
export const getBanner = (data) => sendGet('/banner/get', data);
export const getDetailBanner = (data) => sendGet('/banner/detail', data);
export const createBanner = (data) => sendPost('/banner/store', data);
export const updateBanner = (data) => sendPost('/banner/update', data);
export const deleteBanner = (data) => sendPost('/banner/destroy', data);
export const extendPackageNew = (data) => sendPost('/packages/extend-and-more', data)




