/* eslint-disable prettier/prettier */
import classNames from 'classnames';
import { useFormik } from 'formik';
import moment from 'moment';
import { useCallback, useContext, useEffect, useState } from 'react';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import styled from 'styled-components';
import { logout } from '../../../api/auth';
import { sendGet } from '../../../api/axiosClients';
import { checkWorkspaceIsActive } from '../../../api/fbTargetApi';
import CalendarIcon from '../../../assets/fbLogo/Calendar.svg';
import LogoutIcon from '../../../assets/fbLogo/Logout.svg';
import SettingIcon from '../../../assets/fbLogo/Settings.svg';
import MoneyIcon from '../../../assets/fbLogo/Stack of Money.svg';
import AccountIcon from '../../../assets/fbLogo/Web Account.svg';
import AvatarImg from '../../../assets/logos/download.png';
import USERS from '../../../common/data/userDummyData';
import Avatar from '../../../components/Avatar';
import Badge from '../../../components/bootstrap/Badge';
import Button from '../../../components/bootstrap/Button';
import Dropdown, { DropdownMenu, DropdownToggle } from '../../../components/bootstrap/Dropdown';
import Input from '../../../components/bootstrap/forms/Input';
import Icon from '../../../components/icon/Icon';
import { NotificationsNone } from '../../../components/icon/material-icons';
import { NotificationContext } from '../../../contexts/NotificationsContext';
import ThemeContext from '../../../contexts/themeContext';
import { priceFormat } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import { SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { getUser, getWorkSpace } from '../../../redux/apiRequest';
import { extensionCheck } from '../../../redux/authSlice';
import { changeWorkSpaceValue } from '../../../redux/workspaceSlice';
import styles from './facebookheader.module.css';
import { checkExpire } from '../../../redux/userSlice';
import { useSocket } from '../../../contexts/socket';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import showNotification from '../../../components/extras/showNotification';

const FacebookHeader = () => {
	const token = window.localStorage.getItem('accessToken');
	const [workspaceId, setWorkSpaceId] = useState(
		Number(window.localStorage.getItem('workspaceId')),
	);
	const isWide = useMedia('(min-width: 769px)');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { mobileDesign, rightMenuStatus } = useContext(ThemeContext);
	const handleOnClick = useCallback(() => navigate('/login'), [navigate]);
	const [optionList, setOptionList] = useState([]);
	const [rawOption, setRawOption] = useState([]);
	const [filterMenu, setFilterMenu] = useState(false);
	const [notifyModal, setNotifyModal] = useState(false);
	const [notifyInfo, setNotifyInfo] = useState({});

	function tinhSoNgayConLai(date) {
		// Ngày hiện tại
		var ngayHienTai = new Date();

		// Chuyển đổi ngày mong muốn thành đối tượng Date
		var ngayMongMuonObject = new Date(date);

		// Tính số milliseconds còn lại
		var soMillisecondsConLai = ngayMongMuonObject - ngayHienTai;

		// Chuyển đổi milliseconds thành số ngày
		var soNgayConLai = Math.ceil(soMillisecondsConLai / (1000 * 60 * 60 * 24));

		return soNgayConLai;
	}
	const {
		listNotifications,
		setListNotifications,
		selectedNotifications,
		setSelectedNotifications,
		setFilterType,
		filterType,
		unseenNotifications,
		setUnseenNotifications,
	} = useContext(NotificationContext);

	const notificationsType = [
		{
			value: 'All',
			text: 'Tất cả',
		},
		{
			value: 'app',
			text: 'Hệ thống',
		},
		{
			value: 'publishPost',
			text: 'Đăng bài',
		},
		{
			value: 'billing',
			text: 'Tài khoản',
		},
	];
	// const notificationsType = ['All', 'App', 'Publishing'];
	const formik = useFormik({
		initialValues: {
			searchInput: '',
		},
	});

	const handleCheckWorkspace = async () => {
		const res = await checkWorkspaceIsActive();
		if (res.status == 200) {
			dispatch(extensionCheck(true));
		} else {
			dispatch(extensionCheck(false));
		}
	};
	useEffect(() => {
		handleCheckWorkspace();
		getUser(token, dispatch);
		getWorkSpace(token, dispatch);
	}, []);
	const userProfile = useSelector((state) => state.user.userProfile);
	const userCurrent = userProfile.currentUser;
	const userCurrentRole = userCurrent?.roles[0];
	const workspaceData = useSelector((state) => state.workspace.workspaceData);
	const workspaceStartId = useSelector((state) => state.workspace.workspaceValue);
	const [currentWorkSpace, setCurrentWorkSpace] = useState({});
	const { socket } = useSocket();
	const { darkModeStatus, setDarkModeStatus } = useDarkMode();
	useEffect(() => {
		socket.off('push_notify').on('push_notify', (message) => {
			setNotifyModal(true);
			setNotifyInfo(message?.data);
			// const newListFollow = listFollowObj.map(
			// 	(item) => message.data.find((arg) => arg.id === item.id) || item,
			// );
			// setListFollowObj(newListFollow);
		});
		socket.off('init_message_done').on('init_message_done', (message) => {
			showNotification(
				'Thành công',
				`Trang ${message?.fanpage?.name} đồng bộ tin nhắn thành công`,
				'success',
			);
		});
	});
	useEffect(() => {
		if (
			userCurrent &&
			Object.keys(userCurrent).length !== 0 &&
			userCurrent?.userpackages &&
			userCurrent?.userpackages.length !== 0 &&
			userCurrent?.userpackages[0]?.end_at
		) {
			if (tinhSoNgayConLai(userCurrent?.userpackages[0]?.end_at) > 0) {
				dispatch(checkExpire(false));
			} else {
				dispatch(checkExpire(true));
			}
		}
	}, [userCurrent]);
	useEffect(() => {
		setWorkSpaceId(workspaceStartId);
		window.localStorage.setItem('workspaceId', workspaceStartId);
	}, [workspaceStartId]);
	const styledBtn = {
		color: darkModeStatus ? 'dark' : 'light',
		hoverShadow: 'default',
		isLight: !darkModeStatus,
		size: 'lg',
	};
	const handleLogout = async () => {
		if (token) {
			const data = {
				Authorization: token,
			};
			const res = await logout(data);
			socket.disconnect();
			handleOnClick();
			dispatch(checkExpire(false));
			window.localStorage.removeItem('accessToken');
			window.localStorage.removeItem('workspaceId');
		} else {
			dispatch(checkExpire(false));
			handleOnClick();
		}
	};
	const Email = styled.span`
		font-weight: bolder;
		margin-right: 5px;
	`;
	const WorkSpaceName = styled.div`
		margin-left: 10px;
		max-width: 165px;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	`;
	const Coin = styled.div`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-left: ${mobileDesign ? '' : '30px'};
		margin-top: ${mobileDesign ? '15px' : ''};
		margin-bottom: ${mobileDesign ? '15px' : ''};
	`;
	const ExpiredDate = styled.div`
		font-weight: bold;
	`;
	// const currentWorkspace = workspaceData.filter((item) => item.id === Number(workspaceId));
	// const otherWorkSpace = workspaceData.filter((item) => item.id !== Number(workspaceId));
	useEffect(() => {
		setRawOption(workspaceData);
		setCurrentWorkSpace(workspaceData.filter((item) => item.id === Number(workspaceStartId)));
		setOptionList(workspaceData);
	}, [workspaceStartId, workspaceData]);
	useEffect(() => {
		let conditions = [];
		if (formik.values.searchInput.length !== 0) {
			conditions.push(function (obj) {
				return obj.name.toLowerCase().includes(formik.values.searchInput.toLowerCase());
			});
		}
		let filteredArray = [];
		if (conditions.length === 0) {
			filteredArray = rawOption;
		} else {
			filteredArray = rawOption.filter(function (obj) {
				return conditions.every(function (condition) {
					return condition(obj);
				});
			});
		}
		setOptionList(filteredArray);
	}, [formik.values.searchInput]);

	const handleMarkAllSeen = async () => {
		const res = await sendGet(`/notify/mark-all-as-seen`);
		if (res.status === 200) {
			getListNotifications();
			setUnseenNotifications(0);
		}
	};

	// const getListNotifications = async () => {
	// 	const res = await sendGet(`/notify/list`);
	// 	if (res.status === 200) {
	// 		setListNotifications(res.data.data);
	// 	}
	// };

	useEffect(() => {
		if (selectedNotifications) {
			readNotification(selectedNotifications);
			setListNotifications((old) =>
				old?.map((x) => {
					if (x?.id === selectedNotifications?.id) {
						return {
							...selectedNotifications,
							read: true,
						};
					} else {
						return x;
					}
				}),
			);
		}
	}, [selectedNotifications]);

	const readNotification = async (noti) => {
		const input = {
			notify_id: noti?.id,
		};
		const res = await sendGet(`/notify/mark-as-seen`, input);
		if (res.status === 200) {
			console.log(res);
		}
	};

	const getListNotifications = async () => {
		const input = {
			type: filterType,
		};
		if (filterType === 'All') {
			delete input.type;
		}
		const res = await sendGet(`/notify/list`, input);
		if (res.status === 200) {
			setListNotifications(res.data.data);
		}
	};

	useEffect(() => {
		getListNotifications();
	}, [filterType]);

	useEffect(() => {
		setUnseenNotifications(userCurrent?.notify_unseen);
	}, [userCurrent]);
	const handleImageError = (event) => {
		event.target.src = AvatarImg; // Đường dẫn của ảnh mặc định
	};
	return (
		<>
			<Header>
				<HeaderLeft className={isWide ? 'd-flex align-items-center' : ''}>
					<Dropdown isOpen={filterMenu} setIsOpen={setFilterMenu}>
						<DropdownToggle>
							<Button isLight color='info' className='d-flex align-items-center'>
								<img
									src={
										currentWorkSpace[0]?.avatar
											? currentWorkSpace[0]?.avatar
											: AvatarImg
									}
									alt='avatar'
									width={45}
									height={45}
									onError={handleImageError}
									className={styles.workspace__avatar}
								/>
								<div>
									<div style={{ marginLeft: '10px' }} className='text-start'>
										Workspace
									</div>
									<WorkSpaceName>{currentWorkSpace[0]?.name}</WorkSpaceName>
								</div>
							</Button>
						</DropdownToggle>
						<DropdownMenu size='lg'>
							<DropdownItem className='border-bottom align-items-center d-flex pb-2'>
								<label
									className='border-0 bg-transparent cursor-pointer me-0'
									htmlFor='searchInput'>
									<Icon icon='Search' size='2x' color='info' />
								</label>
								<Input
									id='searchInput'
									type='search'
									className=''
									placeholder='Tìm kiếm...'
									onChange={formik.handleChange}
									value={formik.values.searchInput}
								/>
							</DropdownItem>
							<div style={{ maxHeight: '30vh', overflowY: 'auto' }}>
								{optionList.map((item) => (
									<DropdownItem
										color='info'
										className='mb-2'
										key={item.id}
										onClick={() => {
											dispatch(changeWorkSpaceValue(item.id));
											window.localStorage.setItem('workspaceId', item.id);
										}}>
										{item?.avatar === null ? (
											<Avatar
												src={AvatarImg}
												// srcSet={USERS.RYAN.srcSet}
												// @ts-ignore
												color={USERS.RYAN.color}
												size={48}
												style={{ cursor: 'pointer' }}
											/>
										) : (
											<img
												src={item?.avatar}
												alt='avatar'
												width={50}
												height={50}
												onError={handleImageError}
												className={styles.workspace__avatar}
											/>
										)}
										<WorkSpaceName
											className={classNames({
												'text-white': darkModeStatus,
												'text-dark': !darkModeStatus,
												'fw-bold': item?.id == workspaceStartId,
											})}>
											{item.name}{' '}
											{item?.id == workspaceStartId ? ' (Đang dùng)' : ''}
										</WorkSpaceName>
									</DropdownItem>
								))}
							</div>
							<DropdownItem className='border-top align-items-center d-flex pt-2'>
								<Button
									onClick={() => {
										navigate('/account/*?communication=facebook');
									}}
									color='info'
									icon='settings'
									className='w-100'
									isLight>
									Cài đặt Workspace
								</Button>
							</DropdownItem>
						</DropdownMenu>
					</Dropdown>
					{isWide ? (
						<>
							<SubheaderSeparator />
							{userCurrent &&
							Object.keys(userCurrent).length !== 0 &&
							userCurrent?.userpackages &&
							userCurrent?.userpackages?.length !== 0 ? (
								<>
									{tinhSoNgayConLai(userCurrent?.userpackages[0]?.end_at) > 0 ? (
										<>
											<Icon icon='Info' className='me-2' size='2x' />
											<span
												className={
													darkModeStatus ? 'text-white' : 'text-dark'
												}>
												Bạn còn lại{' '}
												<Icon
													icon='Check Circle '
													color='success'
													className='mx-1'
													size='lg'
												/>{' '}
												<span className='fw-bold'>
													{userCurrent?.userpackages[0]?.account_limit -
														userCurrent?.account_count}{' '}
													/ {userCurrent?.userpackages[0]?.account_limit}
												</span>{' '}
												tài khoản và{' '}
												<Icon
													icon='Check Circle '
													color='success'
													className='mx-1'
													size='lg'
												/>{' '}
												<span className='fw-bold'>
													{' '}
													{userCurrent?.userpackages[0]?.target_limit -
														userCurrent?.target_count}{' '}
													/ {userCurrent?.userpackages[0]?.target_limit}
												</span>{' '}
												đối tượng khả dụng trong{' '}
												<span className='fw-bold'>
													{tinhSoNgayConLai(
														userCurrent?.userpackages[0]?.end_at,
													)}
												</span>{' '}
												ngày.
											</span>
										</>
									) : (
										<div
											style={{ cursor: 'pointer' }}
											onClick={() => {
												navigate('/payment');
											}}>
											<Icon
												icon='Info'
												color='danger'
												className='me-2'
												size='2x'
											/>
											<span
												className={
													darkModeStatus ? 'text-danger' : 'text-danger'
												}>
												Tài khoản của bạn đã hết hạn
											</span>
										</div>
									)}
								</>
							) : (
								<></>
							)}
						</>
					) : (
						<></>
					)}
				</HeaderLeft>
				<HeaderRight>
					<div
						className={
							mobileDesign
								? ` flex-column g-3 align-items-start`
								: 'd-flex flex-row align-items-center'
						}
						// style={{ display: `${rightMenuStatus ? 'flex' : 'none'}` }}
					>
						{mobileDesign ? (
							<div className='d-flex align-items-center mt-2'>
								<Dropdown>
									<DropdownToggle hasIcon={false}>
										<div>
											<div className='notification-button'>
												<NotificationsNone />
											</div>
											{unseenNotifications > 0 ? (
												<div className={styles.notifications__number}>
													{unseenNotifications}
												</div>
											) : (
												<></>
											)}
										</div>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd className='notification-body'>
										<div className='d-flex align-items-center justify-content-between px-3'>
											<h3 className='m-0 fw-bold'>Thông báo</h3>
											<div>
												<Button
													isLight
													color='success'
													className='d-flex align-items-center'
													onClick={handleMarkAllSeen}>
													Đánh dấu tất cả là đã đọc
												</Button>
											</div>
										</div>
										<div className='pt-3 px-3 filter-button-card'>
											{notificationsType?.map((item, idx) => (
												<Button
													isLight={
														item.value === filterType || !darkModeStatus
															? false
															: true
													}
													color='dark'
													key={idx}
													className={`filter-button`}
													onClick={() => setFilterType(item.value)}>
													{item.text}
												</Button>
											))}
										</div>
										{listNotifications?.length > 0 ? (
											listNotifications?.map((item, idx) => (
												<div
													key={idx}
													className='notification-card'
													onClick={() => {
														setSelectedNotifications(item);
														if (!item?.read) {
															setUnseenNotifications((old) => --old);
														}
														navigate('/setting/notifications');
													}}>
													<div className='d-flex'>
														<div
															className='col-2'
															style={{ position: 'relative' }}>
															<img
																src={
																	item?.notification?.image?.url
																		? item?.notification?.image
																				?.url
																		: `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Facebook_icon.svg/2048px-Facebook_icon.svg.png`
																}
																className='notification-type'
																alt=''
															/>
															{!item?.read ? (
																<div
																	className={
																		styles.unread__notifications
																	}></div>
															) : (
																<></>
															)}
														</div>
														<div className='col-10 ps-2'>
															<div className='d-flex justify-content-between'>
																<p className='notification-title'>
																	{item?.notification?.title}
																</p>
																<p
																	className='m-0'
																	style={{
																		fontSize: '80%',
																		whiteSpace: 'nowrap',
																	}}>
																	{moment(
																		item?.notification
																			?.createdAt,
																	).format('YYYY-MM-DD HH:mm')}
																</p>
															</div>
															{/* <p>{item?.notification?.created_by}</p> */}
															<div>
																{item?.notification?.content.replace(
																	/<[^>]+>/g,
																	'',
																).length < 70
																	? item?.notification?.content.replace(
																			/<[^>]+>/g,
																			'',
																	  )
																	: item?.notification?.content
																			.replace(/<[^>]+>/g, '')
																			.substring(0, 70) +
																	  '...'}
															</div>
														</div>
													</div>
												</div>
											))
										) : (
											<div className={`text-center fw-bold my-3 text-muted`}>
												Không có bài viết nào để hiện thị
											</div>
										)}
										<div className='text-center mt-3'>
											<Link
												to={'/setting/notifications'}
												className={`text-decoration-none ${
													darkModeStatus ? 'text-white' : 'text-dark'
												}`}>
												Xem thêm
											</Link>
										</div>
									</DropdownMenu>
								</Dropdown>
								<Dropdown>
									<DropdownToggle>
										<img
											src={
												userCurrent?.avatar
													? userCurrent?.avatar
													: AvatarImg
											}
											alt='avatar'
											width={50}
											height={50}
											className={styles.workspace__avatar}
										/>
										{/* <Avatar
										src={userCurrent?.avatar ? userCurrent?.avatar : AvatarImg}
										// @ts-ignore
										size={48}
										style={{ cursor: 'pointer' }}
									/> */}
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										className='py-0 overflow-hidden'>
										<div className='row g-0'>
											<div className='p-3 border-bottom d-flex align-items-center col-12'>
												<Avatar
													src={
														userCurrent?.avatar
															? userCurrent?.avatar
															: AvatarImg
													}
													alt='avatar'
													width={50}
													height={50}
													// className={styles.workspace__avatar}
													style={{ cursor: 'pointer' }}
												/>
												<div className='ms-2'>
													<div className=' fw-bold '>
														{userCurrent?.username &&
														userCurrent?.username.length !== 0
															? userCurrent?.username
															: userCurrent?.fullname}
													</div>
													<span className=' text-muted text-truncate'>
														{userCurrent?.email}
													</span>
													<div className='fw-bold '>
														{userCurrent?.coin
															? priceFormat(userCurrent?.coin)
															: priceFormat(0)}
													</div>
												</div>
											</div>
											<div className='col-12 p-3 border-bottom'>
												{userCurrent?.userpackages &&
												userCurrent?.userpackages.length !== 0 &&
												userCurrent?.userpackages[0]?.package?.name ? (
													<Badge
														className='w-100 p-2'
														color={
															(userCurrent?.userpackages[0]?.package
																?.name == 'Free' &&
																'primary') ||
															(userCurrent?.userpackages[0]?.package
																?.name == 'Personal' &&
																'info') ||
															(userCurrent?.userpackages[0]?.package
																?.name == 'Team work' &&
																'warning') ||
															(userCurrent?.userpackages[0]?.package
																?.name == 'Company' &&
																'secondary')
														}>
														{
															userCurrent?.userpackages[0]?.package
																?.name
														}{' '}
														:{' '}
														{moment(
															userCurrent?.userpackages[0]?.end_at,
														).format('YYYY-MM-DD HH:mm')}
													</Badge>
												) : (
													<div className='d-none'></div>
												)}
											</div>
											{/* <div
												className={classNames(
													'col-12 px-2 py-2 transition-base cursor-pointer  ',
													{
														'bg-light-hover': !darkModeStatus,
														'bg-dark-hover': darkModeStatus,
													},
												)}>
												<div
													onClick={() => {
														setDarkModeStatus(!darkModeStatus);
													}}
													className='d-flex align-items-center'>
													<div className='d-flex align-items-center'>
														<Icon
															color={
																!darkModeStatus ? 'info' : 'warning'
															}
															icon={
																!darkModeStatus
																	? 'DarkMode'
																	: 'LightMode'
															}
															size='2x'
														/>
														<span
															className={classNames(
																{
																	'text-white': darkModeStatus,
																	'text-dark': !darkModeStatus,
																},
																'text-decoration-none text-dark fw-bold ms-3',
															)}>
															{!darkModeStatus
																? 'Dark Mode'
																: 'Light Mode'}
														</span>
													</div>
												</div>
											</div> */}
											<a
												className='text-decoration-none'
												href={process.env.REACT_APP_EXTENSIONURL}
												target='_blank'>
												<div
													className={classNames(
														'col-12 px-2 py-2 transition-base cursor-pointer  ',
														{
															'bg-light-hover': !darkModeStatus,
															'bg-dark-hover': darkModeStatus,
														},
													)}>
													<div className='d-flex align-items-center'>
														<div className='d-flex align-items-center'>
															{/* <Icon
															icon='Settings'
															size='2x'
															color='info'
														/> */}
															<img
																src={SettingIcon}
																style={{
																	width: '2rem',
																	height: '2rem',
																}}
																alt=''
															/>
															<span
																className={classNames(
																	{
																		'text-white':
																			darkModeStatus,
																		'text-dark':
																			!darkModeStatus,
																	},
																	'text-decoration-none text-dark fw-bold ms-3',
																)}>
																Cài đặt Extension
															</span>
														</div>
													</div>
												</div>
											</a>
											<Link className='text-decoration-none' to='/setting'>
												<div
													className={classNames(
														'col-12 px-2 py-2 transition-base cursor-pointer  ',
														{
															'bg-light-hover': !darkModeStatus,
															'bg-dark-hover': darkModeStatus,
														},
													)}>
													<div className='d-flex align-items-center'>
														<div className='d-flex align-items-center'>
															{/* <Icon
															icon='ManageAccounts'
															size='2x'
															color='primary'
														/> */}
															<img
																src={AccountIcon}
																style={{
																	width: '2rem',
																	height: '2rem',
																}}
																alt=''
															/>
															<span
																className={classNames(
																	{
																		'text-white':
																			darkModeStatus,
																		'text-dark':
																			!darkModeStatus,
																	},
																	'text-decoration-none text-dark fw-bold ms-3',
																)}>
																Thông tin cá nhân
															</span>
														</div>
													</div>
												</div>
											</Link>
											<Link className='text-decoration-none' to='/calendar'>
												<div
													className={classNames(
														'col-12 px-2 py-2 transition-base cursor-pointer  ',
														{
															'bg-light-hover': !darkModeStatus,
															'bg-dark-hover': darkModeStatus,
														},
													)}>
													<div className='d-flex align-items-center'>
														<div className='d-flex align-items-center'>
															{/* <Icon
															icon='EditCalendar'
															size='2x'
															color='secondary'
														/> */}
															<img
																src={CalendarIcon}
																style={{
																	width: '2rem',
																	height: '2rem',
																}}
																alt=''
															/>
															<span
																className={classNames(
																	{
																		'text-white':
																			darkModeStatus,
																		'text-dark':
																			!darkModeStatus,
																	},
																	'text-decoration-none text-dark fw-bold ms-3',
																)}>
																Lịch đăng bài
															</span>
														</div>
													</div>
												</div>
											</Link>
											{userCurrentRole !== 'client' &&
												userCurrentRole !== 'staff' && (
													<Link
														className='text-decoration-none'
														to='/payment'>
														<div
															className={classNames(
																'col-12 px-2 py-2 transition-base cursor-pointer  border-bottom',
																{
																	'bg-light-hover':
																		!darkModeStatus,
																	'bg-dark-hover': darkModeStatus,
																},
															)}>
															<div className='d-flex align-items-center'>
																<div className='d-flex align-items-center'>
																	{/* <Icon
															icon='Payments'
															size='2x'
															color='warning'
														/> */}
																	<img
																		src={MoneyIcon}
																		style={{
																			width: '2rem',
																			height: '2rem',
																		}}
																		alt=''
																	/>
																	<span
																		className={classNames(
																			{
																				'text-white':
																					darkModeStatus,
																				'text-dark':
																					!darkModeStatus,
																			},
																			'text-decoration-none text-dark fw-bold ms-3',
																		)}>
																		Nâng cấp tài khoản
																	</span>
																</div>
															</div>
														</div>
													</Link>
												)}
											<div
												onClick={handleLogout}
												className={classNames(
													'col-12 px-2 py-2 transition-base cursor-pointer  ',
													{
														'bg-light-hover': !darkModeStatus,
														'bg-dark-hover': darkModeStatus,
													},
												)}>
												<div className='d-flex align-items-center'>
													<div className='d-flex align-items-center'>
														{/* <Icon icon='Logout' size='2x' color='danger' /> */}
														<img
															src={LogoutIcon}
															style={{
																width: '2rem',
																height: '2rem',
															}}
															alt=''
														/>
														<span className='fw-bold ms-3'>
															Đăng xuất
														</span>
													</div>
												</div>
											</div>
										</div>
									</DropdownMenu>
								</Dropdown>
								{/* <Email>{userCurrent?.email}</Email> */}
							</div>
						) : (
							<>
								<Dropdown>
									<DropdownToggle hasIcon={false}>
										<div>
											<div className='notification-button'>
												<NotificationsNone />
											</div>
											{unseenNotifications > 0 ? (
												<div className={styles.notifications__number}>
													{unseenNotifications}
												</div>
											) : (
												<></>
											)}
										</div>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd className='notification-body'>
										<div className='d-flex align-items-center justify-content-between px-3'>
											<h3 className='m-0 fw-bold'>Thông báo</h3>
											<div>
												<Button
													isLight
													color='success'
													className='d-flex align-items-center'
													onClick={handleMarkAllSeen}>
													Đánh dấu tất cả là đã đọc
												</Button>
											</div>
										</div>
										<div className='pt-3 px-3 filter-button-card'>
											{notificationsType?.map((item, idx) => (
												<Button
													isLight={
														item.value === filterType ? false : true
													}
													color='dark'
													key={idx}
													className='filter-button'
													onClick={() => setFilterType(item.value)}>
													{item.text}
												</Button>
											))}
										</div>
										{listNotifications?.length > 0 ? (
											listNotifications?.map((item, idx) => {
												if (idx < 5) {
													return (
														<div
															key={idx}
															className='notification-card'
															onClick={() => {
																setSelectedNotifications(item);
																if (!item?.read) {
																	setUnseenNotifications(
																		(old) => --old,
																	);
																}
																navigate('/setting/notifications');
															}}>
															<div className='d-flex'>
																<div
																	className='col-2'
																	style={{
																		position: 'relative',
																	}}>
																	<img
																		src={
																			item?.notification
																				?.image?.url
																				? item?.notification
																						?.image?.url
																				: `https://upload.wikimedia.org/wikipedia/commons/thumb/1/1b/Facebook_icon.svg/2048px-Facebook_icon.svg.png`
																		}
																		className='notification-type'
																		alt=''
																	/>
																	{!item?.read ? (
																		<div
																			className={
																				styles.unread__notifications
																			}></div>
																	) : (
																		<></>
																	)}
																</div>
																<div className='col-10 ps-2'>
																	<div className='d-flex justify-content-between'>
																		<p className='notification-title'>
																			{item?.notification
																				?.title.length > 30
																				? item?.notification?.title.substring(
																						0,
																						30,
																				  ) + '...'
																				: item?.notification
																						?.title}
																		</p>
																		<p
																			className='m-0'
																			style={{
																				fontSize: '80%',
																				whiteSpace:
																					'nowrap',
																			}}>
																			{moment(
																				item?.notification
																					?.createdAt,
																			).format(
																				'YYYY-MM-DD HH:mm',
																			)}
																		</p>
																	</div>
																	{/* <p>{item?.notification?.created_by}</p> */}
																	<div>
																		{/* {parse(item?.notification?.content).length >
																100
																	? parse(
																			item?.notification?.content,
																	  ).substring(0, 40)
																	: parse(item?.notification?.content)} */}
																		{/* {parse(item?.notification?.content)} */}
																		{item?.notification?.content.replace(
																			/<[^>]+>/g,
																			'',
																		).length < 70
																			? item?.notification?.content.replace(
																					/<[^>]+>/g,
																					'',
																			  )
																			: item?.notification?.content
																					.replace(
																						/<[^>]+>/g,
																						'',
																					)
																					.substring(
																						0,
																						70,
																					) + '...'}
																	</div>
																</div>
															</div>
														</div>
													);
												}
											})
										) : (
											<div className={`text-center fw-bold my-3 text-muted`}>
												Không có bài viết nào để hiện thị
											</div>
										)}
										<div className='text-center mt-3'>
											<Link
												to={'/setting/notifications'}
												className={`text-decoration-none ${
													darkModeStatus ? 'text-white' : 'text-dark'
												}`}>
												Xem thêm
											</Link>
										</div>
									</DropdownMenu>
								</Dropdown>
								<Dropdown>
									<DropdownToggle hasIcon={false}>
										<div
											style={{ cursor: 'pointer' }}
											className='d-flex align-items-center'>
											{/* <Email className='me-2'>{userCurrent?.email}</Email> */}
											<Avatar
												src={
													userCurrent?.avatar
														? userCurrent?.avatar
														: AvatarImg
												}
												alt='avatar'
												width={45}
												height={45}
												// className={styles.workspace__avatar}
												style={{ cursor: 'pointer' }}
											/>
										</div>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										className='py-0 overflow-hidden'>
										<div className='row g-0'>
											<div className='p-3 border-bottom d-flex align-items-center col-12'>
												<Avatar
													src={
														userCurrent?.avatar
															? userCurrent?.avatar
															: AvatarImg
													}
													alt='avatar'
													width={50}
													height={50}
													// className={styles.workspace__avatar}
													style={{ cursor: 'pointer' }}
												/>
												<div className='ms-2'>
													<div className=' fw-bold '>
														{userCurrent?.username &&
														userCurrent?.username.length !== 0
															? userCurrent?.username
															: userCurrent?.fullname}
													</div>

													<span className=' text-muted text-truncate'>
														{userCurrent?.email}
													</span>
													<div className='fw-bold '>
														{userCurrent?.coin
															? priceFormat(userCurrent?.coin)
															: priceFormat(0)}
													</div>
												</div>
											</div>
											<div className='col-12 p-3 border-bottom'>
												{userCurrent?.userpackages &&
												userCurrent?.userpackages.length !== 0 &&
												userCurrent?.userpackages[0]?.package?.name ? (
													<Badge
														className='w-100 p-2'
														color={
															(userCurrent?.userpackages[0]?.package
																?.name == 'Free' &&
																'primary') ||
															(userCurrent?.userpackages[0]?.package
																?.name == 'Personal' &&
																'info') ||
															(userCurrent?.userpackages[0]?.package
																?.name == 'Team work' &&
																'warning') ||
															(userCurrent?.userpackages[0]?.package
																?.name == 'Company' &&
																'secondary')
														}>
														{
															userCurrent?.userpackages[0]?.package
																?.name
														}{' '}
														:{' '}
														{moment(
															userCurrent?.userpackages[0]?.end_at,
														).format('YYYY-MM-DD HH:mm')}
													</Badge>
												) : (
													<div className='d-none'></div>
												)}
											</div>
											{/* <div
												className={classNames(
													'col-12 px-2 py-2 transition-base cursor-pointer  ',
													{
														'bg-light-hover': !darkModeStatus,
														'bg-dark-hover': darkModeStatus,
													},
												)}>
												<div
													onClick={() => {
														setDarkModeStatus(!darkModeStatus);
													}}
													className='d-flex align-items-center'>
													<div className='d-flex align-items-center'>
														<Icon
															color={
																!darkModeStatus ? 'info' : 'warning'
															}
															icon={
																!darkModeStatus
																	? 'DarkMode'
																	: 'LightMode'
															}
															size='2x'
														/>
														<span
															className={classNames(
																{
																	'text-white': darkModeStatus,
																	'text-dark': !darkModeStatus,
																},
																'text-decoration-none text-dark fw-bold ms-3',
															)}>
															{!darkModeStatus
																? 'Dark Mode'
																: 'Light Mode'}
														</span>
													</div>
												</div>
											</div> */}
											<a
												className='text-decoration-none'
												href={process.env.REACT_APP_EXTENSIONURL}
												target='_blank'>
												<div
													className={classNames(
														'col-12 px-2 py-2 transition-base cursor-pointer  ',
														{
															'bg-light-hover': !darkModeStatus,
															'bg-dark-hover': darkModeStatus,
														},
													)}>
													<div className='d-flex align-items-center'>
														<div className='d-flex align-items-center'>
															{/* <Icon
															icon='Settings'
															size='2x'
															color='info'
														/> */}
															<img
																src={SettingIcon}
																style={{
																	width: '2rem',
																	height: '2rem',
																}}
																alt=''
															/>
															<span
																className={classNames(
																	{
																		'text-white':
																			darkModeStatus,
																		'text-dark':
																			!darkModeStatus,
																	},
																	'text-decoration-none text-dark fw-bold ms-3',
																)}>
																Cài đặt Extension
															</span>
														</div>
													</div>
												</div>
											</a>
											<Link className='text-decoration-none' to='/setting'>
												<div
													className={classNames(
														'col-12 px-2 py-2 transition-base cursor-pointer  ',
														{
															'bg-light-hover': !darkModeStatus,
															'bg-dark-hover': darkModeStatus,
														},
													)}>
													<div className='d-flex align-items-center'>
														<div className='d-flex align-items-center'>
															{/* <Icon
															icon='ManageAccounts'
															size='2x'
															color='primary'
														/> */}
															<img
																src={AccountIcon}
																style={{
																	width: '2rem',
																	height: '2rem',
																}}
																alt=''
															/>
															<span
																className={classNames(
																	{
																		'text-white':
																			darkModeStatus,
																		'text-dark':
																			!darkModeStatus,
																	},
																	'text-decoration-none text-dark fw-bold ms-3',
																)}>
																Thông tin cá nhân
															</span>
														</div>
													</div>
												</div>
											</Link>
											<Link className='text-decoration-none' to='/calendar'>
												<div
													className={classNames(
														'col-12 px-2 py-2 transition-base cursor-pointer  ',
														{
															'bg-light-hover': !darkModeStatus,
															'bg-dark-hover': darkModeStatus,
														},
													)}>
													<div className='d-flex align-items-center'>
														<div className='d-flex align-items-center'>
															{/* <Icon
															icon='EditCalendar'
															size='2x'
															color='secondary'
														/> */}
															<img
																src={CalendarIcon}
																style={{
																	width: '2rem',
																	height: '2rem',
																}}
																alt=''
															/>
															<span
																className={classNames(
																	{
																		'text-white':
																			darkModeStatus,
																		'text-dark':
																			!darkModeStatus,
																	},
																	'text-decoration-none text-dark fw-bold ms-3',
																)}>
																Lịch đăng bài
															</span>
														</div>
													</div>
												</div>
											</Link>
											<a
												className='text-decoration-none'
												href='https://amaicontent.com/huong-dan-su-dung/'
												target='_blank'>
												<div
													className={classNames(
														'col-12 px-2 py-2 transition-base cursor-pointer  ',
														{
															'bg-light-hover': !darkModeStatus,
															'bg-dark-hover': darkModeStatus,
														},
													)}>
													<div className='d-flex align-items-center'>
														<div className='d-flex align-items-center'>
															<Icon
																icon='Help'
																size='2x'
																color='secondary'
															/>

															<span
																className={classNames(
																	{
																		'text-white':
																			darkModeStatus,
																		'text-dark':
																			!darkModeStatus,
																	},
																	'text-decoration-none text-dark fw-bold ms-3',
																)}>
																Trợ giúp
															</span>
														</div>
													</div>
												</div>
											</a>
											{userCurrentRole !== 'client' &&
												userCurrentRole !== 'staff' && (
													<Link
														className='text-decoration-none'
														to='/payment'>
														<div
															className={classNames(
																'col-12 px-2 py-2 transition-base cursor-pointer  border-bottom',
																{
																	'bg-light-hover':
																		!darkModeStatus,
																	'bg-dark-hover': darkModeStatus,
																},
															)}>
															<div className='d-flex align-items-center'>
																<div className='d-flex align-items-center'>
																	{/* <Icon
															icon='Payments'
															size='2x'
															color='warning'
														/> */}
																	<img
																		src={MoneyIcon}
																		style={{
																			width: '2rem',
																			height: '2rem',
																		}}
																		alt=''
																	/>
																	<span
																		className={classNames(
																			{
																				'text-white':
																					darkModeStatus,
																				'text-dark':
																					!darkModeStatus,
																			},
																			'text-decoration-none text-dark fw-bold ms-3',
																		)}>
																		Nâng cấp tài khoản
																	</span>
																</div>
															</div>
														</div>
													</Link>
												)}

											<div
												onClick={handleLogout}
												className={classNames(
													'col-12 px-2 py-2 transition-base cursor-pointer  ',
													{
														'bg-light-hover': !darkModeStatus,
														'bg-dark-hover': darkModeStatus,
													},
												)}>
												<div className='d-flex align-items-center'>
													<div className='d-flex align-items-center'>
														{/* <Icon icon='Logout' size='2x' color='danger' /> */}
														<img
															src={LogoutIcon}
															style={{
																width: '2rem',
																height: '2rem',
															}}
															alt=''
														/>
														<span className='fw-bold ms-3'>
															Đăng xuất
														</span>
													</div>
												</div>
											</div>
										</div>
									</DropdownMenu>
								</Dropdown>
							</>
						)}
					</div>
				</HeaderRight>
			</Header>
			<Modal
				isOpen={notifyModal}
				setIsOpen={setNotifyModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={false}>
				<ModalHeader>
					<ModalTitle id='exampleModalLabel'>Thông báo mới từ Admin</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<p className='fs-3 fw-bold mb-0'>{notifyInfo?.title}</p>
					<div
						dangerouslySetInnerHTML={{
							__html: notifyInfo?.content,
						}}></div>
				</ModalBody>
			</Modal>
		</>
	);
};

export default FacebookHeader;
