/* eslint-disable prettier/prettier */
import {configureStore} from '@reduxjs/toolkit'
import authReducer from './authSlice'
import userReducer from './userSlice'
import workspaceReducer from './workspaceSlice'
import youtubeplayerSlice from './youtubeplayerSlice';
export const store = configureStore({
    reducer:{
        auth: authReducer,
        user: userReducer,
        workspace: workspaceReducer,
        youtubeplayer: youtubeplayerSlice,
    }
});