/* eslint-disable prettier/prettier */
import classNames from 'classnames';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { buyAddOns, extendPackage, extendPackageNew } from '../../../api/paymentApi';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody, CardHeader } from '../../../components/bootstrap/Card';
import Modal, {
	ModalBody,
	ModalFooter,
	ModalHeader,
	ModalTitle,
} from '../../../components/bootstrap/Modal';
import Checks from '../../../components/bootstrap/forms/Checks';
import Input from '../../../components/bootstrap/forms/Input';
import Label from '../../../components/bootstrap/forms/Label';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import { priceFormat } from '../../../helpers/helpers';
import useDarkMode from '../../../hooks/useDarkMode';
import styles from './payment.module.css';
import { getUser } from '../../../redux/apiRequest';
const Package = ({
	uid,
	id,
	name,
	price,
	workspace_limit,
	account_limit,
	target_limit,
	discount,
	account_addons,
	target_addons,
	note,
	color,
	submit,
	periodDiscount,
}) => {
	const [staticBackDropStatus, setStaticBackdropStatus] = useState(false);
	const [paymentModal, setPaymentModal] = useState(false);
	const [disableButton, setDisableButton] = useState(false);
	const { themeStatus } = useDarkMode();
	const userProfile = useSelector((state) => state.user.userProfile);
	const userCurrent = userProfile.currentUser;
	const dispatch = useDispatch();
	const token = window.localStorage.getItem('accessToken');
	const formik = useFormik({
		initialValues: {
			month: '1',
			account: 0,
			target: 0,
		},
	});
	const handleChange = () => {
		submit(id, name, price, discount, account_addons, target_addons);
	};
	const submitOder = async () => {
		const toltalPrice =
			Number(formik.values.month) *
				price *
				((Number(formik.values.month) == 1 && (100 - periodDiscount?.one_month) / 100) ||
					(Number(formik.values.month) == 3 &&
						(100 - periodDiscount?.three_month) / 100) ||
					(Number(formik.values.month) == 6 && (100 - periodDiscount?.six_month) / 100) ||
					(Number(formik.values.month) == 12 && (100 - periodDiscount?.one_year) / 100)) +
			(userCurrent?.userpackages[0]?.order?.account_limit_addon_price /
				userCurrent?.userpackages[0]?.package?.account_limit_addon_price +
				formik.values.account) *
				userCurrent?.userpackages[0]?.package?.account_limit_addon_price *
				Number(formik.values.month) +
			(userCurrent?.userpackages[0]?.order?.target_limit_addon_price /
				userCurrent?.userpackages[0]?.package?.target_limit_addon_price +
				formik.values.target) *
				userCurrent?.userpackages[0]?.package?.target_limit_addon_price *
				Number(formik.values.month);
		if (formik.values.account < 0) {
			showNotification('Lỗi', 'Tài khoản xã hội thêm không thể nhỏ hơn 0', 'danger');
		} else if (formik.values.target < 0) {
			showNotification('Lỗi', 'Đối tượng theo dõi thêm không thể nhỏ hơn 0', 'danger');
		} else if (Number(formik.values.month) < 1) {
			showNotification('Lỗi', 'Số tháng gia hạn không thể nhỏ hơn 1', 'danger');
		} else if (toltalPrice > userCurrent?.coin) {
			showNotification('Lỗi', 'Bạn không đủ tiền để thực hiện giao dịch này', 'danger');
		} else {
			setDisableButton(true);
			const input = {
				month_qty: Number(formik.values.month),
				account_addon: formik.values.account,
				target_addon: formik.values.target,
			};

			try {
				const res = await extendPackageNew(input);
				if (res.status == 200) {
					getUser(token, dispatch);
					showNotification('Thành công', res?.message, 'success');
					setPaymentModal(false);
					setDisableButton(false);
				} else {
					showNotification('Lỗi', res?.message, 'danger');
					setDisableButton(false);
				}
			} catch (err) {
				setDisableButton(false);
			}
		}
	};
	return (
		<>
			<Card
				stretch
				className={classNames(
					{
						'bg-l25-primary': id === 1 && themeStatus == 'light',
						'bg-l25-info': id === 2 && themeStatus == 'light',
						'bg-l25-warning': id === 3 && themeStatus == 'light',
						'bg-l25-secondary': id === 4 && themeStatus == 'light',
						'bg-lo25-primary': id === 1 && themeStatus == 'dark',
						'bg-lo25-info': id === 2 && themeStatus == 'dark',
						'bg-lo25-warning': id === 3 && themeStatus == 'dark',
						'bg-lo25-secondary': id === 4 && themeStatus == 'dark',
					},
					'h-100',
				)}>
				<CardHeader borderSize={1} borderColor={color} className='bg-transparent'>
					<div className='col-12 text-center py-4 pb-0 position-relative'>
						<h2 className='fw-bold display-5'>{name}</h2>
						{uid == id ? (
							<span
								className='position-absolute end-0 border border-primary border-2 text-primary fw-bold px-1 py-1 rounded-1 lead text-uppercase'
								style={{
									top: '-10px',
									fontSize: '1rem',
									right: '0',
									width: 'fit-content',
									whiteSpace: 'nowrap',
								}}>
								Đang dùng
							</span>
						) : (
							<div className='d-none'></div>
						)}
					</div>
				</CardHeader>
				<CardBody>
					<div
						className='row text-center justify-content-between'
						// style={{ position: 'relative', height: 'calc(100% + 55px)' }}
					>
						<div className='col-auto mx-auto'>
							{price === 0 ? (
								<>
									<h3 className='display-6 fw-bold'>
										<span className='display-6 fw-bold'>Miễn phí</span>
									</h3>
									<br />
								</>
							) : (
								<>
									<h3 className='display-6 fw-bold'>
										{/* <span className='display-6 fw-bold'>$</span> */}
										{priceFormat(price)}
									</h3>
									<div className='text-end '>
										<span className='fw-bold text-muted text-uppercase'>
											/Tháng
										</span>
									</div>
								</>
							)}
						</div>
						<div className='col-12 text-start'>
							{workspace_limit === -1 ? (
								<div className='lead mb-2'>
									<Icon icon='Done Outline' color='success' /> Không giới hạn
									Workspace.
								</div>
							) : (
								<div className='lead mb-2'>
									<Icon icon='Done Outline' color='success' />{' '}
									<span className='fw-bold'>{workspace_limit}</span> Workspaces.
								</div>
							)}
							<div className='lead mb-2'>
								<Icon icon='Done Outline' color='success' />{' '}
								<span className='fw-bold'>{account_limit}</span> Tài khoản xã hội.
							</div>
							<div className='lead mb-2'>
								<Icon icon='Done Outline' color='success' />{' '}
								<span className='fw-bold'>{target_limit}</span> Đối tượng theo dõi.
							</div>
							<div className='lead mb-2'>
								<Icon icon='Done Outline' color='success' />{' '}
								<span className='fw-bold'>{account_addons.toLocaleString()}đ</span>{' '}
								để thêm <span className='fw-bold'>1</span> tài khoản.
							</div>
							<div className='lead mb-2'>
								<Icon icon='Done Outline' color='success' />{' '}
								<span className='fw-bold'>{target_addons.toLocaleString()}đ</span>{' '}
								để thêm <span className='fw-bold'>1</span> đối tượng.
							</div>
							<div className='lead mb-2'>
								<Icon icon='Done Outline' color='success' /> {note}
							</div>
						</div>
						{id !== 1 && (
							<div className={`col-12 mt-auto ${styles.payment__register__button}`}>
								{uid == id ? (
									<Button
										onClick={() => {
											setPaymentModal(true);
										}}
										color={color}
										isLight
										className='w-100 py-3 text-uppercase'
										size='lg'>
										GIA HẠN
									</Button>
								) : (
									<Button
										onClick={() => {
											handleChange();
											window.scrollTo({
												top: 500,
												behavior: 'smooth',
											});
										}}
										color={color}
										isLight
										className='w-100 py-3 text-uppercase'
										size='lg'>
										ĐĂNG KÝ
									</Button>
								)}
							</div>
						)}
					</div>
				</CardBody>
			</Card>
			<Modal
				isCentered
				isOpen={paymentModal}
				setIsOpen={setPaymentModal}
				titleId='exampleModalLabel'
				isStaticBackdrop={staticBackDropStatus}>
				<ModalHeader className='px-4' setIsOpen={setPaymentModal}>
					<ModalTitle>Gia Hạn</ModalTitle>
				</ModalHeader>
				<ModalBody itemType='delete'>
					<div className='row  mb-2 border-bottom pb-2'>
						<div className='row align-items-center mb-2'>
							<div className='col-12'>
								<Label>Thời hạn</Label>
							</div>
							<div className='row mb-2'>
								<div className='col-3'>
									<Checks
										type='radio'
										name='month'
										id='1month'
										label='1 Tháng'
										value='1'
										onChange={formik.handleChange}
										checked={formik.values.month}
									/>
								</div>
								<div className='col-3'>
									<Checks
										type='radio'
										name='month'
										id='3month'
										label='3 Tháng'
										value='3'
										onChange={formik.handleChange}
										checked={formik.values.month}
									/>
								</div>
								<div className='col-3'>
									<Checks
										type='radio'
										name='month'
										id='6month'
										label='6 Tháng'
										value='6'
										onChange={formik.handleChange}
										checked={formik.values.month}
									/>
								</div>
								<div className='col-3'>
									<Checks
										type='radio'
										name='month'
										id='12month'
										label='12 Tháng'
										value='12'
										onChange={formik.handleChange}
										checked={formik.values.month}
									/>
								</div>
							</div>
							<div className='row align-items-center mb-2'>
								<div className='col-6'>
									<Label>Tài khoản xã hội (Không bắt được)</Label>
								</div>
								<div className='col-6 fw-bold d-flex justify-content-end'>
									<Input
										min={0}
										id='account'
										placeholder='Tài khoản'
										onChange={formik.handleChange}
										value={formik.values.account}
										type='number'
									/>
								</div>
							</div>
							<div className='row align-items-center mb-2'>
								<div className='col-6'>
									<Label>Đối tượng theo dõi (Không bắt buộc)</Label>
								</div>
								<div className='col-6 fw-bold d-flex justify-content-end'>
									<Input
										min={0}
										id='target'
										placeholder='Đối tượng'
										onChange={formik.handleChange}
										value={formik.values.target}
										type='number'
									/>
								</div>
							</div>
						</div>
					</div>
					<p className='fw-bold fs-4'>Gói dịch vụ:</p>
					<div className='d-flex justify-content-between'>
						<div className=''>
							{name}: (discount{' '}
							<span className='fw-bold'>
								{(Number(formik.values.month) == 1 &&
									`${periodDiscount?.one_month}%`) ||
									(Number(formik.values.month) == 3 &&
										`${periodDiscount?.three_month}%`) ||
									(Number(formik.values.month) == 6 &&
										`${periodDiscount?.six_month}%`) ||
									(Number(formik.values.month) == 12 &&
										`${periodDiscount?.one_year}%`)}
							</span>
							)
						</div>
						<p className='fw-bold'>
							{priceFormat(
								Number(formik.values.month) *
									price *
									((Number(formik.values.month) == 1 &&
										(100 - periodDiscount?.one_month) / 100) ||
										(Number(formik.values.month) == 3 &&
											(100 - periodDiscount?.three_month) / 100) ||
										(Number(formik.values.month) == 6 &&
											(100 - periodDiscount?.six_month) / 100) ||
										(Number(formik.values.month) == 12 &&
											(100 - periodDiscount?.one_year) / 100)),
							)}
						</p>
					</div>
					<hr></hr>
					<p className='fw-bold fs-4'>Tài khoản thêm:</p>
					<div className=''>
						<div className='d-flex justify-content-between'>
							<div className=''>
								Tài khoản xã hội: (
								{userCurrent?.userpackages[0]?.order?.account_limit_addon_price /
									userCurrent?.userpackages[0]?.package
										?.account_limit_addon_price +
									formik.values.account}{' '}
								x{' '}
								{priceFormat(
									userCurrent?.userpackages[0]?.package
										?.account_limit_addon_price,
								)}
								)
							</div>
							<p className='fw-bold'>
								{priceFormat(
									(userCurrent?.userpackages[0]?.order
										?.account_limit_addon_price /
										userCurrent?.userpackages[0]?.package
											?.account_limit_addon_price +
										formik.values.account) *
										userCurrent?.userpackages[0]?.package
											?.account_limit_addon_price *
										Number(formik.values.month),
								)}
							</p>
						</div>
						<div className='d-flex justify-content-between'>
							<div className=''>
								Đối tượng theo dõi: (
								{userCurrent?.userpackages[0]?.order?.target_limit_addon_price /
									userCurrent?.userpackages[0]?.package
										?.target_limit_addon_price +
									formik.values.target}{' '}
								x{' '}
								{priceFormat(
									userCurrent?.userpackages[0]?.package?.target_limit_addon_price,
								)}
								)
							</div>
							<p className='fw-bold'>
								{priceFormat(
									(userCurrent?.userpackages[0]?.order?.target_limit_addon_price /
										userCurrent?.userpackages[0]?.package
											?.target_limit_addon_price +
										formik.values.target) *
										userCurrent?.userpackages[0]?.package
											?.target_limit_addon_price *
										Number(formik.values.month),
								)}
							</p>
						</div>
					</div>
					<hr></hr>
					<div className='d-flex justify-content-between'>
						<div className='fw-bold fs-4'>Tổng cộng:</div>
						<p className='fw-bold fs-4'>
							{priceFormat(
								Number(formik.values.month) *
									price *
									((Number(formik.values.month) == 1 &&
										(100 - periodDiscount?.one_month) / 100) ||
										(Number(formik.values.month) == 3 &&
											(100 - periodDiscount?.three_month) / 100) ||
										(Number(formik.values.month) == 6 &&
											(100 - periodDiscount?.six_month) / 100) ||
										(Number(formik.values.month) == 12 &&
											(100 - periodDiscount?.one_year) / 100)) +
									(userCurrent?.userpackages[0]?.order
										?.account_limit_addon_price /
										userCurrent?.userpackages[0]?.package
											?.account_limit_addon_price +
										formik.values.account) *
										userCurrent?.userpackages[0]?.package
											?.account_limit_addon_price *
										Number(formik.values.month) +
									(userCurrent?.userpackages[0]?.order?.target_limit_addon_price /
										userCurrent?.userpackages[0]?.package
											?.target_limit_addon_price +
										formik.values.target) *
										userCurrent?.userpackages[0]?.package
											?.target_limit_addon_price *
										Number(formik.values.month),
							)}
						</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						isDisable={disableButton}
						color='info'
						icon='ShoppingCart'
						onClick={() => {
							submitOder();
						}}>
						Đồng ý
					</Button>
					<Button
						// isOutline
						className='border-0'
						color='dark'
						onClick={() => {
							setPaymentModal(false);
						}}>
						Hủy bỏ
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default Package;
