import React, { useEffect, useState } from 'react';
import '../AboutUs/aboutus.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { FaLongArrowAltRight } from 'react-icons/fa';
import { getListPackage } from '../../../api/paymentApi';
import { COLOR } from '../CalendarPage/color';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import useDarkMode from '../../../hooks/useDarkMode';
import { Skeleton } from '@mui/material';
import Package from '../PaymentPage/Package';
import img1 from '../../../assets/landing/tinh-nang.svg';
import img2 from '../../../assets/landing/img2.svg';
import img3 from '../../../assets/landing/img3.svg';
import img4 from '../../../assets/landing/img4.svg';
import img5 from '../../../assets/landing/img5.svg';

const Price = () => {
	const isWide = useMedia('(min-width: 769px)');
	const [listPackage, setListPackage] = useState([]);
	const [isLoadingPackage, setIsLoadingPackage] = useState(true);
	const { themeStatus } = useDarkMode();
	const navigate = useNavigate();
	useEffect(() => {
		AOS.init();
	}, []);
	const fetchData = async () => {
		const res = await getListPackage();
		if (res.status === 200) {
			setIsLoadingPackage(false);
			const resData = res.data.packages;
			const convertResultArray = resData.map((item, index) => {
				return {
					id: item.id,
					name: item.name,
					price: item.price_per_month,
					workspace_limit: item.workspace_limit,
					account_limit: item.account_limit,
					target_limit: item.target_limit,
					discount: item.percent_sale_off,
					account_addons: item.account_limit_addon_price,
					target_addons: item.target_limit_addon_price,
					note: item.note,
					color: COLOR[++index],
				};
			});
			setListPackage(convertResultArray);
		} else {
			console.log('Lỗi get dữ liệu');
			setIsLoadingPackage(false);
		}
	};
	useEffect(() => {
		fetchData();
	}, []);
	return (
		<div className='d-flex flex-column w-100'>
			<div
				className={classNames({
					affiliate__container: isWide,
					affiliate__container__mobile: !isWide,
				})}>
				<div className='affiliate__section1'>
					<div className='mb-3' style={{ fontSize: '32px', fontWeight: '400' }}>
						BÁO GIÁ SỬ DỤNG AMAI CONTENT
					</div>
					<div className='mb-3' style={{ fontSize: '32px', fontWeight: '700' }}>
						Chăm sóc hàng trăm kênh Social chỉ từ 300.000đ/tháng
					</div>
					<div className='mb-5'>
						<h4 style={{ fontWeight: '400' }}>
							Sử dụng đầy đủ tính năng và chăm sóc hàng trăm kênh Social với AMAI
							Content
						</h4>
					</div>
					<div className='d-flex justify-content-center'>
						<Link to='/register'>
							<button className='about__us__section1__button'>
								Đăng ký ngay <FaLongArrowAltRight className='ms-2' />
							</button>
						</Link>
					</div>
				</div>
			</div>
			<div
				className={classNames({
					about__us__container: isWide,
					about__us__container__moblie: !isWide,
				})}>
				<div className='about__us__section3' style={{ width: '100%' }} data-aos='zoom-in'>
					<Card>
						<CardHeader className='d-flex justify-content-between'>
							<CardLabel icon='Payments'>
								<CardTitle>Các gói dịch vụ</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									icon='Payments'
									color='info'
									tag='a'
									href='setting/recharge'
									className={themeStatus === 'dark' ? 'text-white' : ''}
									isLight>
									Nạp tiền
								</Button>
							</CardActions>
						</CardHeader>
						<CardBody>
							<div className='row w-100 align-items-stretch justify-content-between'>
								{isLoadingPackage ? (
									<>
										{Array.from({ length: 4 }, (_, i) => (
											<div key={i} className='col-12 col-xl-3 mb-3'>
												<Skeleton
													className='h1 my-2'
													variant='rounded'
													animation='wave'
												/>
												<hr className='my-3' />
												<Skeleton
													variant='rounded'
													animation='wave'
													height={300}
												/>
												<Skeleton
													variant='rounded'
													animation='wave'
													height={40}
													className='mt-2 mb-4'
												/>
											</div>
										))}
									</>
								) : (
									<>
										{listPackage.map((i) => (
											<div key={i.id} className='col-12 col-xl-3 mb-3'>
												<Package
													key={i.id}
													{...i}
													submit={() => {
														navigate('/register');
													}}
													// periodDiscount={discountInfo}
													// uid={userCurrent?.userpackages[0].package_id}
												/>
											</div>
										))}
									</>
								)}
							</div>
						</CardBody>
					</Card>
				</div>
			</div>
			<div
				className={classNames({
					about__us__container: isWide,
					about__us__container__moblie: !isWide,
				})}>
				<div className='about__us__section3'>
					<div className='d-flex flex-column align-items-center'>
						<div className='row align-items-center g-5 mt-3' data-aos='fade-right'>
							<div className='col-12 col-lg-6'>
								<img src={img1} alt='' />
							</div>
							<div className='col-12 col-lg-6 about__us__section3__description'>
								<h2 className='about__us__section3__description__header'>
									Tính năng chính của AMAI Content
								</h2>
								<ul>
									<li className='mt-3 text-justify'>
										Đặt lịch đăng bài trên các nền tảng như Facebook, TikTok,
										Instagram, Youtube.
									</li>
									<li className='text-justify mt-1'>
										Reup: Tự động chuyển đổi và đăng lại nội dung từ các nguồn
										khác nhau.
									</li>
									<li className='text-justify mt-1'>
										Lên lịch tự động: Tự động lên lịch đăng hàng trăm bài viết
										theo cấu hình trước.
									</li>
									<li className='text-justify mt-1'>
										Spin nội dung: Tự động chọn ngẫu nhiên nội dung và biểu
										tượng cho mỗi bài đăng.
									</li>
									<li className='text-justify mt-1'>
										Bình luận tự động: Tùy chỉnh kịch bản bình luận cho bài viết
										được đặt lịch.
									</li>
									<li className='text-justify mt-1'>
										Seeding hội nhóm: Thêm nhiều tài khoản đặt lịch đăng nội
										dung vào nhóm của bạn.
									</li>
									<li className='text-justify mt-1'>
										Chia VIA: Hệ thống đăng bài qua các VIA khác nhau để giảm
										spam cho VIA.
									</li>
									<li className='text-justify mt-1'>
										Quản lý bình luận, tin nhắn (phiên bản cơ bản).
									</li>
									<li className='text-justify mt-1'>
										Ứng dụng Amai Content: Cung cấp cảnh báo khi có lỗi với tài
										khoản, bài viết hoặc kênh.
									</li>
									<li className='text-justify mt-1'>
										Báo cáo hiệu suất kênh: Theo dõi lượt tiếp cận, tương tác,
										tệp khách hàng, …
									</li>
									<li className='text-justify mt-1'>
										Báo cáo nhân viên: Giám sát hiệu suất và năng suất của nhân
										sự.
									</li>
									<li className='text-justify mt-1'>
										Báo cáo nội dung: Đánh giá hiệu suất từng bài viết trên các
										nền tảng.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div
				data-aos='fade-left'
				className={classNames({
					affiliate__container: isWide,
					affiliate__container__mobile: !isWide,
				})}>
				<div className='affiliate__section3'>
					{/* <div className='mb-4'></div> */}
					<h1
						style={{
							fontWeight: '700',
							color: 'rgba(64, 12, 176, 1)',
						}}>
						CÁC HỆ THỐNG KHÁC THUỘC HỆ SINH THÁI AMAI AGENCY
					</h1>
					<div className='row g-3 mb-3  align-items-stretch'>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img2} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>AMAI MMO</h2>
							</div>

							<div className='mt-3'>Hệ thống kiếm tiền Online tự động.</div>
						</div>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img3} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>AMAI Social</h2>
							</div>

							<div className='mt-3'>
								Hệ thống tăng tương tác mạng xã hội Facebook, Zalo, Tiktok,
								Instagram, Telegram, Youtube, Twitter
							</div>
						</div>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img4} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>AMAI SEO</h2>
							</div>

							<div className='mt-3'>
								Bộ phần mềm SEO Website và SEO Google Business
							</div>
						</div>
						<div className='col-12 col-lg-3 d-flex flex-column align-items-center '>
							<img src={img5} alt='' />
							<div
								className='d-flex justify-content-center'
								style={{
									height: '60px',
								}}>
								<h2 className='fw-bold'>AMAI Link</h2>
							</div>
							<div className='mt-3'>Rút gọn link và kiếm tiền từ Affiliate</div>
						</div>
					</div>
				</div>
			</div>
			<div
				data-aos='zoom-in'
				className={classNames({
					about__us__container: isWide,
					about__us__container__moblie: !isWide,
				})}>
				<div className='about__us__section3'>
					<div className='d-flex flex-column align-items-center'>
						<h2 className='about__us__section3__text__description'>
							Đăng ký trải nghiệm AMAI Content ngay hôm nay.
						</h2>
						<div className='d-flex align-items-center mt-5'>
							<Link to='/register'>
								<button className='about__us__section1__button'>
									Đăng ký ngay <FaLongArrowAltRight className='ms-2' />
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Price;
