/* eslint-disable prettier/prettier */
import { createContext, useEffect, useState, useContext } from 'react';
import { io } from 'socket.io-client';
import encrypt from './common/encrypt';
import AuthContext from './authContext';

// export const socket = io(process.env.REACT_APP_API_URL);
export const SocketContext = createContext();

export function getAppKey() {
	return window.localStorage.getItem('app-key') || encrypt.generateKey();
}
export function setAppKey(key) {
	return window.localStorage.setItem('app-key', key);
}

export function encryptData(data) {
	if (!!process.env.REACT_APP_ENCRYPT_API_ENABLE) {
		return data;
	} else {
		return { content: encrypt.encode(JSON.stringify(data)) };
	}
}
export function decryptData(data) {
	let dataContent = null;
	if (!!process.env.REACT_APP_ENCRYPT_API_ENABLE) {
		return data;
	} else {
		try {
			dataContent = JSON.parse((data.content && encrypt.decode(data.content)) || '{}');
		} catch (e) {
			console.log(e);
		}
		return dataContent;
	}
}

// socket.on('connect', function () {});
// socket.on('get_info', function () {
// 	const key = getAppKey();
// 	setAppKey(key);
// 	socket.emit('info', encryptData({ key: key, type: 2 }));
// });

export const TYPES = {
	BROWSER: 1,
	USER: 2,
};

export const useSocket = () => {
	return useContext(SocketContext);
};

export const SocketContextProvider = ({ children }) => {
	const [socket, setSocket] = useState(
		io(process.env.REACT_APP_API_URL, {
			autoConnect: false,
			reconnection: false, // Tắt việc thử lại kết nối
			transports: ['websocket'], // Chỉ sử dụng WebSocket
			// Các tùy chọn khác tùy thuộc vào yêu cầu của bạn
		}),
	);
	// const [userToken, setUserToken] = useState(localStorage.getItem('accessToken') || null);
	const {accessToken} = useContext(AuthContext)
	// useEffect(() => {
	// 	console.log('userTokennn')
	// 	// Thiết lập lại kết nối socket mỗi khi userToken thay đổi
	// 	const newSocket = io(process.env.REACT_APP_API_URL, {
	// 		query: { token: userToken },
	// 	});

	// 	setSocket(newSocket);
	// 	return () => {
	// 		newSocket.disconnect();
	// 	};
	// }, [userToken]);
	useEffect(() => {
		if (accessToken && accessToken.length !== 0) {
			socket.emit('init', { socketKey: accessToken });
		}
	}, [accessToken]);
	useEffect(() => {
		if (accessToken && accessToken.length !== 0) {
			socket.connect();
			socket.on('disconnect', function () {});
			socket.on('connect', function () {});
			socket.on('init_socket', function () {
				const key = getAppKey();
				setAppKey(key);
				// socket.emit('info', encryptData({ key: key, type: 2 }));
				socket.emit('init', {
					...encryptData({ key: key, type: 2 }),
					socketKey: accessToken,
				});
			});
			return () => {
				socket.off('disconnect', function () {});
				socket.off('connect', function () {});
				socket.off('get_info', function () {});
			};
		}
	}, [accessToken]);
	return (
		<SocketContext.Provider value={{ socket }}>{children}</SocketContext.Provider>
	);
};
