/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable prettier/prettier */
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useMedia } from 'react-use';
import { login, register } from '../../../api/auth';
import auth_image from '../../../assets/img/login/auth-image.png';
import amai_logo from '../../../assets/img/login/coco-logo.png';
import logoImg from '../../../assets/logo 1.svg';
import { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Button from '../../../components/bootstrap/Button';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import showNotification from '../../../components/extras/showNotification';
import Icon from '../../../components/icon/Icon';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import './login.css';
import styles from './login.module.css';
// interface ILoginHeaderProps {
// 	isNewUser?: boolean;
// }
const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Đăng ký tài khoản,</div>
				<div className='text-center h4 text-muted mb-5'>Đăng ký tài khoản để bắt đầu!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Xin Chào,</div>
			<div className='text-center h4 text-muted mb-5'>Đăng nhập để tiếp tục!</div>
		</>
	);
};

// interface ILoginProps {
// 	isSignUp?: boolean;
// }
const Login = ({ isSignUp }) => {
	const isWide = useMedia('(min-width: 600px)');
	const [cookies, setCookie] = useCookies(['refCode']);
	const token = window.localStorage.getItem('accessToken');
	const { darkModeStatus } = useDarkMode();
	const [signInPassword, setSignInPassword] = useState(false);
	const [singUpStatus, setSingUpStatus] = useState(isSignUp);
	const [err, setErr] = useState('');
	const [showPassword, setShowPassword] = useState(false);
	const [showPasswordSignUp, setShowPasswordSignUp] = useState(false);
	const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const handleOnClick = useCallback(
		() => navigate('/account/*?communication=facebook'),
		[navigate],
	);
	// const { socket, setUserToken } = useSocket();
	const {setAccessToken, accessToken} = useContext(AuthContext)
	const [isLoading, setIsLoading] = useState(false);
	const handleSignUp = async (values) => {
		const data = {
			email: formikSignUp.values.signupEmail,
			password: formikSignUp.values.signupPassword,
			password_confirm: formikSignUp.values.signupPasswordConfirm,
			username: formikSignUp.values.signupName,
			// ref_code: formikSignUp.values.refCode,
			...(cookies.refCode &&
				cookies.refCode.length !== 0 && {
					ref_code: cookies.refCode,
				}),
		};
		const res = await register(data);
		if (res.status === 200) {
			setSingUpStatus(false);
			showNotification('Thành công', res.massage, 'success');
		}
		// Handle res typescript
		else {
			showNotification('Lỗi', res.message, 'danger');
		}
	};
	const handleSubmit = async (values) => {
		const loginData = {
			email: formik.values.loginUsername,
			password: formik.values.loginPassword,
		};
		try {
			const res = await login(loginData);
			if (res.status === 200) {
				setIsLoading(true);
				const accessToken = res.data.token;
				showNotification('Thành công', res.message, 'success');
				// setUserToken(accessToken);
				setAccessToken(accessToken)
				// socket.emit('sendToken', { token: accessToken });
				window.localStorage.setItem('accessToken', accessToken);
				window.localStorage.setItem('workspaceId', '1');
				handleOnClick();
			}
			//handle res typescript
			else {
				showNotification('Lỗi', res.message, 'danger');
			}
		} catch (err) {
			throw err;
		}
	};

	const usernameCheck = (username) => {
		return !!getUserDataWithUsername(username);
	};

	const passwordCheck = (username, password) => {
		return getUserDataWithUsername(username).password === password;
	};

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: {
			loginUsername: '',
			loginPassword: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.loginUsername) {
				errors.loginUsername = 'Bắt buộc';
			}

			if (!values.loginPassword) {
				errors.loginPassword = 'Bắt buộc';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});
	const formikSignUp = useFormik({
		enableReinitialize: true,
		initialValues: {
			signupEmail: '',
			signupName: '',
			signupPassword: '',
			signupPasswordConfirm: '',
			refCode: '',
		},
		validate: (values) => {
			const errors = {};

			if (!values.signupEmail) {
				errors['signupEmail'] = 'Bắt buộc';
			}

			if (!values.signupName) {
				errors['signupName'] = 'Bắt buộc';
			}

			if (!values.signupPassword) {
				errors['signupPassword'] = 'Bắt buộc';
			}

			if (!values.signupPasswordConfirm) {
				errors['signupPasswordConfirm'] = 'Bắt buộc';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: (values) => {
			handleSignUp(values);
		},
	});
	const toggleShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const toggleShowPasswordSignUp = () => {
		setShowPasswordSignUp(!showPasswordSignUp);
	};
	const toggleShowPasswordConfirm = () => {
		setShowPasswordConfirm(!showPasswordConfirm);
	};
	return (
		<>
			{token && token.length !== 0 && !isLoading ? (
				<Navigate to='/account/*?communication=facebook' />
			) : (
				<PageWrapper
					isMember={false}
					isProtected={false}
					title={singUpStatus ? 'Đăng ký' : 'Đăng nhập'}
					className={styles.bg__signup}>
					<div
						className={`h-100 d-flex login-body ${
							isWide ? '' : 'flex-column-reverse'
						}`}>
						<div className='logo__login'>
							<Link to='/'>
								<img src={logoImg} alt='logo_amai' />
							</Link>
						</div>
						<div className='col-xl-6 col-lg-6 col-md-12 shadow-3d-container auth-input-form'>
							<div className='auth-heading'>
								{!singUpStatus ? 'Đăng nhập' : 'Đăng ký'}
							</div>
							<div>
								{singUpStatus ? (
									<>
										<form
											className='auth-form d-flex justify-content-center row'
											onSubmit={(e) => {
												e.preventDefault();
												formikSignUp.handleSubmit();
											}}>
											<div className='col-8'>
												<FormGroup id='signupEmail' className='mb-3'>
													<p className='auth-label'>Email</p>
													<Input
														placeholder='Email'
														id='signupEmail'
														type='email'
														// autoComplete='email'
														value={formikSignUp.values.signupEmail}
														isTouched={formikSignUp.touched.signupEmail}
														invalidFeedback={
															formikSignUp.errors.signupEmail
														}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-8'>
												<FormGroup id='signupName' className='mb-3'>
													<p className='auth-label'>Username</p>
													<Input
														placeholder='Username'
														id='signupName'
														// autoComplete='given-name'
														value={formikSignUp.values.signupName}
														isTouched={formikSignUp.touched.signupName}
														invalidFeedback={
															formikSignUp.errors.signupName
														}
														isValid={formikSignUp.isValid}
														onChange={formikSignUp.handleChange}
														onBlur={formikSignUp.handleBlur}
														onFocus={() => {
															formikSignUp.setErrors({});
														}}
													/>
												</FormGroup>
											</div>
											<div className='col-8'>
												<div className={styles.pwd__input}>
													<FormGroup id='signupPassword' className='mb-3'>
														<p className='auth-label'>Mật khẩu</p>

														<Input
															placeholder='Mật khẩu'
															// autoComplete='your password'
															type={
																showPasswordSignUp
																	? 'text'
																	: 'password'
															}
															id='signupPassword'
															value={
																formikSignUp.values.signupPassword
															}
															isTouched={
																formikSignUp.touched.signupPassword
															}
															invalidFeedback={
																formikSignUp.errors.signupPassword
															}
															isValid={formikSignUp.isValid}
															onChange={formikSignUp.handleChange}
															onBlur={formikSignUp.handleBlur}
															onFocus={() => {
																formikSignUp.setErrors({});
															}}
														/>
													</FormGroup>
													<Button
														className={styles.showpwd__button}
														onClick={toggleShowPasswordSignUp}>
														<Icon icon='RemoveRedEye' />
													</Button>
												</div>
											</div>
											<div className='col-8'>
												<div className={styles.pwd__input}>
													<FormGroup
														id='signupPasswordConfirm'
														className='mb-3'>
														<p className='auth-label'>
															Nhập lại mật khẩu
														</p>

														<Input
															placeholder='Nhập lại mật khẩu'
															id='signupPasswordConfirm'
															type={
																showPasswordConfirm
																	? 'text'
																	: 'password'
															}
															value={
																formikSignUp.values
																	.signupPasswordConfirm
															}
															isTouched={
																formikSignUp.touched
																	.signupPasswordConfirm
															}
															invalidFeedback={
																formikSignUp.errors
																	.signupPasswordConfirm
															}
															isValid={formikSignUp.isValid}
															onChange={formikSignUp.handleChange}
															onBlur={formikSignUp.handleBlur}
															onFocus={() => {
																formikSignUp.setErrors({});
															}}
														/>
													</FormGroup>
													<Button
														className={styles.showpwd__button}
														onClick={toggleShowPasswordConfirm}>
														<Icon icon='RemoveRedEye' />
													</Button>
												</div>
											</div>
											{/* <div className='col-8'>
												<FormGroup id='refCode' className='mb-3'>
													<p className='auth-label'>Mã giới thiệu</p>
													<Input
														placeholder='Mã giới thiệu (nếu có)'
														disabled
														id='refCode'
														value={formikSignUp.values.refCode}
														onChange={formikSignUp.handleChange}
													/>
												</FormGroup>
											</div> */}
											<div
												className={`${
													isWide ? 'mt-5' : 'mt-2'
												} d-flex justify-content-center`}>
												<Button
													color='info'
													className='auth-action-button py-3'
													// onClick={formikSignUp.handleSubmit}
													type='submit'>
													Đăng ký
												</Button>
											</div>
										</form>
									</>
								) : (
									<>
										<form
											className='d-flex justify-content-center row auth-form'
											onSubmit={(e) => {
												e.preventDefault();
												formik.handleSubmit();
											}}>
											<div className='col-8'>
												<FormGroup id='loginUsername' className='mb-3'>
													<p className='auth-label'>
														Email hoặc tên đăng nhập
													</p>
													<Input
														id='loginUsername'
														placeholder='Email/Tên đăng nhập'
														// autoComplete='username'
														value={formik.values.loginUsername}
														isTouched={formik.touched.loginUsername}
														invalidFeedback={
															formik.errors.loginUsername
														}
														isValid={formik.isValid}
														onChange={formik.handleChange}
														onBlur={formik.handleBlur}
														onFocus={() => {
															formik.setErrors({});
														}}
													/>
												</FormGroup>
												<div className={styles.pwd__input}>
													<FormGroup id='loginPassword' className='mb-4'>
														<div className='d-flex justify-content-between'>
															<p className='auth-label'>Mật khẩu</p>

															<Link
																to={'/forgot-password'}
																className='forgot-password-link'>
																Quên mật khẩu
															</Link>
														</div>
														<Input
															placeholder='Mật khẩu'
															id='loginPassword'
															type={
																showPassword ? 'text' : 'password'
															}
															// autoComplete='current-password'
															value={formik.values.loginPassword}
															isTouched={formik.touched.loginPassword}
															invalidFeedback={
																formik.errors.loginPassword
															}
															isValid={formik.isValid}
															onChange={formik.handleChange}
															onBlur={formik.handleBlur}
														/>
													</FormGroup>
													<Button
														className={styles.showpwd__button}
														onClick={toggleShowPassword}>
														<Icon icon='RemoveRedEye' />
													</Button>
												</div>
											</div>
											<div
												className={`${
													isWide ? 'mt-5' : 'mt-2'
												} col-12 d-flex justify-content-center`}>
												<Button
													color='info'
													className='auth-action-button'
													// onClick={formik.handleSubmit}
													type='submit'>
													Đăng nhập
												</Button>
											</div>
										</form>
									</>
								)}
							</div>

							{/* <LoginHeader isNewUser={singUpStatus} /> */}

							{/* BEGIN :: Social Login */}
							{/* {!signInPassword && (
									<>
										<div className='col-12 mt-3 text-center text-muted'>
											OR
										</div>
										<div className='col-12 mt-3'>
											<Button
												isOutline
												color={darkModeStatus ? 'light' : 'dark'}
												className={classNames('w-100 py-3', {
													'border-light': !darkModeStatus,
													'border-dark': darkModeStatus,
												})}
												icon='CustomApple'
												onClick={handleOnClick}>
												Sign in with Apple
											</Button>
										</div>
										<div className='col-12'>
											<Button
												isOutline
												color={darkModeStatus ? 'light' : 'dark'}
												className={classNames('w-100 py-3', {
													'border-light': !darkModeStatus,
													'border-dark': darkModeStatus,
												})}
												icon='CustomGoogle'
												onClick={handleOnClick}>
												Continue with Google
											</Button>
										</div>
									</>
								)} */}
							{/* END :: Social Login */}

							<div
								className='text-center'
								style={{ fontWeight: '500', marginTop: isWide ? '5rem' : '2rem' }}>
								{!singUpStatus ? (
									<p>
										Bạn đã có tài khoản chưa?{' '}
										<button
											className='change-status-button'
											onClick={() => {
												setSignInPassword(false);
												setSingUpStatus(!singUpStatus);
											}}>
											Đăng ký
										</button>
									</p>
								) : (
									<p>
										Bạn đã tài khoản?{' '}
										<button
											className='change-status-button'
											onClick={() => {
												setSignInPassword(false);
												setSingUpStatus(!singUpStatus);
											}}>
											Đăng nhập
										</button>
									</p>
								)}
							</div>
						</div>
						<div className='col-xl-6 col-lg-6 col-md-12 d-none d-lg-flex image-body'>
							<div className='image-div'>
								<img src={auth_image} className='auth-image' alt='' />
								<img src={amai_logo} alt='' className='amai-logo' />
							</div>
							<div className='text-center mt-3'>
								<h1 className='mb-2'>Amai Content</h1>
								<p className='fs-5'>
									Giải pháp Tối ưu hóa Nội dung và Quản lý Mạng Xã Hội hoàn hảo
								</p>
							</div>
						</div>
					</div>
				</PageWrapper>
			)}
		</>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
