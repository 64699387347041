import React, { useEffect, useRef } from 'react';
import './aboutus.css';
import amaiteamImg from '../../../assets/AboutUs/AMAI MMO 1.jpg';
import { FaLongArrowAltRight } from 'react-icons/fa';
import avatarImg from '../../../assets/logos/download.png';
import img1 from '../../../assets/AboutUs/Nhiem vu 1.svg';
import img2 from '../../../assets/AboutUs/Tam nhin 1.svg';
import img3 from '../../../assets/AboutUs/Gia tri cot loi 1.svg';
import { useMedia } from 'react-use';
import classNames from 'classnames';
import ContactForm from './ContactForm';
import AOS from 'aos';
import 'aos/dist/aos.css';

const AboutUs = () => {
	const isWide = useMedia('(min-width: 769px)');

	const ref = useRef(null);

	const handleClick = () => {
		ref.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<div
			className={classNames({
				about__us__container: isWide,
				about__us__container__moblie: !isWide,
			})}>
			<div className='about__us__section1'>
				<div className='d-flex flex-column align-items-stretch mb-5'>
					<h1 className='about__us__section1__header'>About Us</h1>
					<div className='d-flex align-items-center'>
						<div className='about__us__section1__sub__header'>Về chúng tôi</div>
						<div className='about__us__section1__header__line'></div>
					</div>
				</div>
				<div className='row g-5 about__us__section1__description'>
					<div className='col-12 col-lg-6'>
						<img src={amaiteamImg} className='about__us__section1__image' />
					</div>
					<div className='col-12 col-lg-6 '>
						<h2>Về chúng tôi, AMAI Agency!</h2>
						<div className='mb-3 text-justify'>
							Amai Agency là một công ty chuyên cung cấp các giải pháp marketing toàn
							diện cho doanh nghiệp. Với đội ngũ chuyên gia giàu kinh nghiệm và nhiệt
							huyết, Amai Agency cam kết mang đến cho doanh nghiệp của bạn những giải
							pháp marketing hiệu quả nhất, giúp doanh nghiệp của bạn tỏa sáng và đạt
							được những mục tiêu kinh doanh của mình.
						</div>
						<div className='mb-3 text-justify'>
							Với Amai Agency, không có giới hạn cho sự sáng tạo. Chúng tôi không
							ngừng nghiên cứu, phát triển ra những phần mềm và giải pháp marketing
							“mới mẻ - sáng tạo - hữu ích" để phục vụ các chiến lược marketing cho
							doanh nghiệp. Bằng sự “đột phá” qua các dự từ lớn đến nhỏ, Amai Agency
							đã và đang hợp tác với nhiều doanh nghiệp lớn nhỏ trên cả nước, giúp các
							doanh nghiệp này đạt được những thành công đáng kể trong kinh doanh.
						</div>
						<div className='mb-3 text-justify'>
							Hãy để Amai Agency trở thành người bạn đồng hành đáng tin cậy trên con
							đường thành công của bạn. Chúng tôi cam kết mang đến những giải pháp
							sáng tạo, hiệu quả và độc đáo, giúp thương hiệu của bạn vươn tới tầm cao
							mới, vượt qua mọi mong đợi.
						</div>
						<div className='mt-auto d-flex align-items-center'>
							<button className='about__us__section1__button' onClick={handleClick}>
								Liên hệ với chúng tôi <FaLongArrowAltRight className='ms-2' />
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className='about__us__section2' data-aos='fade-right'>
				<div className='row g-5 align-items-center'>
					<div className='col-12 col-lg-6 d-flex flex-column align-items-center'>
						<h4 className='fw-bold'>CEO’s MESSAGE</h4>
						<h1>Lời chào từ CEO</h1>
						<h5 className='mt-2 mb-3 text-justify' style={{ fontWeight: '400' }}>
							Tại Amai Agency, chúng tôi quan niệm rằng sự đột phá trong sáng tạo là
							yếu tố then chốt dẫn đến sự phát triển và thành tựu. Chúng tôi không
							ngừng nỗ lực để phát hiện và khai thác các cơ hội mới, phát triển những
							giải pháp công nghệ tiên phong và hiệu quả, hỗ trợ khách hàng nâng cao
							giá trị doanh nghiệp của họ. Chúng tôi nhận thức rõ rằng mọi hoạt động
							của chúng tôi đều xoay quanh khách hàng. Do đó, chúng tôi cam kết không
							ngừng lắng nghe, tiếp nhận ý kiến và phản hồi từ khách hàng nhằm cùng
							nhau xây dựng một môi trường kinh doanh bền vững. Chúng tôi tin tưởng
							rằng sự hợp tác sâu rộng và bền chặt sẽ đưa chúng tôi và khách hàng cùng
							nhau tiến xa hơn nữa trên hành trình chinh phục các mục tiêu lớn.
						</h5>
						<div className='text-end' style={{ width: '100%' }}>
							<h5 style={{ fontWeight: '700', marginRight: '10%' }}>
								- CEO Hà Trọng Hưng -
							</h5>
						</div>
					</div>
					<div className='col-12 col-lg-6 d-flex justify-content-center align-items-center'>
						<img src={avatarImg} className='about__us__section2__img' alt='' />
					</div>
				</div>
			</div>
			<div className='about__us__section3'>
				<div className='d-flex flex-column align-items-center'>
					<h1 className='fw-bold'>CORPORATE PHILOSOPHY</h1>
					<h1 className='fw-bold'>AMAI Content - Giới thiệu</h1>
					<div className='row align-items-center  g-5 mt-3' data-aos='fade-left'>
						<div className='col-12 col-lg-6 text-center'>
							<img src={img1} alt='' />
						</div>
						<div className='col-12 col-lg-6 about__us__section3__description'>
							<h2 className='about__us__section3__description__header'>SỨ MỆNH</h2>
							<div className='text-justify'>
								Amai Agency là một công ty tư vấn và tiếp thị chuyên nghiệp, được
								thành lập với sứ mệnh “Hỗ trợ doanh nghiệp kiến tạo, chinh phục
								những mục tiêu lớn - BIG GOAL”
							</div>
							<div className='text-justify'>
								Chúng tôi tin rằng, mỗi doanh nghiệp đều có những mục tiêu riêng, và
								chúng tôi mong muốn được đồng hành cùng doanh nghiệp trong hành
								trình kiến tạo và chinh phục những mục tiêu đó. Bằng sự chuyên
								nghiệp, tận tâm và sáng tạo, chúng tôi sẽ giúp doanh nghiệp của bạn:
							</div>
							<ul className='ms-2'>
								<li>Xác định rõ mục tiêu và tầm nhìn</li>
								<li>Xây dựng chiến lược và kế hoạch hành động hiệu quả</li>
								<li>Triển khai và thực hiện chiến lược một cách hiệu quả</li>
								<li>Đo lường và đánh giá hiệu quả chiến lược</li>
							</ul>
						</div>
					</div>
					<div className='row align-items-center g-5 mt-3' data-aos='fade-right'>
						<div className='col-12 col-lg-6 about__us__section3__description'>
							<h2 className='about__us__section3__description__header'>Tầm nhìn</h2>
							<div className='text-justify'>
								Với slogan “Đồng hành cùng doanh nghiệp" Amai Agency mong muốn trở
								thành một công ty tư vấn và cung cấp các giải pháp tiếp thị hàng đầu
								tại Việt Nam, được tin tưởng và lựa chọn bởi các doanh nghiệp lớn
								nhỏ trên cả nước vào năm 2030.  Mở rộng và phát triển hoạt động kinh
								doanh tiếp cận được với các khách hàng ngoài nước vào năm 2025.
							</div>
						</div>
						<div className='col-12 col-lg-6 text-center'>
							<img src={img2} alt='' />
						</div>
					</div>
					<div className='row align-items-center g-5 mt-3' data-aos='fade-left'>
						<div className='col-12 col-lg-6 text-center'>
							<img src={img3} alt='' />
						</div>
						<div className='col-12 col-lg-6 about__us__section3__description'>
							<h2 className='about__us__section3__description__header'>
								Giá trị cốt lõi
							</h2>
							<ul>
								<li>
									<div>Sự sáng tạo luôn được khuyến khích:</div>
									<div className='text-justify'>
										Amai Agency đặt sự sáng tạo là một giá trị cốt lõi. Chúng
										tôi tin rằng để đạt được những ý tưởng độc đáo và hiệu quả,
										cần phải khuyến khích sự sáng tạo. Chúng tôi tạo điều kiện
										cho đội ngũ của mình để tự do thể hiện ý tưởng mới, đồng
										thời khuyến khích họ tìm kiếm các giải pháp sáng tạo trong
										các dự án của chúng tôi.
									</div>
								</li>
								<li>
									<div>Minh bạch, tận tâm được đặt lên hàng đầu:</div>
									<div className='text-justify'>
										Chúng tôi luôn đảm bảo rằng thông tin về dự án, tiến độ và
										kế hoạch được truyền đạt một cách rõ ràng và trung thực cho
										khách hàng.
									</div>
								</li>
								<li>
									<div>Chuyên nghiệp trong tất cả các dự án:</div>
									<div className='text-justify'>
										Chúng tôi sử dụng kiến thức chuyên sâu và kinh nghiệm rộng
										rãi để đảm bảo chất lượng cao và hiệu quả trong mọi dự án.
										Đội ngũ chuyên gia của chúng tôi được đào tạo chuyên nghiệp
										và có kiến thức sâu rộng trong các lĩnh vực liên quan đến
										dịch vụ mà chúng tôi cung cấp.
									</div>
								</li>
								<li>
									<div>Tận tâm lắng nghe - thấu hiểu khách hàng:</div>
									<div className='text-justify'>
										Amai Agency tìm hiểu về mục tiêu, nhu cầu và mong muốn của
										khách hàng để đảm bảo rằng chúng tôi cung cấp những giải
										pháp tốt nhất và phù hợp nhất. Chúng tôi xem mỗi dự án là
										một cơ hội để xây dựng mối quan hệ đáng tin cậy và lâu dài
										với khách hàng.
									</div>
								</li>
								<li>
									<div>Không ngại thử nghiệm:</div>
									<div className='text-justify'>
										Chúng tôi tin rằng việc tìm kiếm những giải pháp mới và khác
										biệt là cách để phát triển và đạt được thành công. 
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='about__us__section4' data-aos='fade-right'>
				<div className='row g-3'>
					<h4 style={{ fontWeight: '400' }} className='col-12'>
						COMPANY INFO
					</h4>
					<h1 style={{ fontWeight: '500' }} className='col-12'>
						Thông tin công ty
					</h1>
					<h2 className='col-12 about__us__section4__title'>Trụ sở chính</h2>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Tên công ty</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Công ty cổ phần dịch vụ & truyền thông Amai
						</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Ngày thành lập</div>
						<div className='col-12 col-lg-7 '>24 tháng 03 năm 2020</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Người đại diện</div>
						<div className='col-12 col-lg-7 text-uppercase'>Hà Trọng Hưng</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Vốn điều lệ</div>
						<div className='col-12 col-lg-7 text-uppercase'>5 tỷ VND</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Văn phòng đại diện</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Toà nhà CT3-2 KĐT Mễ Trì Hạ, Nam Từ Liêm, Hà Nội
						</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Nội dung kinh doanh</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Phát triển phần mềm, dịch vụ marketing
						</div>
					</div>
					<h2 className='col-12 about__us__section4__title mt-5'>Chi nhánh Nhật Bản</h2>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Tên công ty</div>
						<div className='col-12 col-lg-7 text-uppercase'>合同会社ベキジャパン</div>
					</div>

					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Người đại diện</div>
						<div className='col-12 col-lg-7 text-uppercase'>グエン・クォック・フン</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Vốn điều lệ</div>
						<div className='col-12 col-lg-7 text-uppercase'>金500万円</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Văn phòng chính</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							〒115-0051東京都北区浮間3-1-3-305
						</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Nội dung kinh doanh</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Phát triển phần mềm, dịch vụ marketing
						</div>
					</div>
				</div>
			</div>
			<div ref={ref}></div>
			<ContactForm />
		</div>
	);
};

export default AboutUs;
