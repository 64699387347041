/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-undef */
import React from 'react';
import Logo from '../../../components/Logo';
import Header, { HeaderLeft, HeaderRight } from '../../../layout/Header/Header';
import { Link } from 'react-router-dom';
const CustomHeader = () => {
	return (
		<Header>
			<HeaderLeft className='d-flex align-items-center'>
				<Link to='/'>
					<Logo height={32}></Logo>
				</Link>
				{/* <span className='fw-bolder'>Amai Content</span> */}
			</HeaderLeft>
			<HeaderRight>
				{/* <Link to='/login'>
                    <Button
                        icon='Login'>
                        Đăng nhập
                    </Button>
                </Link> */}
			</HeaderRight>
		</Header>
	);
};

export default CustomHeader;
