import * as React from 'react';
import { SVGProps } from 'react';

const SvgCustomUpload = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 64 64'
		width='1em'
		height='1em'
		className='svg-icon'
		fill='currentColor'

		{...props}>
		<g data-name='Layer 5'>
			<path d='M24 37a7 7 0 1 0 7 7 7.008 7.008 0 0 0-7-7Zm0 12a5 5 0 1 1 5-5 5.006 5.006 0 0 1-5 5Z' />
			<path d='M63 19a18 18 0 1 0-34.962 6H15a1 1 0 0 0-.895.553L12.382 29H5a4 4 0 0 0-4 4v22a4 4 0 0 0 4 4v1a3 3 0 0 0 3 3h32a3 3 0 0 0 3-3v-1a4 4 0 0 0 4-4V36.882A18.02 18.02 0 0 0 63 19Zm-47.382 8h13.275a17.864 17.864 0 0 0 1.163 2H14.618ZM5 31h26.609a18 18 0 0 0 5.174 4h-6.478A10.938 10.938 0 0 0 17.7 35H3v-2a2 2 0 0 1 2-2Zm19 22a9 9 0 1 1 9-9 9.011 9.011 0 0 1-9 9Zm-8.479-2H3V37h12.521a10.979 10.979 0 0 0 0 14ZM41 60a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-1h34Zm2-3H5a2 2 0 0 1-2-2v-2h14.7a10.938 10.938 0 0 0 12.61 0H45v2a2 2 0 0 1-2 2Zm2-6H32.479a11 11 0 0 0 2.095-4H36a3 3 0 0 0 0-6h-1.426a11 11 0 0 0-2.095-4h3.99l-.439 1.757a1 1 0 0 0 1.5 1.091l4.883-3.048A17.467 17.467 0 0 0 45 37Zm-10.051-8H36a1 1 0 0 1 0 2h-1.051c.03-.33.051-.662.051-1s-.021-.67-.051-1ZM45 35a15.992 15.992 0 0 1-2.636-.236.993.993 0 0 0-.694.138l-3.1 1.937.515-2.061a1 1 0 0 0-.564-1.156A15.985 15.985 0 1 1 45 35Z' />
			<path d='M40 14h2v6a1 1 0 0 0 1 1h4a1 1 0 0 0 1-1v-6h2a1 1 0 0 0 .707-1.707l-5-5a1 1 0 0 0-1.414 0l-5 5A1 1 0 0 0 40 14Zm5-4.586L47.586 12H47a1 1 0 0 0-1 1v6h-2v-6a1 1 0 0 0-1-1h-.586Z' />
			<path d='M54 20h-4a1 1 0 0 0-.949.684L48.279 23h-6.558l-.772-2.316A1 1 0 0 0 40 20h-4a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-7a1 1 0 0 0-1-1Zm-1 7H37v-5h2.279l.772 2.316A1 1 0 0 0 41 25h8a1 1 0 0 0 .949-.684L50.721 22H53Z' />
		</g>
	</svg>
);

export default SvgCustomUpload;
