import * as React from 'react';
import { SVGProps } from 'react';

const SvgCustomLike = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='1em'
		height='1em'
		viewBox='0 0 17.863 18.905'
		className='svg-icon'
		{...props}>
		<defs>
			<style>
			{
					'.custom-like_svg__a{fill:#fff;stroke:#606060;stroke-linecap:round;stroke-linejoin:round}'
				}
			</style>
		</defs>
		<path
			className='custom-like_svg__a'
			d='M4.206 8.932h-3.2a.494.494 0 0 0-.508.474v8.526a.493.493 0 0 0 .508.473h3.2a.493.493 0 0 0 .508-.473V9.406a.493.493 0 0 0-.508-.474ZM16.975 12.178a1.4 1.4 0 0 1 .388-2.68 1.409 1.409 0 0 0-1.4-1.405h-5.1a11.281 11.281 0 0 0 1.211-3.617c.323-2.1-.226-3.779-1.55-3.973 0 0-1.873-.129-1.486 3 0 0-2.276 3.186-4.295 5.813v7.3a1.47 1.47 0 0 0 1.437 1.542 1.116 1.116 0 0 0 .129.008h9.108a1.147 1.147 0 0 0 0-2.293h.21a1.147 1.147 0 0 0 0-2.293h-.049a1.409 1.409 0 0 0 1.397-1.402Z'
		/>
	</svg>
);

export default SvgCustomLike;
