/* eslint-disable prettier/prettier */
// import ReactDOM from 'react-dom'; // For React 17
import 'bootstrap/dist/js/bootstrap.bundle';
import { CookiesProvider } from 'react-cookie';
import { createRoot } from 'react-dom/client'; // For React 18
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App/App';
import { AxiosInterceptor } from './api/axiosClients';
import { AuthContextProvider } from './contexts/authContext';
import { LoadingProvider } from './contexts/loading';
import { ThemeContextProvider } from './contexts/themeContext';
import './i18n';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './styles/styles.scss';
const children = (
	<Provider store={store}>
		<AuthContextProvider>
		<ThemeContextProvider>
			<Router>
				<LoadingProvider>
					{/* <GoogleOAuthProvider clientId='633639369139-qpr3nlm62gm8pj2em9j23r4k1u80ku08.apps.googleusercontent.com'> */}
						<AxiosInterceptor>
							<CookiesProvider defaultSetOptions={{ path: '/' }}>
								<App />
							</CookiesProvider>
						</AxiosInterceptor>
					{/* </GoogleOAuthProvider> */}
				</LoadingProvider>
			</Router>
		</ThemeContextProvider>
		</AuthContextProvider>
	</Provider>
);

const container = document.getElementById('root');

// ReactDOM.render(children, container); // For React 17
createRoot(container as Element).render(children); // For React 18

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
