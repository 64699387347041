/* eslint-disable prettier/prettier */
import React, { lazy } from 'react';
import {
	componentsMenu,
	dashboardMenu,
	demoPages,
	layoutMenu,
	Terms,
	ToolMenu,
	trendingMenu,
	adminMenu,
} from '../menu';
import Login from '../pages/presentation/auth/Login';
import ForgotPassword from '../pages/presentation/forgot-password/ForgotPassword';
const TRENDING = {
	FACEBOOK_TRENDING: lazy(() => import('../pages/ExtraPage/FacebookTrending/FacebookTrending')),
};
const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	DASHBOARD_BOOKING: lazy(() => import('../pages/dashboard/DashboardBookingPage')),
	FACEBOOK: lazy(() => import('../pages/ExtraPage/Facebook/Facebook')),
	INSTAGRAM: lazy(() => import('../pages/ExtraPage/Instagram/Instagram')),
	SETTING: lazy(() => import('../pages/ExtraPage/SettingPage/SettingPage')),
	POSTSTORE: lazy(() => import('../pages/ExtraPage/PostStore/PostStore')),
	HELP: lazy(() => import('../pages/ExtraPage/Help/Help')),
	AUTOCOMMENT: lazy(() => import('../pages/ExtraPage/AutoComment/AutoComment')),
	AUTOREACTION: lazy(() => import('../pages/ExtraPage/AutoReaction/AutoReaction')),
	PAYMENT: lazy(() => import('../pages/ExtraPage/PaymentPage/Payment')),
	UPLOADHISTORY: lazy(() => import('../pages/ExtraPage/UploadHistoryPage/UploadHistoryPage')),
	CALENDAR: lazy(() => import('../pages/ExtraPage/CalendarPage/CalendarPage')),
	WORKSPACE: lazy(() => import('../pages/ExtraPage/WorkSpace/WorkSpace')),
	FBTOOL: lazy(() => import('../pages/ExtraPage/FacebookTool/FacebookTool')),
	LANDING: lazy(() => import('../pages/ExtraPage/SecondLandingPage/SecondLandingPage')),
	PROFILEINFO: lazy(() => import('../pages/ExtraPage/ProfileInfo/ProfileInfo')),
	// LANDING: lazy(() => import('../pages/ExtraPage/LandingPage/LandingPage')),
	ANALYTICS: lazy(() => import('../pages/ExtraPage/Analytics/Analytics')),
	CONTENTPOST: lazy(() => import('../pages/ExtraPage/ContentPost/ContentPost')),
	INBOX: lazy(() => import('../pages/ExtraPage/Inbox/Inbox')),
	ANALYTICSSYSTEM: lazy(() => import('../pages/ExtraPage/AnalyticsSystem/AnalyticsSystem')),
	COMPETITOR: lazy(() => import('../pages/ExtraPage/Competitor/Competitor')),
	COMPETITORGROUP: lazy(() => import('../pages/ExtraPage/Competitor/GroupCompetior')),
	GALLERY: lazy(() => import('../pages/ExtraPage/CollectionPage/CollectionPage')),
	MEMBER: lazy(() => import('../pages/ExtraPage/MemberPage/MemberPage')),

};
const POST = {
	FBPOST: lazy(() => import('../pages/dashboard/Facebook')),
	IGPOST: lazy(() => import('../pages/ExtraPage/InstagramPost/InstagramPost')),
	YTPOST: lazy(() => import('../pages/ExtraPage/YoutubePost/YoutubePost')),
	TTPOST: lazy(() => import('../pages/ExtraPage/TiktokPostPage/TiktokPostPage')),
};
const POSTFOLLOW = {
	FBPOSTFOLLOW: lazy(() => import('../pages/ExtraPage/FollowListPage/FollowListPage')),
	IGPOSTFOLLOW: lazy(() => import('../pages/ExtraPage/FollowIgPage/FollowIgPage')),
	YTPOSTFOLLOW: lazy(() => import('../pages/ExtraPage/FollowYtPage/FollowYtPage')),
	TTPOSTFOLLOW: lazy(() => import('../pages/ExtraPage/TiktokTarget/TiktokTarget')),
	DOUYINPOSTFOLLOW: lazy(() => import('../pages/ExtraPage/DouyinTargetPage/DouyinTarget')),
	KOUAISHOUPOSTFOLLOW: lazy(() => import('../pages/ExtraPage/KuaishouTarget/KuaishouTarget')),
};
const UPLOAD = {
	FBUPLOAD: lazy(() => import('../pages/ExtraPage/UploadPage/UploadPage')),
	IGUPLOAD: lazy(() => import('../pages/ExtraPage/UploadIgPage/UploadIgPage')),
};
const FACEBOOK = {
	GROUP: lazy(() => import('../pages/ExtraPage/Facebook/Group/Group')),
	PAGE: lazy(() => import('../pages/ExtraPage/Facebook/Page/Page')),
	ACCOUNT: lazy(() => import('../pages/ExtraPage/Facebook/Facebook')),
};
const POSTAUTO = {
	AUTOPOST: lazy(() => import('../pages/ExtraPage/PostAuto/PostAutoPage/PostAutoPage')),
	AUTOREACTION: lazy(() => import('../pages/ExtraPage/PostAuto/AutoReaction/Autoreaction')),
	IGAUTOPOST: lazy(() => import('../pages/ExtraPage/IgPostAuto/IgPostAuto')),
	AUTOTOKENPOST: lazy(() => import('../pages/ExtraPage/PostAuto/AutoPostToken/AutoTokenpost')),
};
const ADMIN = {
	EXTENSION: lazy(() => import('../pages/ExtraPage/Admin Manager/Extensionpage/Extension')),
	PAYMENTHISTORY: lazy(
		() => import('../pages/ExtraPage/Admin Manager/PaymentHistory/PaymentHistory'),
	),
	USERMANAGEMENT: lazy(
		() => import('../pages/ExtraPage/Admin Manager/UserManagement/UserManagement'),
	),
	NOTIFICATIONS: lazy(
		() => import('../pages/ExtraPage/Admin Manager/AdminNotifications/AdminNotifications'),
	),
	CONFIG: lazy(() => import('../pages/ExtraPage/Admin Manager/AdminConfig/AdminConfig')),
	ANALYST: lazy(() => import('../pages/ExtraPage/Admin Manager/Analyst/Analyst')),
	SCRIPT: lazy(() => import('../pages/ExtraPage/Admin Manager/Script/ScriptPage')),
	CATEGORY: lazy(() => import('../pages/ExtraPage/Admin Manager/CategoryPage/CategoryPage')),
	WORKSPACE: lazy(() => import('../pages/ExtraPage/Admin Manager/WorkspacePage/Workspace')),
	ACCOUNT: lazy(() => import('../pages/ExtraPage/Admin Manager/AccountPage/AccountPage')),
	// FBPOST: lazy(() => import('../pages/ExtraPage/Admin Manager/FbPostPage/FBPostPage')),
	AUTOFB: lazy(() => import('../pages/ExtraPage/Admin Manager/AutoFbPage/AutoFbPage')),
	WITHDRAW: lazy(
		() => import('../pages/ExtraPage/Admin Manager/WithdrawManager/WithdrawManager'),
	),
	BANNERREF: lazy(() => import('../pages/ExtraPage/Admin Manager/BannerRef/BannerRef')),
	PAYMENTMETHOD: lazy(
		() => import('../pages/ExtraPage/Admin Manager/PaymentMethodPage/PaymentMethodPage'),
	),
};

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
const TOOL = {
	CAROUSELVIDEO: lazy(
		() =>
			import('../pages/ExtraPage/FacebookTool/ToolItem/VideoCarouselPost/VideoCarouselPost'),
	),
	CLONEREEL: lazy(
		() =>
			import('../pages/ExtraPage/FacebookTool/ToolItem/CloneReelsVideoToCarousel/CloneReels'),
	),
	CLONECAROUSEL: lazy(
		() =>
			import('../pages/ExtraPage/FacebookTool/ToolItem/CloneCarouselPost/CloneCarouselPost'),
	),
};
const TERMS = {
	TERMS: lazy(() => import('../pages/ExtraPage/PrivacyPolicy/Terms and Conditions/Terms')),
	POLICY: lazy(() => import('../pages/ExtraPage/PrivacyPolicy/Privacy Policy/PrivacyPolicy')),
};
const presentation = [
	/**
	 * Landing
	 */
	// {
	// 	path: dashboardMenu.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.dashboardBooking.path,
	// 	element: <LANDING.DASHBOARD_BOOKING />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.summary.path,
	// 	element: <LANDING.SUMMARY />,
	// 	exact: true,
	// },
	// {
	// 	path: trendingMenu.trending.path,
	// 	element: <TRENDING.FACEBOOK_TRENDING />,
	// 	exact: true,
	// },
	{
		path: 'profileInfo/*',
		element: <LANDING.PROFILEINFO />,
		exact: true,
	},
	{
		path: dashboardMenu.landing.path,
		element: <LANDING.LANDING />,
		exact: true,
	},
	{
		path: trendingMenu.analytics.path,
		element: <LANDING.ANALYTICS />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.contentPost.path,
	// 	element: <LANDING.CONTENTPOST />,
	// 	exact: true,
	// },
	{
		path: trendingMenu.trendingfacebook.path,
		element: <TRENDING.FACEBOOK_TRENDING />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.workspace.path,
	// 	element: <LANDING.WORKSPACE />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.facebook.path,
		element: <LANDING.FACEBOOK />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.facebook.subMenu.instagram.path,
	// 	element: <LANDING.INSTAGRAM />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.poststore.path,
	// 	element: <LANDING.POSTSTORE />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.help.path,
		element: <LANDING.HELP />,
		exact: true,
	},
	{
		path: trendingMenu.analyticsSystem.path,
		element: <LANDING.ANALYTICSSYSTEM />,
		exact: true,
	},
	{
		path: dashboardMenu.paymentpage.path,
		element: <LANDING.PAYMENT />,
		exact: true,
	},
	{
		path: dashboardMenu.settingpage.path,
		element: <LANDING.SETTING />,
		exact: true,
	},
	{
		path: dashboardMenu.autocomment.path,
		element: <LANDING.AUTOCOMMENT />,
		exact: true,
	},
	{
		path: dashboardMenu.autoreaction.path,
		element: <LANDING.AUTOREACTION />,
		exact: true,
	},
	{
		path: trendingMenu.competitor.path,
		element: <LANDING.COMPETITORGROUP />,
		exact: true,
	},
	{
		path: '/competitor/*',
		element: <LANDING.COMPETITOR />,
		exact: true,
	},
	{
		path: dashboardMenu.calendarpage.path,
		element: <LANDING.CALENDAR />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.facebooktool.path,
	// 	element: <LANDING.FBTOOL />,
	// 	exact: true,
	// },
	{
		path: ToolMenu.carouselvideo.path,
		element: <TOOL.CAROUSELVIDEO />,
		exact: true,
	},
	{
		path: ToolMenu.cloneFBReel.path,
		element: <TOOL.CLONEREEL />,
		exact: true,
	},
	{
		path: ToolMenu.clonecarousel.path,
		element: <TOOL.CLONECAROUSEL />,
		exact: true,
	},
	/** ************************************************** */

	/**
	 * Pages
	 */

	/**
	 * Single Pages
	 */
	// {
	// 	path: dashboardMenu.facebook.subMenu.account.path,
	// 	element: <FACEBOOK.ACCOUNT />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.facebook.subMenu.group.path,
	// 	element: <FACEBOOK.GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.facebook.subMenu.page.path,
	// 	element: <FACEBOOK.PAGE />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.post.subMenu.facebookpost.path,
		element: <POST.FBPOST />,
		exact: true,
	},
	{
		path: dashboardMenu.post.subMenu.instagrampost.path,
		element: <POST.IGPOST />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.post.subMenu.youtubepost.path,
	// 	element: <POST.YTPOST />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.post.subMenu.tiktokpost.path,
		element: <POST.TTPOST />,
		exact: true,
	},
	{
		path: dashboardMenu.inbox.path,
		element: <LANDING.INBOX />,
		exact: true,
	},
	{
		path: dashboardMenu.followlistpage.subMenu.followListFacebook.path,
		element: <POSTFOLLOW.FBPOSTFOLLOW />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.followlistpage.subMenu.followListKouaishou.path,
	// 	element: <POSTFOLLOW.KOUAISHOUPOSTFOLLOW />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.followlistpage.subMenu.followListTiktok.path,
		element: <POSTFOLLOW.TTPOSTFOLLOW />,
		exact: true,
	},
	{
		path: dashboardMenu.followlistpage.subMenu.followListDouyin.path,
		element: <POSTFOLLOW.DOUYINPOSTFOLLOW />,
		exact: true,
	},
	{
		path: dashboardMenu.uploadhistory.path,
		element: <LANDING.UPLOADHISTORY />,
		exact: true,
	},
	{
		path: dashboardMenu.memberpage.path,
		element: <LANDING.MEMBER />,
		exact: true,
	},
	{
		path: dashboardMenu.gallery.path,
		element: <LANDING.GALLERY />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.followlistpage.subMenu.followListYoutube.path,
	// 	element: <POSTFOLLOW.YTPOSTFOLLOW />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.followlistpage.subMenu.followListInstagram.path,
		element: <POSTFOLLOW.IGPOSTFOLLOW />,
		exact: true,
	},
	{
		path: dashboardMenu.uploadpage.path,
		element: <UPLOAD.FBUPLOAD />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.uploadpage.subMenu.uploadFbPage.path,
	// 	element: <UPLOAD.FBUPLOAD />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.uploadpage.subMenu.uploadIgPage.path,
	// 	element: <UPLOAD.IGUPLOAD />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.postauto.path,
		element: <POSTAUTO.AUTOPOST />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.postauto.subMenu.autoIgpost.path,
	// 	element: <POSTAUTO.IGAUTOPOST />,
	// 	exact: true,
	// },

	// {
	// 	path: dashboardMenu.postauto.subMenu.autoFbTokenPost.path,
	// 	element: <POSTAUTO.AUTOTOKENPOST />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.postauto.subMenu.autoreaction.path,
	// 	element: <POSTAUTO.AUTOREACTION />,
	// 	exact: true,
	// },

	{
		path: adminMenu.adminManger.subMenu.extension.path,
		element: <ADMIN.EXTENSION />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.adminAnalyst.path,
		element: <ADMIN.ANALYST />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.managermentwithdraw.path,
		element: <ADMIN.WITHDRAW />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.bannerRef.path,
		element: <ADMIN.BANNERREF />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.notifications.path,
		element: <ADMIN.NOTIFICATIONS />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.config.path,
		element: <ADMIN.CONFIG />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.autofb.path,
		element: <ADMIN.AUTOFB />,
		exact: true,
	},
	// {
	// 	path: adminMenu.adminManger.subMenu.fbpost.path,
	// 	element: <ADMIN.FBPOST />,
	// 	exact: true,
	// },
	{
		path: adminMenu.adminManger.subMenu.paymentMethod.path,
		element: <ADMIN.PAYMENTMETHOD />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.workspace.path,
		element: <ADMIN.WORKSPACE />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.account.path,
		element: <ADMIN.ACCOUNT />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.category.path,
		element: <ADMIN.CATEGORY />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.usermanger.path,
		element: <ADMIN.USERMANAGEMENT />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.paymenthistory.path,
		element: <ADMIN.PAYMENTHISTORY />,
		exact: true,
	},
	{
		path: adminMenu.adminManger.subMenu.script.path,
		element: <ADMIN.SCRIPT />,
		exact: true,
	},
	{
		path: Terms.terms.path,
		element: <TERMS.TERMS />,
		exact: true,
	},
	{
		path: Terms.policy.path,
		element: <TERMS.POLICY />,
		exact: true,
	},
	// {
	// 	path: demoPages.singlePages.subMenu.boxedSingle.path,
	// 	element: <SINGLE.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.singlePages.subMenu.fluidSingle.path,
	// 	element: <SINGLE.FLUID />,
	// 	exact: true,
	// },

	// /**
	//  * List
	//  */
	// {
	// 	path: demoPages.listPages.subMenu.listBoxed.path,
	// 	element: <LIST.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.listPages.subMenu.listFluid.path,
	// 	element: <LIST.FLUID />,
	// 	exact: true,
	// },

	// /**
	//  * Grid
	//  */
	// {
	// 	path: demoPages.gridPages.subMenu.gridBoxed.path,
	// 	element: <GRID.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.gridPages.subMenu.gridFluid.path,
	// 	element: <GRID.FLUID />,
	// 	exact: true,
	// },

	// /**
	//  * Edit
	//  */
	// {
	// 	path: demoPages.editPages.subMenu.editModern.path,
	// 	element: <EDIT.MODERN />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editBoxed.path,
	// 	element: <EDIT.BOXED />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editFluid.path,
	// 	element: <EDIT.FLUID />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editWizard.path,
	// 	element: <EDIT.WIZARD />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editInCanvas.path,
	// 	element: <EDIT.IN_CANVAS />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.editPages.subMenu.editInModal.path,
	// 	element: <EDIT.IN_MODAL />,
	// 	exact: true,
	// },

	// {
	// 	path: demoPages.pricingTable.path,
	// 	element: <PRICING.PRICING_TABLE />,
	// 	exact: true,
	// },

	/**
	 * END - Pages
	 */

	/**
	 * Auth Page
	 */
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: '/register',
		element: <Login isSignUp={true} />,
		exact: true,
	},
	{
		path: demoPages.forgotPassword.path,
		element: <ForgotPassword />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},

	/**
	 * App
	 */

	/**
	 * App > Project Management
	 */
	// {
	// 	path: demoPages.projectManagement.subMenu.list.path,
	// 	element: <APP.PROJECT_MANAGEMENT.PROJECTS_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.projectManagement.subMenu.itemID.path}/:id`,
	// 	element: <APP.PROJECT_MANAGEMENT.PROJECT />,
	// 	exact: true,
	// },

	// /**
	//  * App > Knowledge
	//  */
	// {
	// 	path: demoPages.knowledge.subMenu.grid.path,
	// 	element: <APP.KNOWLEDGE.GRID />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.knowledge.subMenu.itemID.path}/:id`,
	// 	element: <APP.KNOWLEDGE.VIEW />,
	// 	exact: true,
	// },

	// /**
	//  * App > Sales
	//  */
	// {
	// 	path: demoPages.sales.subMenu.transactions.path,
	// 	element: <APP.SALES.TRANSACTIONS />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.sales.subMenu.salesList.path,
	// 	element: <APP.SALES.PRODUCTS />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.sales.subMenu.productsGrid.path,
	// 	element: <APP.SALES.PRODUCTS_GRID />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.sales.subMenu.productID.path}/:id`,
	// 	element: <APP.SALES.PRODUCTS_VIEW />,
	// 	exact: true,
	// },

	// /**
	//  * App > Appointment
	//  */
	// {
	// 	path: demoPages.appointment.subMenu.calendar.path,
	// 	element: <APP.APPOINTMENT.CALENDAR />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.appointment.subMenu.employeeList.path,
	// 	element: <APP.APPOINTMENT.EMPLOYEE_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.appointment.subMenu.employeeID.path}/:id`,
	// 	element: <APP.APPOINTMENT.EMPLOYEE_VIEW />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.appointment.subMenu.appointmentList.path,
	// 	element: <APP.APPOINTMENT.APPOINTMENT_LIST />,
	// 	exact: true,
	// },

	// /**
	//  * App > CRM
	//  */
	// {
	// 	path: demoPages.crm.subMenu.dashboard.path,
	// 	element: <APP.CRM.CRM_DASHBOARD />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.crm.subMenu.customersList.path,
	// 	element: <APP.CRM.CUSTOMERS />,
	// 	exact: true,
	// },
	// {
	// 	path: `${demoPages.crm.subMenu.customerID.path}/:id`,
	// 	element: <APP.CRM.CUSTOMER />,
	// 	exact: true,
	// },

	// /**
	//  * App > Chat
	//  */
	// {
	// 	path: demoPages.chat.subMenu.withListChat.path,
	// 	element: <APP.CHAT.WITH_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: demoPages.chat.subMenu.onlyListChat.path,
	// 	element: <APP.CHAT.ONLY_LIST />,
	// 	exact: true,
	// },

	// /**
	//  * END - App
	//  */

	// /** ************************************************** */

	// /**
	//  * Page Layout Types
	//  */
	// {
	// 	path: layoutMenu.blank.path,
	// 	element: <PAGE_LAYOUTS.BLANK />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.headerAndSubheader.path,
	// 	element: <PAGE_LAYOUTS.HEADER_SUBHEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlyHeader.path,
	// 	element: <PAGE_LAYOUTS.HEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlySubheader.path,
	// 	element: <PAGE_LAYOUTS.SUBHEADER />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.pageLayout.subMenu.onlyContent.path,
	// 	element: <PAGE_LAYOUTS.CONTENT />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.asideTypes.subMenu.defaultAside.path,
	// 	element: <PAGE_LAYOUTS.ASIDE />,
	// 	exact: true,
	// },
	// {
	// 	path: layoutMenu.asideTypes.subMenu.minimizeAside.path,
	// 	element: <PAGE_LAYOUTS.MINIMIZE_ASIDE />,
	// 	exact: true,
	// },
];
const documentation = [
	/**
	 * Bootstrap
	 */
	/**
	 * Content
	 */
	// {
	// 	path: componentsMenu.content.path,
	// 	element: <CONTENT.CONTENTS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.typography.path,
	// 	element: <CONTENT.TYPOGRAPHY />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.images.path,
	// 	element: <CONTENT.IMAGES />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.tables.path,
	// 	element: <CONTENT.TABLES />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.figures.path,
	// 	element: <CONTENT.FIGURES />,
	// 	exact: true,
	// },
	// /**
	//  * Forms
	//  */
	// {
	// 	path: componentsMenu.forms.path,
	// 	element: <FORMS_PAGE.FORMS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.formGroup.path,
	// 	element: <FORMS_PAGE.FORM_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.formControl.path,
	// 	element: <FORMS_PAGE.FORM_CONTROLS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.select.path,
	// 	element: <FORMS_PAGE.SELECT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.checksAndRadio.path,
	// 	element: <FORMS_PAGE.CHECKS_AND_RADIO />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.range.path,
	// 	element: <FORMS_PAGE.RANGE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.inputGroup.path,
	// 	element: <FORMS_PAGE.INPUT_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.validation.path,
	// 	element: <FORMS_PAGE.VALIDATION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.wizard.path,
	// 	element: <FORMS_PAGE.WIZARD />,
	// 	exact: true,
	// },
	// /**
	//  * Components
	//  */
	// {
	// 	path: componentsMenu.components.path,
	// 	element: <COMPONENTS_PAGE.COMPONENTS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.tooltip.path,
	// 	element: <COMPONENTS_PAGE.TOOLTIP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.toasts.path,
	// 	element: <COMPONENTS_PAGE.TOASTS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.scrollspy.path,
	// 	element: <COMPONENTS_PAGE.SCROLLSPY />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.carousel.path,
	// 	element: <COMPONENTS_PAGE.CAROUSEL />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.spinners.path,
	// 	element: <COMPONENTS_PAGE.SPINNER />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.listGroup.path,
	// 	element: <COMPONENTS_PAGE.LIST_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.breadcrumb.path,
	// 	element: <COMPONENTS_PAGE.BREADCRUMB />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.collapse.path,
	// 	element: <COMPONENTS_PAGE.COLLAPSE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.pagination.path,
	// 	element: <COMPONENTS_PAGE.PAGINATION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.progress.path,
	// 	element: <COMPONENTS_PAGE.PROGRESS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.card.path,
	// 	element: <COMPONENTS_PAGE.CARD />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.button.path,
	// 	element: <COMPONENTS_PAGE.BUTTON />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.buttonGroup.path,
	// 	element: <COMPONENTS_PAGE.BUTTON_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.alert.path,
	// 	element: <COMPONENTS_PAGE.ALERT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.badge.path,
	// 	element: <COMPONENTS_PAGE.BADGE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.popovers.path,
	// 	element: <COMPONENTS_PAGE.POPOVERS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.dropdowns.path,
	// 	element: <COMPONENTS_PAGE.DROPDOWN />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.accordion.path,
	// 	element: <COMPONENTS_PAGE.ACCORDION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.modal.path,
	// 	element: <COMPONENTS_PAGE.MODAL />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.navsTabs.path,
	// 	element: <COMPONENTS_PAGE.NAVS_TABS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.offcanvas.path,
	// 	element: <COMPONENTS_PAGE.OFF_CANVAS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.table.path,
	// 	element: <COMPONENTS_PAGE.TABLE />,
	// 	exact: true,
	// },
	// /**
	//  * Utilities
	//  */
	// {
	// 	path: componentsMenu.utilities.path,
	// 	element: <UTILITIES.UTILITIES />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.api.path,
	// 	element: <UTILITIES.API />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.background.path,
	// 	element: <UTILITIES.BACKGROUND />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.borders.path,
	// 	element: <UTILITIES.BORDERS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.colors.path,
	// 	element: <UTILITIES.COLORS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.display.path,
	// 	element: <UTILITIES.DISPLAY />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.flex.path,
	// 	element: <UTILITIES.FLEX />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.float.path,
	// 	element: <UTILITIES.FLOAT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.interactions.path,
	// 	element: <UTILITIES.INTERACTIONS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.overflow.path,
	// 	element: <UTILITIES.OVERFLOW />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.position.path,
	// 	element: <UTILITIES.POSITION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.shadows.path,
	// 	element: <UTILITIES.SHADOWS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.sizing.path,
	// 	element: <UTILITIES.SIZING />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.spacing.path,
	// 	element: <UTILITIES.SPACING />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.text.path,
	// 	element: <UTILITIES.TEXT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.verticalAlign.path,
	// 	element: <UTILITIES.VERTICAL_ALIGN />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.visibility.path,
	// 	element: <UTILITIES.VISIBILITY />,
	// 	exact: true,
	// },
	// /**
	//  * Extra
	//  */
	// /**
	//  * Icons
	//  */
	// {
	// 	path: componentsMenu.icons.path,
	// 	element: <ICONS.ICONS_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.icons.subMenu.icon.path,
	// 	element: <ICONS.ICON />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.icons.subMenu.material.path,
	// 	element: <ICONS.MATERIAL />,
	// 	exact: true,
	// },
	// /**
	//  * Charts
	//  */
	// {
	// 	path: componentsMenu.charts.path,
	// 	element: <CHARTS_PAGE.CHART_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsUsage.path,
	// 	element: <CHARTS_PAGE.GENERAL_USAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsSparkline.path,
	// 	element: <CHARTS_PAGE.SPARKLINE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsLine.path,
	// 	element: <CHARTS_PAGE.LINE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsArea.path,
	// 	element: <CHARTS_PAGE.AREA />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsColumn.path,
	// 	element: <CHARTS_PAGE.COLUMN />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsBar.path,
	// 	element: <CHARTS_PAGE.BAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsMixed.path,
	// 	element: <CHARTS_PAGE.MIXED />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsTimeline.path,
	// 	element: <CHARTS_PAGE.TIMELINE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsCandleStick.path,
	// 	element: <CHARTS_PAGE.CANDLESTICK />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsBoxWhisker.path,
	// 	element: <CHARTS_PAGE.BOX_WHISKER />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsPieDonut.path,
	// 	element: <CHARTS_PAGE.PIE_DONUT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsRadar.path,
	// 	element: <CHARTS_PAGE.RADAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsPolar.path,
	// 	element: <CHARTS_PAGE.POLAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsRadialBar.path,
	// 	element: <CHARTS_PAGE.RADIAL_BAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsBubble.path,
	// 	element: <CHARTS_PAGE.BUBBLE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsScatter.path,
	// 	element: <CHARTS_PAGE.SCATTER />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsHeatMap.path,
	// 	element: <CHARTS_PAGE.HEAT_MAP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsTreeMap.path,
	// 	element: <CHARTS_PAGE.TREE_MAP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.notification.path,
	// 	element: <EXTRA.NOTIFICATION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.hooks.path,
	// 	element: <EXTRA.HOOKS />,
	// 	exact: true,
	// },
];
const contents = [...presentation];

export default contents;
