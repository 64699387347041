/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
// import _ from 'lodash';
import { sendPost, sendGet } from './axiosClients';

export const login = (data) => sendPost('/auth/login', data);
export const register = (data) => sendPost('/auth/register',data);
export const logout = (data) => sendPost('/auth/logout', data);
export const deleteAccount = (data) => sendPost('/auth/delete-account', data);
export const getMe = (data) => sendGet('/user/me',data);
export const fbLogin = (data) => sendPost('/fb-accounts-login',data);
export const getPermisson = (data) => sendGet('/fb-permissions', data);
export const refreshTokenUser = (data) => sendPost('/refresh-token/user', data);
export const refreshTokenPage = (data) => sendPost('/refresh-token/page', data);
export const newRefreshTokenPage = (data) => sendPost('/refresh-page-token', data)
export const igLogin = (data) => sendPost('/instagram-accounts-login',data);
export const analystSystem = (data) => sendGet('/facebook/statics', data)
// export const oauthZalo = (data) => sendGet('https://oauth.zaloapp.com/v4/permission', data);
export const genZaloCode = (data) => sendGet('/gen-code-verifier', data);
export const loginZalo = (data) => sendPost('/zalo/login',data);
export const deleteZalo = (data) => sendPost('/zalo/account/delete', data)
export const loginTiktok = (data) => sendPost('/tiktok/login', data);
export const deleteTiktok = (data) => sendPost('/tiktok/delete', data);
export const deleteThreadsAccount = (data) => sendPost('/threads-accounts/delete', data)
