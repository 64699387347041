/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const youtubeplayerSlice = createSlice({
    name: 'youtubeplayer',
    initialState: {
        videoPlayer: {
            video: "",
            visible: false
        }

    },
    reducers: {
        startVideoPlayers: (state, action) => {
            return {
                ...state,
                videoPlayer: {
                    ...state.videoPlayer,
                    video: action.payload,
                    visible: true
                }
            };
        },
        stopVideoPlayers: (state) => {
            return {
                ...state,
                videoPlayer: { ...state.videoPlayer, video: "", visible: false }
            };
        },
    }
})
export const {
    startVideoPlayers,
    stopVideoPlayers,
} = youtubeplayerSlice.actions;
export default youtubeplayerSlice.reducer;