import React, { Suspense } from 'react';
import Card, { CardBody } from '../../components/bootstrap/Card';
import PageWrapper from '../PageWrapper/PageWrapper';
import Page from '../Page/Page';
import SubHeader from '../SubHeader/SubHeader';
import ContentRoutes from './ContentRoutes';
import NotificationsContextProvider from '../../contexts/NotificationsContext';

const LOADING = (
	<PageWrapper>
		<SubHeader>
			<div />
		</SubHeader>
		<Page>
			<div></div>
		</Page>
	</PageWrapper>
);

const Content = () => {
	return (
		<main className='content'>
			<Suspense
			// fallback={LOADING}
			>
				<ContentRoutes />
			</Suspense>
		</main>
	);
};

export default Content;
