import React from 'react';
import './layer.css';
import Icon from '../icon/Icon';
import { useNavigate } from 'react-router-dom';
const Layer = () => {
    const navigate = useNavigate();
	return (
		<>
			<div className='layer__container'></div>
			<div className='layer__modal__container'>
				<div onClick={() => {
                    navigate('/payment')
                }} className='layer__modal'>
					<Icon icon='Lock' size='3x' />
                    <div className='ms-2 text-info fs-4 fw-bold'>
                        Bạn cần gia hạn để sử dụng tính năng này.
                    </div>
				</div>
			</div>
		</>
	);
};

export default Layer;
