import * as React from 'react';
import { SVGProps } from 'react';

const SvgCustomZalo = (props: SVGProps<SVGSVGElement>) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		viewBox='0 0 50 50'
		width='1em'
		height='1em'
		className='svg-icon'
		fill='currentColor'
		{...props}>
		<path d='M9 4C6.25 4 4 6.25 4 9v32c0 2.75 2.25 5 5 5h32c2.75 0 5-2.25 5-5V9c0-2.75-2.25-5-5-5H9zm0 2h6.58C12.01 9.716 10 14.518 10 19.5c0 5.16 2.11 10.1 5.91 13.84.12.21.22 1.24-.24 2.43-.29.75-.87 1.73-1.99 2.1a.997.997 0 0 0-.68 1.01c.03.45.36.83.8.92 2.87.57 4.73-.29 6.23-.97 1.35-.62 2.24-1.04 3.61-.48 2.8 1.09 5.78 1.65 8.86 1.65 4.094 0 8.031-1 11.5-2.887V41c0 1.668-1.332 3-3 3H9c-1.668 0-3-1.332-3-3V9c0-1.668 1.332-3 3-3zm24 9c.55 0 1 .45 1 1v9c0 .55-.45 1-1 1s-1-.45-1-1v-9c0-.55.45-1 1-1zm-15 1h5c.36 0 .7.2.88.52.17.31.16.7-.03 1.01L19.8 24H23c.55 0 1 .45 1 1s-.45 1-1 1h-5c-.36 0-.7-.2-.88-.52-.17-.31-.16-.7.03-1.01L21.2 18H18c-.55 0-1-.45-1-1s.45-1 1-1zm9.5 3c.61 0 1.18.17 1.69.45.18-.26.46-.45.81-.45.55 0 1 .45 1 1v5c0 .55-.45 1-1 1-.35 0-.63-.19-.81-.45-.51.28-1.08.45-1.69.45-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5zm11 0c1.93 0 3.5 1.57 3.5 3.5S40.43 26 38.5 26 35 24.43 35 22.5s1.57-3.5 3.5-3.5zm-11 2a1.513 1.513 0 0 0-1.244.66 1.492 1.492 0 0 0-.256.84 1.513 1.513 0 0 0 .66 1.244 1.492 1.492 0 0 0 .84.256 1.497 1.497 0 0 0 1.5-1.5c0-.83-.67-1.5-1.5-1.5zm11 0a1.513 1.513 0 0 0-1.06.44A1.501 1.501 0 0 0 37 22.5a1.513 1.513 0 0 0 .66 1.244 1.506 1.506 0 0 0 .84.256 1.497 1.497 0 0 0 1.5-1.5c0-.83-.67-1.5-1.5-1.5z' />
	</svg>
);

export default SvgCustomZalo;
