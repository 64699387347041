import React, { useEffect, useRef } from 'react';
import '../AboutUs/aboutus.css';
import { useMedia } from 'react-use';
import amaiteamImg from '../../../assets/AboutUs/AMAI MMO 1.jpg';
import img1 from '../../../assets/AboutUs/Nhiem vu 1.svg';
import img2 from '../../../assets/AboutUs/Tam nhin 1.svg';
import img3 from '../../../assets/AboutUs/Gia tri cot loi 1.svg';
import { FaLongArrowAltRight } from 'react-icons/fa';
import classNames from 'classnames';
import cardHeaderImg from '../../../assets/AboutUs/webwindow.svg';
import ContactForm from '../AboutUs/ContactForm';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Service = () => {
	const isWide = useMedia('(min-width: 769px)');

	const ref = useRef(null);

	const handleClick = () => {
		ref.current?.scrollIntoView({ behavior: 'smooth' });
	};

	useEffect(() => {
		AOS.init();
	}, []);

	return (
		<div
			className={classNames({
				about__us__container: isWide,
				about__us__container__moblie: !isWide,
			})}>
			<div className='about__us__section1'>
				<div className='d-flex flex-column align-items-stretch mb-5'>
					<h1 className='about__us__section1__header'>Our Service</h1>
					<div className='d-flex align-items-center'>
						<div className='about__us__section1__sub__header'>
							Dịch vụ của chúng tôi
						</div>
						<div className='about__us__section1__header__line'></div>
					</div>
				</div>
				<div className='row g-5 align-items-stretch about__us__section1__description'>
					<div className='col-12 col-lg-6 '>
						<div className='mb-3' style={{ fontSize: '32px' }}>
							Về chúng tôi, AMAI Agency!
						</div>
						<div className='mb-3 text-justify'>
							Amai Agency là một công ty chuyên cung cấp các giải pháp marketing toàn
							diện cho doanh nghiệp. Với đội ngũ chuyên gia giàu kinh nghiệm và nhiệt
							huyết, Amai Agency cam kết mang đến cho doanh nghiệp của bạn những giải
							pháp marketing hiệu quả nhất, giúp doanh nghiệp của bạn tỏa sáng và đạt
							được những mục tiêu kinh doanh của mình.
						</div>
						<div className='mb-3 text-justify'>
							Với Amai Agency, không có giới hạn cho sự sáng tạo. Chúng tôi không
							ngừng nghiên cứu, phát triển ra những phần mềm và giải pháp marketing
							“mới mẻ - sáng tạo - hữu ích" để phục vụ các chiến lược marketing cho
							doanh nghiệp. Bằng sự “đột phá” qua các dự từ lớn đến nhỏ, Amai Agency
							đã và đang hợp tác với nhiều doanh nghiệp lớn nhỏ trên cả nước, giúp các
							doanh nghiệp này đạt được những thành công đáng kể trong kinh doanh.
						</div>
						<div className='mb-3 text-justify'>
							Hãy để Amai Agency trở thành người bạn đồng hành đáng tin cậy trên con
							đường thành công của bạn. Chúng tôi cam kết mang đến những giải pháp
							sáng tạo, hiệu quả và độc đáo, giúp thương hiệu của bạn vươn tới tầm cao
							mới, vượt qua mọi mong đợi.
						</div>
						<div className=' mt-auto d-flex align-items-center'>
							<button className='about__us__section1__button' onClick={handleClick}>
								Liên hệ với chúng tôi <FaLongArrowAltRight className='ms-2' />
							</button>
						</div>
					</div>
					<div className='col-12 col-lg-6'>
						<img src={amaiteamImg} style={{ borderRadius: '5px' }} />
					</div>
				</div>
			</div>
			<div className='about__us__section6'>
				<div className='row g-5'>
					<div className='col-12 col-lg-6 ' data-aos='flip-left'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-center'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>SEO Service</h3>
									<h1 className='fw-bold m-0'>Dịch vụ SEO</h1>
								</div>
							</div>
							<div className='mt-4 text-justify'>
								AMAI Content cung cấp dịch vụ SEO nhắm tối ưu hóa sự xuất hiện trực
								tuyến cho doanh nghiệp. Chúng tôi áp dụng chiến lược SEO Mũ Trắng,
								tuân thủ nguyên tắc đạo đức và chất lượng. Dịch vụ của chúng tôi bao
								gồm nghiên cứu từ khóa, tối ưu hóa nội dung, xây dựng liên kết chất
								lượng và cải thiện trải nghiệm người dùng.
							</div>
							<div className='text-justify'>
								Chúng tôi cam kết mang lại tăng trưởng lưu lượng truy cập chất
								lượng, tỷ lệ chuyển đổi cao và đưa doanh nghiệp lên top Google một
								cách bền vững. Với kinh nghiệm và sự tận tâm, AMAI Content là đối
								tác tin cậy để gia tăng sự xuất hiện trực tuyến và đạt được thành
								công kinh doanh.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-right'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Website development</h3>
									<h1 className='fw-bold m-0'>Phát triển Website</h1>
								</div>
							</div>
							<div className='mt-4 text-justify'>
								Dịch vụ của chúng tôi mang đến giải pháp toàn diện cho việc xây dựng
								website chuyên nghiệp và hiệu quả. Bắt đầu từ việc phân tích nhu
								cầu, chúng tôi tạo ra giao diện đẹp mắt và tương thích trên mọi
								thiết bị. Qua quá trình phát triển, chúng tôi sử dụng mã nguồn tối
								ưu và bảo mật cao.
							</div>
							<div className='text-justify'>
								Chúng tôi áp dụng công nghệ tiên tiến và tuân thủ tiêu chuẩn quốc tế
								để tạo ra những trang web độc đáo và tương tác. Đội ngũ thiết kế và
								phát triển chuyên nghiệp cam kết đáp ứng đầy đủ yêu cầu và mục tiêu
								kinh doanh của khách hàng. Chúng tôi đồng hành cùng khách hàng từ
								triển khai đến hỗ trợ sau dịch vụ.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-left'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Content Wirte</h3>
									<h1 className='fw-bold m-0'>Viết nội dung</h1>
								</div>
							</div>
							 
							<div className='mt-4 text-justify'>
								Amai Content tự hào là đơn vị chuyên cung cấp các dịch vụ viết bài
								SEO và sáng tạo nội dung chất lượng. Chúng tôi đặt sự tập trung cao
								vào việc tạo ra nội dung chất lượng, phù hợp với hầu hết các ngành
								và mục tiêu của khách hàng. Đội ngũ biên tập viên của chúng tôi sở
								hữu kiến thức sâu rộng trong việc viết nội dung SEO hấp dẫn và
								chuyên sâu.
							</div>
							<div className='text-justify'>
								Ngoài ra, chúng tôi không chỉ cung cấp những bài viết chứa chất xám
								sáng tạo mà còn kết hợp tư duy chiến lược và kiến thức chuyên sâu.
								Mỗi câu chữ được xây dựng một cách tỉ mỉ, đảm bảo đáp ứng đầy đủ các
								yếu tố SEO quan trọng.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-right'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Website development</h3>
									<h1 className='fw-bold m-0'>Marketing thuê ngoài</h1>
								</div>
							</div>

							<div className='mt-4 text-justify'>
								AMAI Content hiện đã và đang là đơn vụ cung cấp dịch vụ marketing
								thuê ngoài uy tín hiện nay, đây giải pháp hiệu quả chi phí so với
								việc duy trì một đội ngũ marketing in-house. Đội ngũ chuyên viên của
								chúng tôi đều có chuyên môn cao và nhiều năm kinh nghiệm trong lĩnh
								vực marketing thuê ngoài. Ngoài ra, chúng tôi cam kết hỗ trợ khách
								hàng một cách linh hoạt 24/7, nhanh chóng phát hiện và xử lý các vấn
								đề phát sinh. Chúng tôi đặt mục tiêu hoàn thành dự án đúng tiến độ,
								giúp doanh nghiệp duy trì hiệu suất cao trong chiến dịch Marketing.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-left'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Booking News</h3>
									<h1 className='fw-bold m-0'>Booking báo và Guest post</h1>
								</div>
							</div>

							<div className='mt-4 text-justify'>
								Chúng tôi cung cấp dịch vụ booking báo và guest post nhằm tăng cường
								sự nhận thức về thương hiệu của khách hàng thông qua xuất hiện trên
								các phương tiện truyền thông. Chúng tôi hỗ trợ thương hiệu và doanh
								nghiệp tiếp cận đúng đối tượng khách hàng mục tiêu, tối ưu hóa hiệu
								quả của chiến dịch PR.
							</div>
							<div className='text-justify'>
								  Đồng thời, Amai Content giúp thương hiệu và doanh nghiệp tạo ra ấn
								tượng và sự khác biệt trong thị trường cạnh tranh. Bằng cách này,
								khách hàng có thể đạt được doanh thu ngay từ tệp khách hàng chất
								lượng do xuất hiện trên các tờ báo uy tín. Độc giả tin tưởng và mua
								sắm ngay sau khi đọc các bài báo chất lượng.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-right'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Google Ads</h3>
									<h1 className='fw-bold m-0'>Quảng cáo Google</h1>
								</div>
							</div>

							<div className='mt-4 text-justify'>
								Dịch vụ quảng cáo Google của chúng tôi mang lại hiệu suất tối ưu khi
								chỉ hiển thị quảng cáo khi người dùng tìm kiếm sản phẩm/dịch vụ.
								Điều này không chỉ tăng tỉ lệ chuyển đổi mà còn giúp tiết kiệm chi
								phí quảng cáo. Với Google Ads của AMAI, doanh nghiệp chỉ phải trả
								phí trên kết quả thu được.
							</div>
							<div className='text-justify'>
								Dịch vụ của chúng tôi còn đo lường chính xác lượt hiển thị, số click
								và đưa ra quyết định cải thiện cho các chiến dịch Pay Per Click -
								PPC. Điều này giúp tăng lượng truy cập website, cải thiện chỉ số AS,
								thu hút traffic chất lượng, xây dựng uy tín website, và hỗ trợ SEO
								hiệu quả.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-left'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Facebook Ads</h3>
									<h1 className='fw-bold m-0'>Quảng cáo Facebook</h1>
								</div>
							</div>
							 
							<div className='mt-4 text-justify'>
								AMAI cung cấp dịch vụ quảng cáo Facebook chuyên nghiệp, nhằm tối đa
								hóa hiệu suất quảng cáo trên nền tảng mạng xã hội lớn nhất thế giới.
								Chúng tôi xây dựng các chiến dịch quảng cáo Facebook độc đáo và hiệu
								quả. Thấu hiểu rõ ràng rằng mỗi doanh nghiệp có nhu cầu khác nhau,
								chúng tôi tạo ra chiến lược quảng cáo tùy chỉnh theo ngành và đối
								tượng khách hàng cụ thể.
							</div>
							<div className='text-justify'>
								Đội ngũ chuyên gia của AMAI sẽ nghiên cứu thị trường, phân tích đối
								thủ cạnh tranh và tạo ra nội dung quảng cáo hấp dẫn. Chúng tôi liên
								tục theo dõi và tối ưu hóa chiến dịch để đảm bảo kết quả tốt nhất.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-right'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Take Care Website</h3>
									<h1 className='fw-bold m-0'>Chăm sóc Website</h1>
								</div>
							</div>

							<div className='mt-4 text-justify'>
								Dịch vụ quản lý và chăm sóc website của AMAI Content mang lại sự
								chuyên nghiệp và toàn diện cho doanh nghiệp của bạn. Chúng tôi tập
								trung vào tối ưu hóa hiệu suất, bảo mật và bảo vệ dữ liệu, cải thiện
								SEO, thiết kế giao diện độc đáo và cung cấp hỗ trợ kỹ thuật chuyên
								nghiệp.
							</div>
							<div className='text-justify'>
								  Với chúng tôi, website của bạn sẽ được nâng cấp về tốc độ tải
								trang, đảm bảo an ninh thông tin, đạt được thứ hạng cao trên công cụ
								tìm kiếm, tạo trải nghiệm độc đáo và nhận được sự hỗ trợ kỹ thuật
								tận tâm.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-left'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Take Care Fanpage</h3>
									<h1 className='fw-bold m-0'>Chăm sóc Fanpage</h1>
								</div>
							</div>

							<div className='mt-4 text-justify'>
								Dịch vụ chăm sóc Fanpage của AMAI là giải pháp toàn diện giúp doanh
								nghiệp tối ưu hóa tiềm năng trên mạng xã hội. Chúng tôi chuyên tạo
								và quản lý nội dung sáng tạo, thu hút sự chú ý và tương tác từ đối
								tượng khách hàng mục tiêu. Đội ngũ chuyên gia nắm vững xu hướng và
								chiến lược tiếp thị mạng xã hội.
							</div>
							<div className='text-justify'>
								Chăm sóc Fanpage của chúng tôi không chỉ tăng cường tương tác mà còn
								xây dựng uy tín thương hiệu và tăng cường sự hiện diện trực tuyến.
								Chúng tôi cam kết cung cấp các bài viết hấp dẫn, hình ảnh và video
								chất lượng để kích thích sự quan tâm của cộng đồng mạng và tối ưu
								hóa chiến lược quảng cáo trên Fanpage. 
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-right'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Take Care Entity</h3>
									<h1 className='fw-bold m-0'>Tạo và chăm sóc Entity</h1>
								</div>
							</div>
							<div className='mt-4 text-justify'>
								AMAI là đơn vị hàng đầu trong lĩnh vực cung cấp dịch vụ tạo & chăm
								sóc Entity. Chúng tôi chuyên tạo và quản lý Social Profile cho doanh
								nghiệp trên nhiều nền tảng, nhằm tăng cường hiện diện trực tuyến,
								xây dựng danh tiếng và tạo lòng tin từ khách hàng. Hàng ngày, chúng
								tôi đều chăm sóc Entity của bạn thông qua việc đăng bài, tương tác
								với khách hàng, giúp xây dựng cộng đồng trực tuyến tích cực và tăng
								cường tương tác khách hàng.
							</div>
							<div className='text-justify'>
								Tận dụng SEO, chúng tôi đưa doanh nghiệp lên TOP tìm kiếm, tăng
								lượng traffic và khả năng tiếp cận khách hàng tiềm năng thông qua
								việc tối ưu hóa nội dung và cấu trúc website.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-left'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Landing Page Design</h3>
									<h1 className='fw-bold m-0'>Thiết kế Landing Page</h1>
								</div>
							</div>

							<div className='mt-4 text-justify'>
								Amai Content cung cấp dịch vụ thiết kế Landing Page được xây dựng
								với mục tiêu nâng cao tỷ lệ chuyển đổi từ các chiến dịch quảng cáo,
								tiếp thị qua email và các chiến lược khác, tập trung vào việc thiết
								kế trang để tối ưu hóa chuyển đổi. Với 7 năm kinh nghiệm, AMAI
								Content là đơn vị hàng đầu trong lĩnh vực cung cấp dịch vụ thiết kế
								Landing Page, với uy tín, chuyên nghiệp nhất trên thị trường.
							</div>
							<div className='text-justify'>
								Ngoài ra, chúng tôi không chỉ mang đến cho bạn mẫu Landing Page đẹp
								và chuẩn, mà còn hỗ trợ cải thiện hiệu suất quảng cáo, từ đó gia
								tăng doanh thu cho doanh nghiệp của bạn.
							</div>
						</div>
					</div>
					<div className='col-12 col-lg-6 ' data-aos='flip-right'>
						<div className='about__us__section6__card'>
							<div className='d-flex align-items-stretch'>
								<img src={cardHeaderImg} alt='' />
								<div className='ms-3'>
									<h3 className='m-0'>Marketing Software</h3>
									<h1 className='fw-bold m-0'>Phần mềm Marketing</h1>
								</div>
							</div>
							 
							<div className='mt-4 text-justify'>
								AMAI, đơn vị cung cấp những phần mềm marketing chất lượng và tiên
								tiến như:  AMAI SEO, AMAI MMO,  AMAI Content, AMAI Social mang đến
								cho doanh nghiệp những công cụ mạnh mẽ để tối ưu hóa chiến lược
								marketing và tăng cường khả năng tương tác với khách hàng. Với khả
								năng phân tích dữ liệu chuyên sâu của các phần mềm, giúp khách hàng
								hiểu rõ hành vi, nhu cầu và mục đích của người dùng. 
							</div>
							<div className='text-justify'>
								Ngoài ra, các phần mềm của chúng tôi sẽ được tích hợp thêm trí tuệ
								nhân tạo, nên tảng này sẽ giúp khách hàng tự động tối ưu chiến lược,
								tiết kiệm thời gian và nguồn lực.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='about__us__section3'>
				<div className='d-flex flex-column align-items-center'>
					<div style={{ fontSize: '24px', fontWeight: '700' }}>CORPORATE PHILOSOPHY</div>
					<div style={{ fontSize: '24px', fontWeight: '700' }}>
						AMAI Content - Giới thiệu
					</div>
					<div className='row align-items-center g-5 mt-3' data-aos='fade-right'>
						<div className='col-12 col-lg-6'>
							<img src={img1} alt='' />
						</div>
						<div className='col-12 col-lg-6 about__us__section3__description'>
							<div className='about__us__section3__description__header'>SỨ MỆNH</div>
							<div className='text-justify'>
								Amai Agency là một công ty tư vấn và tiếp thị chuyên nghiệp, được
								thành lập với sứ mệnh “Hỗ trợ doanh nghiệp kiến tạo, chinh phục
								những mục tiêu lớn - BIG GOAL”
							</div>
							<div className='text-justify'>
								Chúng tôi tin rằng, mỗi doanh nghiệp đều có những mục tiêu riêng, và
								chúng tôi mong muốn được đồng hành cùng doanh nghiệp trong hành
								trình kiến tạo và chinh phục những mục tiêu đó. Bằng sự chuyên
								nghiệp, tận tâm và sáng tạo, chúng tôi sẽ giúp doanh nghiệp của bạn:
							</div>
							<ul className='ms-2'>
								<li>Xác định rõ mục tiêu và tầm nhìn</li>
								<li>Xây dựng chiến lược và kế hoạch hành động hiệu quả</li>
								<li>Triển khai và thực hiện chiến lược một cách hiệu quả</li>
								<li>Đo lường và đánh giá hiệu quả chiến lược</li>
							</ul>
						</div>
					</div>

					<div className='row align-items-center g-5 mt-3' data-aos='fade-left'>
						<div className='col-12 col-lg-6 about__us__section3__description'>
							<div className='about__us__section3__description__header'>Tầm nhìn</div>
							<div className='text-justify'>
								Với slogan “Đồng hành cùng doanh nghiệp" Amai Agency mong muốn trở
								thành một công ty tư vấn và cung cấp các giải pháp tiếp thị hàng đầu
								tại Việt Nam, được tin tưởng và lựa chọn bởi các doanh nghiệp lớn
								nhỏ trên cả nước vào năm 2030.  Mở rộng và phát triển hoạt động kinh
								doanh tiếp cận được với các khách hàng ngoài nước vào năm 2025.
							</div>
						</div>
						<div className='col-12 col-lg-6'>
							<img src={img2} alt='' />
						</div>
					</div>
					<div className='row align-items-center g-5 mt-3' data-aos='fade-right'>
						<div className='col-12 col-lg-6'>
							<img src={img3} alt='' />
						</div>
						<div className='col-12 col-lg-6 about__us__section3__description'>
							<div className='about__us__section3__description__header'>
								Giá trị cốt lõi
							</div>
							<ul>
								<li>
									<div>Sự sáng tạo luôn được khuyến khích:</div>
									<div className='text-justify'>
										Amai Agency đặt sự sáng tạo là một giá trị cốt lõi. Chúng
										tôi tin rằng để đạt được những ý tưởng độc đáo và hiệu quả,
										cần phải khuyến khích sự sáng tạo. Chúng tôi tạo điều kiện
										cho đội ngũ của mình để tự do thể hiện ý tưởng mới, đồng
										thời khuyến khích họ tìm kiếm các giải pháp sáng tạo trong
										các dự án của chúng tôi.
									</div>
								</li>
								<li>
									<div>Minh bạch, tận tâm được đặt lên hàng đầu:</div>
									<div className='text-justify'>
										Chúng tôi luôn đảm bảo rằng thông tin về dự án, tiến độ và
										kế hoạch được truyền đạt một cách rõ ràng và trung thực cho
										khách hàng.
									</div>
								</li>
								<li>
									<div>Chuyên nghiệp trong tất cả các dự án:</div>
									<div className='text-justify'>
										Chúng tôi sử dụng kiến thức chuyên sâu và kinh nghiệm rộng
										rãi để đảm bảo chất lượng cao và hiệu quả trong mọi dự án.
										Đội ngũ chuyên gia của chúng tôi được đào tạo chuyên nghiệp
										và có kiến thức sâu rộng trong các lĩnh vực liên quan đến
										dịch vụ mà chúng tôi cung cấp.
									</div>
								</li>
								<li>
									<div>Tận tâm lắng nghe - thấu hiểu khách hàng:</div>
									<div className='text-justify'>
										Amai Agency tìm hiểu về mục tiêu, nhu cầu và mong muốn của
										khách hàng để đảm bảo rằng chúng tôi cung cấp những giải
										pháp tốt nhất và phù hợp nhất. Chúng tôi xem mỗi dự án là
										một cơ hội để xây dựng mối quan hệ đáng tin cậy và lâu dài
										với khách hàng.
									</div>
								</li>
								<li>
									<div>Không ngại thử nghiệm:</div>
									<div className='text-justify'>
										Chúng tôi tin rằng việc tìm kiếm những giải pháp mới và khác
										biệt là cách để phát triển và đạt được thành công. 
									</div>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='about__us__section4' data-aos='zoom-in'>
				<div className='row g-3'>
					<div style={{ fontSize: '24px', fontWeight: '400' }} className='col-12'>
						COMPANY INFO
					</div>
					<div style={{ fontSize: '48px', fontWeight: '500' }} className='col-12'>
						Thông tin công ty
					</div>
					<div className='col-12 about__us__section4__title'>Trụ sở chính</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Tên công ty</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Công ty cổ phần dịch vụ & truyền thông Amai
						</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Ngày thành lập</div>
						<div className='col-12 col-lg-7 '>24 tháng 03 năm 2020</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Người đại diện</div>
						<div className='col-12 col-lg-7 text-uppercase'>Hà Trọng Hưng</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Vốn điều lệ</div>
						<div className='col-12 col-lg-7 text-uppercase'>5 tỷ VND</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Văn phòng đại diện</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Toà nhà CT3-2 KĐT Mễ Trì Hạ, Nam Từ Liêm, Hà Nội
						</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Nội dung kinh doanh</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Phát triển phần mềm, dịch vụ marketing
						</div>
					</div>
					<div className='col-12 about__us__section4__title mt-5'>Chi nhánh Nhật Bản</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Tên công ty</div>
						<div className='col-12 col-lg-7 text-uppercase'>合同会社ベキジャパン</div>
					</div>

					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Người đại diện</div>
						<div className='col-12 col-lg-7 text-uppercase'>グエン・クォック・フン</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Vốn điều lệ</div>
						<div className='col-12 col-lg-7 text-uppercase'>金500万円</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Văn phòng chính</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							〒115-0051東京都北区浮間3-1-3-305
						</div>
					</div>
					<div className='row border-bottom mt-3 pb-2'>
						<div className='col-12 col-lg-5'>Nội dung kinh doanh</div>
						<div className='col-12 col-lg-7 text-uppercase'>
							Phát triển phần mềm, dịch vụ marketing
						</div>
					</div>
				</div>
			</div>
			<div ref={ref}></div>
			<ContactForm />
		</div>
	);
};

export default Service;
