/* eslint-disable prettier/prettier */
import { sendGet, sendPost } from './axiosClients';

export const createAccount = (data) => sendPost('/fb-accounts/store', data);
export const updateAccount = (data) => sendPost('/fb-accounts/update', data);
export const deleteAccount = (data) => sendPost('/fb-accounts/delete', data);
export const getAccount = (data) => sendGet('/fb-accounts', data);
export const getFanpage = (data) => sendGet('/fb-account-fanpages', data);
export const getGroup = (data) => sendGet('/fb-account-groups', data);
export const getAccountNew = (data) => sendGet('/user/account/list', data);
export const getAccountNotReady = (data) => sendGet('get-account-not-ready', data);
export const acceptAccount = (data) => sendPost('/accept-fb-account', data);
export const refreshAccount = (data) => sendPost('/account/update-disabled', data);
export const addTeleGroup = (data) => sendPost('/telegram-accounts/store', data);
export const deleteTeleGroup = (data) => sendPost('/telegram-accounts/delete', data);
export const fbInsightPage = (id, data) => sendGet(`/fb-insight-page/${id}`, data);
export const fbInsightPost = (id, data) => sendGet(`/fb-insight-page-post/${id}`, data);
export const fbInsightPostPage = (id, data) => sendGet(`/fb-page-post-engage/${id}`, data);
export const fbPostAnalytics = (id, data) => sendGet(`/fb-page-post-analyst/${id}`, data);
export const adminGroup = (data) => sendPost('/add-fanpage-to-group', data);
export const handleCheckGroup = (data) => sendGet(`check-group-install`, data);
