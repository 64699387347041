import React from 'react';
import { FaLongArrowAltRight } from 'react-icons/fa';
import './aboutus.css';

const ContactForm = ({ aosdata }) => {
	return (
		<div className='about__us__section5' data-aos='fade-up'>
			<div>
				<h1 className='mb-5 text-center'>Liên hệ với chúng tôi</h1>
				<input
					type='text'
					className='contact-with-us-form-input'
					placeholder='Tên của bạn'
				/>
				<input
					type='text'
					className='contact-with-us-form-input'
					placeholder='Số điện thoại'
				/>
				<input type='text' className='contact-with-us-form-input' placeholder='Email' />
				<textarea
					className='contact-with-us-form-area'
					placeholder='Dịch vụ'
					rows='5'></textarea>
				<div className='d-flex justify-content-center mt-3 aboutUs__button'>
					<button className='aboutUs__submitButton aboutUs__submitButton-animated'>
						Liên hệ ngay <FaLongArrowAltRight className='ms-2' />
						<span class='blob-btn__inner'>
							<span class='blob-btn__blobs'>
								<span class='blob-btn__blob'></span>
								<span class='blob-btn__blob'></span>
								<span class='blob-btn__blob'></span>
								<span class='blob-btn__blob'></span>
							</span>
						</span>
					</button>
				</div>
			</div>
		</div>
	);
};

export default ContactForm;
