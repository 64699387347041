/* eslint-disable prettier/prettier */
import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
	name: 'user',
	initialState: {
		userProfile: {
			currentUser: null,
			isFetching: false,
			isExpire: false,
			error: false,
			currentProfile: localStorage.getItem('currentProfile')
				? JSON.parse(localStorage.getItem('currentProfile'))
				: {},
			facebookAccount: [],
			tikTokAccount: [],
			youTubeAccount: [],
			instagramAccount: [],
			twitterAccount: [],
			teleAccount: [],
		},
	},
	reducers: {
		getProfileStart: (state) => {
			state.userProfile.isFetching = true;
		},
		getProfileSuccess: (state, action) => {
			state.userProfile.isFetching = false;
			state.userProfile.currentUser = action.payload;
		},
		getFacebookAccount: (state, action) => {
			state.userProfile.facebookAccount = action.payload;
		},
		getTiktokAccount: (state, action) => {
			state.userProfile.tikTokAccount = action.payload;
		},
		getYoutubeAccount: (state, action) => {
			state.userProfile.youTubeAccount = action.payload;
		},
		getInstagramAccount: (state, action) => {
			state.userProfile.instagramAccount = action.payload;
		},
		getTeleAccount: (state, action) => {
			state.userProfile.teleAccount = action.payload;
		},
		getTwitterAccount: (state, action) => {
			state.userProfile.twitterAccount = action.payload;
		},
		getProfileFailed: (state) => {
			state.userProfile.isFetching = false;
			state.userProfile.error = true;
		},
		getCurrentProfile: (state, action) => {
			localStorage.setItem('currentProfile', JSON.stringify(action.payload));
			state.userProfile.currentProfile = action.payload;
		},
		checkExpire: (state, action) => {
			state.userProfile.isExpire = action.payload;
		},
	},
});
export const {
	getProfileStart,
	getProfileSuccess,
	getProfileFailed,
	getFacebookAccount,
	getTiktokAccount,
	getYoutubeAccount,
	getInstagramAccount,
	getTwitterAccount,
	getTeleAccount,
	getCurrentProfile,
	checkExpire,
} = userSlice.actions;
export default userSlice.reducer;
