import React, {useState, useEffect} from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Button from '../../../../components/bootstrap/Button';
import './loginapp.css';
import Icon from '../../../../components/icon/Icon';
import { getPermisson } from '../../../../api/auth';
const InstagramLogin = ({ onSubmit }) => {
	const [scope, setScope] = useState('');
    const fetchPermission = async () => {
		const input = {
			action: 'login',
		};
		const res = await getPermisson(input);
		setScope(res.data.join(','));
	};
    useEffect(() => {
        fetchPermission();
    },[])
	const responseInstagram = async (response) => {
		if (response) {
			onSubmit(response.accessToken);
		}
		// const input = {
		// 	access_token: response.accessToken,
		// };
		// const res = await igLogin(input);
		// if (res.status === 200) {
		// 	dispatch(fbLoginStart(res.data));
		// 	showNotification('Thành công', res.message, 'success');
		// 	setState(false);
		// 	navigate('/facebook/account');
		// } else {
		// 	showNotification('Lỗi', res.message, 'danger');
		// }
	};
	return (
		<FacebookLogin
			// scope='instagram_basic,instagram_content_publish,instagram_manage_comments,instagram_manage_insights,instagram_shopping_tag_product'
            scope={scope}
			appId={process.env.REACT_APP_APP_KEY}
			autoLoad={false}
			// fields='name,email,picture'
			callback={responseInstagram}
			render={(renderProps) => (
				<Button
					className='w-100 py-2 text-uppercase fs-4'
					color='danger'
					onClick={renderProps.onClick}>
					LOGIN WITH INSTAGRAM <Icon className='ms-2' icon='CustomInstagram' />
				</Button>
			)}
		/>
	);
};

export default InstagramLogin;
