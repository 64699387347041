/* eslint-disable prettier/prettier */
import cryptojs from 'crypto-js'
import { v4 as uuidv4 } from 'uuid'

const key = process.env.REACT_APP_ENCRYPT_KEY

const encode = (data) => cryptojs.AES.encrypt(data, key).toString()
const decode = (data) => {
  try {
    return cryptojs.AES.decrypt(data, key).toString(cryptojs.enc.Utf8)
  } catch (error) {
    return null
  }
}
const generateKey = () => {
  return uuidv4('token')
}

export default {
  encode,
  decode,
  generateKey
}
