import React, { useState, useEffect } from 'react';
import './landingheader.css';
import logoImg from '../../../assets/logos/amai-conntent.png';
import Icon from '../../../components/icon/Icon';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
const LandingHeader = () => {
	const [isOpen, setIsOpen] = useState(false);
	const { pathname } = useLocation();
	return (
		<nav id='landing-header'>
			<div className='landing-container'>
				<a href='/'>
					<img src={logoImg} alt='' className='landing-logo' />
				</a>
				<div className={`landing-navbar-collapse ${isOpen ? 'landing__active' : ''}`}>
					<ul id='landing-navbar'>
						<li>
							<Link
								className={classNames('https://amaicontent.com/blog/', {
									active: pathname == '/',
								})}
								to={'/'}>
								Trang chủ
							</Link>
						</li>
						<li>
							<Link
								className={classNames('https://amaicontent.com/blog/', {
									active: pathname == '/ve-chung-toi',
								})}
								to={'/ve-chung-toi'}>
								Giới thiệu
							</Link>
						</li>
						<li>
							<Link
								className={classNames('https://amaicontent.com/blog/', {
									active: pathname == '/dich-vu',
								})}
								to={'/dich-vu'}>
								Dịch vụ
							</Link>
						</li>
						<li>
							<a
								className={classNames('https://amaicontent.com/blog/')}
								href='https://amaicontent.com/blog/'>
								Blog
							</a>
						</li>
						<li>
							<Link
								className={classNames('https://amaicontent.com/blog/', {
									active: pathname == '/bang-gia',
								})}
								to={'/bang-gia'}>
								Báo giá
							</Link>
						</li>
						<li>
							<Link
								className={classNames('https://amaicontent.com/blog/', {
									active: pathname == '/affiliate',
								})}
								to={'/affiliate'}>
								Affiliate
							</Link>
						</li>
						<li>
							<Link className='landing-nav-items gradient-button' to={'/register'}>
								Trải nghiệm miễn phí
							</Link>
						</li>
						<li>
							<Link to={'/login'}>Đăng nhập</Link>
						</li>
					</ul>
				</div>
				<div id='mobile'>
					<Icon
						onClick={() => {
							setIsOpen(!isOpen);
						}}
						icon='Menu'
						size='2x'
						color='light'
					/>
				</div>
			</div>
			{/* <div id='mobile'>
				<i id='bar' className='landing-fas fa-outdent'></i>
			</div> */}
		</nav>
	);
};

export default LandingHeader;
