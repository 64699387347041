import classNames from 'classnames';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fbLogin, getPermisson, igLogin } from '../../../../api/auth';
import { addTeleGroup, createAccount } from '../../../../api/fbAccountApi';
import { TwitterCallback, loginTwitter } from '../../../../api/twitterLoginApi';
import { loginYoutube, youtubeCallback } from '../../../../api/ytLoginApi';
import InstagramIcon from '../../../../assets/logos/InstagramIcon.png';
import TiktokIcon from '../../../../assets/logos/TiktokIcon.png';
import YoutubeIcon from '../../../../assets/logos/YoutubeIcon.png';
import YoutubeImg from '../../../../assets/logos/YoutubeImage.svg';
import fbIcon from '../../../../assets/logos/fbIcon.png';
import telegramIcon from '../../../../assets/logos/telegram.svg';
import TwitterIcon from '../../../../assets/logos/twitter.svg';
import Button from '../../../../components/bootstrap/Button';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../../../../components/bootstrap/Modal';
import Spinner from '../../../../components/bootstrap/Spinner';
import FormGroup from '../../../../components/bootstrap/forms/FormGroup';
import Input from '../../../../components/bootstrap/forms/Input';
import Label from '../../../../components/bootstrap/forms/Label';
import showNotification from '../../../../components/extras/showNotification';
import { useLoading } from '../../../../contexts/loading';
import useDarkMode from '../../../../hooks/useDarkMode';
import Page from '../../../../layout/Page/Page';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import { fbLoginStart, igLoginStart } from '../../../../redux/authSlice';
import AppItem from './AppItem';
import InstagramLogin from './InstagramLogin';
import './loginapp.css';

const LoginAppView = () => {
	const [activeTab, setActiveTab] = useState('facebook');
	const [loadingButton, setLoadingButton] = useState(false);
	const [state, setState] = useState(false);
	const [igState, setIgState] = useState(false);
	const [youtubeState, setYoutubeState] = useState(false);
	const [twitterState, setTwitterState] = useState(false);
	const [telegramState, setTelegramState] = useState(false);
	const [staticBackDropStatus, setStaticBackdropStatus] = useState(true);
	const [headerCloseStatus, setHeaderCloseStatus] = useState(true);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const { loading, show, hide } = useLoading();
	const [isLoading, setIsLoading] = useState(false);
	const [scope, setScope] = useState('');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const fetchPermission = async () => {
		const input = {
			action: 'login',
		};
		const res = await getPermisson(input);
		setScope(res.data.join(','));
	};
	const getData = (data) => {
		setActiveTab(data?.type);
		if (data?.type == 'facebook') {
			fetchPermission();
			setState(true);
		}
		if (data?.type == 'instagram') {
			setIgState(true);
		}
		if (data?.type == 'youtube') {
			setYoutubeState(true);
		}
		if (data?.type == 'twitter') {
			setTwitterState(true);
		}
		if (data?.type == 'telegram') {
			setTelegramState(true);
		}
	};
	const initialStatus = () => {
		setStaticBackdropStatus(false);
		setHeaderCloseStatus(true);
	};
	const formik = useFormik({
		initialValues: {
			emaillink: '',
			telegramGroup: '',
		},
	});
	const handleClick = async () => {
		if (state) {
			setLoadingButton(true);
			const removeBlankEmailLink = formik.values.emaillink.trim();
			const input = { link: removeBlankEmailLink };
			try {
				const respone = await createAccount(input);
				const responeData = respone.data;
				if (respone.status === 200) {
					setLoadingButton(false);
					showNotification('Thành công', 'Thêm tài khoản thành công', 'success');
					initialStatus();
					setState(false);
					formik.values.emaillink = '';
					navigate('/facebook/account');
				} else {
					setLoadingButton(false);
					showNotification('Lỗi', respone.message, 'danger');
				}
			} catch (err) {
				setLoadingButton(false);
			}
		}
		if (telegramState) {
			setLoadingButton(true);
			let removeBlankEmailLink = formik.values.telegramGroup.trim();
			removeBlankEmailLink =
				removeBlankEmailLink.charAt(0) !== '@'
					? '@' + removeBlankEmailLink
					: removeBlankEmailLink;
			const input = { username: removeBlankEmailLink };
			try {
				const respone = await addTeleGroup(input);
				const responeData = respone.data;
				if (respone.status === 200) {
					setLoadingButton(false);
					showNotification('Thành công', 'Thêm tài khoản thành công', 'success');
					initialStatus();
					setTelegramState(false);
					formik.values.telegramGroup = '';
					navigate('/facebook/account');
				} else {
					setLoadingButton(false);
					showNotification('Lỗi', respone.message, 'danger');
				}
			} catch (err) {
				setLoadingButton(false);
			}
		}
	};
	const responseFacebook = async (response) => {
		setLoadingButton(true);
		const input = {
			access_token: response.accessToken,
		};
		const res = await fbLogin(input);
		if (res.status === 200) {
			dispatch(fbLoginStart(res.data));
			showNotification('Thành công', res.message, 'success');
			setState(false);
			navigate('/facebook/account');
		} else {
			showNotification('Lỗi', res.message, 'danger');
		}
	};

	const handleLogin = async () => {
		const res = await loginYoutube();
		if (res.status == 200) {
			window.open(res?.data);
		}
	};
	const handleLoginTwitter = async () => {
		const res = await loginTwitter();
		if (res.status == 200) {
			window.open(res?.data);
		}
	};
	const getGoogleProfile = async (data) => {
		// const input = data;
		const res = await youtubeCallback(data);
		if (res?.status == 200) {
			setLoadingButton(false);
			navigate('/facebook/account');
		} else {
			setLoadingButton(false);

			showNotification('Lỗi', res?.errors, 'danger');
		}
	};
	const getTwitterProfile = async (data) => {
		// const input = data;
		const res = await TwitterCallback(data);
		if (res?.status == 200) {
			setLoadingButton(false);

			navigate('/facebook/account');
		} else {
			setLoadingButton(false);

			showNotification('Lỗi', res?.errors, 'danger');
		}
	};
	useEffect(() => {
		const urlSearchParams = new URLSearchParams(window.location.search);
		const params = Object.fromEntries(urlSearchParams.entries());
		if (
			Object.keys(params).length !== 0 &&
			window.location.pathname.split('/').includes('youtube')
		) {
			// setLoadingButton(true);
			show();
			getGoogleProfile(params);
		}
		if (
			Object.keys(params).length !== 0 &&
			window.location.pathname.split('/').includes('twitter')
		) {
			// setLoadingButton(true);
			show();
			getTwitterProfile(params);
		}
	}, [window.location]);
	useEffect(() => {
		show();
		if (isLoading) {
			hide();
		} else {
			show();
			setIsLoading(true);
		}
	}, [isLoading]);
	const getInstagramLogin = async (data) => {
		setLoadingButton(true);
		const input = {
			access_token: data,
		};
		const res = await igLogin(input);
		if (res.status === 200) {
			setLoadingButton(false);
			dispatch(igLoginStart(res.data));
			showNotification('Thành công', res.message, 'success');
			setIgState(false);
			navigate('/facebook/account');
		} else {
			setLoadingButton(false);
			showNotification('Lỗi', res.message, 'danger');
		}
	};
	return (
		<PageWrapper>
			<Page container='fluid'>
				<div className='d-flex justify-content-end'>
					<Button tag='a' href='/facebook' icon='ArrowBack' color='dark'>
						Quay lại
					</Button>
				</div>
				<div className='display-5 fw-bold py-3'>Thêm Tài Khoản</div>
				<div className='fs-3'>
					Thêm các tài khoản mạng xã hội để có thể dễ dàng quản lý.{' '}
				</div>
				<div className='row mt-3'>
					<div className='col-12 col-md-6 col-lg-3'>
						<AppItem
							onSubmit={getData}
							name='Facebook'
							category='Trang hoặc nhóm'
							img={fbIcon}
							type='facebook'
						/>
					</div>
					<div className='col-12 col-md-6 col-lg-3'>
						<AppItem
							onSubmit={getData}
							name='Tiktok'
							category='Tài khoản kinh doanh'
							img={TiktokIcon}
							type='tiktok'
						/>
					</div>
					<div className='col-12 col-md-6 col-lg-3'>
						<AppItem
							onSubmit={getData}
							name='Youtube'
							category='Kênh của bạn'
							img={YoutubeIcon}
							type='youtube'
						/>
					</div>
					<div className='col-12 col-md-6 col-lg-3'>
						<AppItem
							onSubmit={getData}
							name='Instagram'
							category='Tài khoản kinh doanh'
							img={InstagramIcon}
							type='instagram'
						/>
					</div>
					<div className='col-12 col-md-6 col-lg-3'>
						<AppItem
							onSubmit={getData}
							name='Twitter'
							category='Tài khoản kinh doanh'
							img={TwitterIcon}
							type='twitter'
						/>
					</div>
					<div className='col-12 col-md-6 col-lg-3'>
						<AppItem
							onSubmit={getData}
							name='Telegram'
							category='Nhóm của bạn'
							img={telegramIcon}
							type='telegram'
						/>
					</div>
				</div>
			</Page>
			<Modal
				isCentered
				isOpen={state}
				setIsOpen={setState}
				titleId='exampleModalLabel'
				isStaticBackdrop={staticBackDropStatus}>
				<ModalHeader setIsOpen={headerCloseStatus ? setState : undefined}>
					<ModalTitle id='exampleModalLabel'>Thêm tài khoản {activeTab}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex justify-content-center'>
						<img
							src={fbIcon}
							alt=''
							style={{ maxHeight: '100px', maxWidth: '100px' }}
						/>
					</div>
					<div className='token'>
						{activeTab == 'facebook' && (
							<>
								{loadingButton ? (
									<Button
										style={{
											marginTop: '10px',
										}}
										className='w-100 py-2 text-uppercase fs-4 d-flex align-items-center justify-content-center'
										color='info'
										isActive>
										<Spinner isSmall inButton />
										Loading...
									</Button>
								) : (
									<div
										onClick={() => {
											setLoadingButton(true);
										}}>
										<FacebookLogin
											scope={scope}
											appId={process.env.REACT_APP_APP_KEY}
											autoLoad={false}
											fields='name,email,picture'
											callback={responseFacebook}
											cssClass='facebook__login__button'
											icon='fa-facebook'
										/>
									</div>
								)}
							</>
						)}
					</div>
				</ModalBody>
			</Modal>
			<Modal
				isCentered
				isOpen={igState}
				setIsOpen={setIgState}
				titleId='exampleModalLabel'
				isStaticBackdrop={staticBackDropStatus}>
				<ModalHeader setIsOpen={headerCloseStatus ? setIgState : undefined}>
					<ModalTitle id='exampleModalLabel'>Thêm tài khoản {activeTab}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex justify-content-center mb-3'>
						<img
							src={InstagramIcon}
							alt=''
							style={{ maxHeight: '100px', maxWidth: '100px' }}
						/>
					</div>
					<p className='fw-bold' style={{ fontSize: '1rem' }}>
						Hiện tại, chỉ được hỗ trợ các tài khoản Instagram Business & Creator đã được
						kết nối với một Trang Facebook.
					</p>
					<p className='fw-bold' style={{ fontSize: '1rem' }}>
						Để chuyển đổi tài khoản Instagram cá nhân của bạn thành tài khoản Business
						hoặc Creator, hãy nhấn{' '}
						<a
							target='_blank'
							className='text-info text-decoration-none'
							href='https://help.instagram.com/502981923235522'>
							vào đây.
						</a>
					</p>
					<p className='fw-bold' style={{ fontSize: '1rem' }}>
						Để kết nối Trang Facebook của bạn với tài khoản Instagram Business hoặc
						Creator, hãy nhấn{' '}
						<a
							target='_blank'
							className='text-link text-decoration-none'
							href='https://help.instagram.com/570895513091465'>
							vào đây.
						</a>
					</p>
					{loadingButton ? (
						<Button className='w-100 py-2 text-uppercase fs-4' color='danger'>
							<Spinner isSmall inButton />
							Loading...
						</Button>
					) : (
						<InstagramLogin onSubmit={getInstagramLogin} />
					)}
				</ModalBody>
			</Modal>
			<Modal
				isCentered
				isOpen={youtubeState}
				setIsOpen={setYoutubeState}
				titleId='exampleModalLabel'
				isStaticBackdrop={staticBackDropStatus}>
				<ModalHeader setIsOpen={headerCloseStatus ? setYoutubeState : undefined}>
					<ModalTitle id='exampleModalLabel'>Thêm tài khoản {activeTab}</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex justify-content-center'>
						<img src={YoutubeImg} alt='' />
					</div>
					<div className='text-center mt-4'>
						AMAI CONTENT sử dụng Dịch vụ API của Youtube để đăng bài và quản lý bài đăng
						trên kênh Youtube của bạn
					</div>
					<div className='col-12 mt-4'>
						{/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
						<Button
							isOutline
							color={darkModeStatus ? 'light' : 'dark'}
							className={classNames('w-100 py-3', {
								'border-light': !darkModeStatus,
								'border-dark': darkModeStatus,
							})}
							icon='CustomGoogle'
							onClick={() => {
								handleLogin();
							}}>
							Continue with Google
						</Button>
					</div>
					<div className='text-center mt-4'>
						Bạn có thể thu hồi quyền truy cập của ứng dụng{' '}
						<a
							href='https://myaccount.google.com/connections?filters=3,4&hl=vi'
							target='_blank'
							className={`${darkModeStatus ? 'text-white' : 'text-dark'} fw-bold`}>
							tại đây.
						</a>
					</div>
					<div className='text-center mt-4'>
						Tôi đồng ý với{' '}
						<a
							href='https://myaccount.google.com/connections?filters=3,4&hl='
							target='_blank'
							className={`${darkModeStatus ? 'text-white' : 'text-dark'} fw-bold`}>
							Điều khoản sử dụng Youtube
						</a>{' '}
						và{' '}
						<a
							href='https://policies.google.com/privacy'
							target='_blank'
							className={`${darkModeStatus ? 'text-white' : 'text-dark'} fw-bold`}>
							Chính sách quyền riêng tư của Google.
						</a>
					</div>
				</ModalBody>
			</Modal>
			<Modal
				isCentered
				isOpen={twitterState}
				setIsOpen={setTwitterState}
				titleId='exampleModalLabel'
				isStaticBackdrop={staticBackDropStatus}>
				<ModalHeader setIsOpen={headerCloseStatus ? setTwitterState : undefined}>
					<ModalTitle id='exampleModalLabel'>Thêm tài khoản Twitter</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex justify-content-center'>
						<img
							src={TwitterIcon}
							alt=''
							style={{ maxHeight: '100px', maxWidth: '100px' }}
						/>
					</div>
					<div className='text-center mt-4'>
						AMAI CONTENT sử dụng Dịch vụ API của Twitter để đăng bài và quản lý bài đăng
						trên kênh Youtube của bạn
					</div>
					<div className='col-12 mt-4'>
						{/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}
						{loadingButton ? (
							<Button
								className={classNames('w-100 py-3', {
									'border-light': !darkModeStatus,
									'border-dark': darkModeStatus,
								})}
								color={darkModeStatus ? 'light' : 'dark'}
								isOutline>
								<Spinner isSmall inButton />
								Loading...
							</Button>
						) : (
							<Button
								isOutline
								color={darkModeStatus ? 'light' : 'dark'}
								className={classNames('w-100 py-3', {
									'border-light': !darkModeStatus,
									'border-dark': darkModeStatus,
								})}
								icon='CustomGoogle'
								onClick={() => {
									handleLoginTwitter();
								}}>
								Continue with Google
							</Button>
						)}
					</div>
					<div className='text-center mt-4'>
						Bạn có thể thu hồi quyền truy cập của ứng dụng{' '}
						<a
							href='https://myaccount.google.com/connections?filters=3,4&hl=vi'
							target='_blank'
							className={`${darkModeStatus ? 'text-white' : 'text-dark'} fw-bold`}>
							tại đây.
						</a>
					</div>
					<div className='text-center mt-4'>
						Tôi đồng ý với{' '}
						<a
							href='https://myaccount.google.com/connections?filters=3,4&hl='
							target='_blank'
							className={`${darkModeStatus ? 'text-white' : 'text-dark'} fw-bold`}>
							Điều khoản sử dụng twitter
						</a>{' '}
						và{' '}
						<a
							href='https://policies.google.com/privacy'
							target='_blank'
							className={`${darkModeStatus ? 'text-white' : 'text-dark'} fw-bold`}>
							Chính sách quyền riêng tư của Google.
						</a>
					</div>
				</ModalBody>
			</Modal>
			<Modal
				isCentered
				isOpen={telegramState}
				setIsOpen={setTelegramState}
				titleId='exampleModalLabel'
				isStaticBackdrop={staticBackDropStatus}>
				<ModalHeader setIsOpen={headerCloseStatus ? setTelegramState : undefined}>
					<ModalTitle id='exampleModalLabel'>Thêm tài khoản Telegram</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='d-flex justify-content-center'>
						<img
							src={telegramIcon}
							alt=''
							style={{ maxHeight: '100px', maxWidth: '100px' }}
						/>
					</div>
					<FormGroup>
						<Label
							id='editlink'
							className={classNames(
								{
									'text-white': themeStatus == 'dark',
									'text-dark': themeStatus == 'white',
								},
								'fw-bolder',
							)}>
							Telegram Group hoặc Channel
						</Label>
						<Input
							type='text'
							id='telegramGroup'
							placeholder='Username hoặc @dat_prs invite link'
							onChange={formik.handleChange}
							value={formik.values.telegramGroup}></Input>
					</FormGroup>
					<div className='col-12 mt-3'>
						{loadingButton ? (
							<Button
								className='w-100 py-2 text-uppercase fs-4 d-flex align-items-center justify-content-center'
								color='warning'
								isLight>
								<Spinner isSmall inButton />
								Loading...
							</Button>
						) : (
							<Button
								className='w-100 py-2 text-uppercase fs-4'
								color='info'
								onClick={handleClick}
								// isLight
							>
								Thêm tài khoản
							</Button>
						)}
					</div>
					<div className='text-center mt-2'>
						Bạn bắt buộc phải cấp quyền admin cho{' '}
						<a
							href='https://t.me/datprs_bot'
							target='_blank'
							className='text-dark fw-bold'>
							@datprs_bot
						</a>
					</div>
				</ModalBody>
			</Modal>
		</PageWrapper>
	);
};

export default LoginAppView;
