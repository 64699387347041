/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
    name: 'auth',
    initialState:{
        fbLogin:{
            loginState: false,
            currentUser: {},
        },
        igLogin:{
            loginState: false,
            currentUser: {},
        },
        login:{
            currentUser: null,
            isFetching: false,
            error: false,
        },
        register:{
            isFetching: false,
            error: false,
            success: false,
        },
        isActiveExtension: null,
    },
    reducers:{
        fbLoginStart: (state, action) => {
            state.fbLogin.loginState = true;
            state.fbLogin.currentUser = action.payload;
        },
        fbLoginEnd: (state) => {
            state.fbLogin.loginState = false;
        },
        igLoginStart: (state, action) => {
            state.igLogin.loginState = true;
            state.igLogin.currentUser = action.payload;
        },
        igLoginEnd: (state) => {
            state.igLogin.loginState = false;
        },
        loginStart: (state) => {
            state.login.isFetching = true;
        },
        loginSuccess: (state, action) =>{
            state.login.isFetching = false;
            state.login.currentUser = action.payload;
            state.login.error = false;
        },
        loginFailed: (state) => {
            state.login.isFetching = false;
            state.login.error = true;
        },
        registerStart: (state) => {
            state.register.isFetching = true;
        },
        registerSuccess: (state) =>{
            state.register.isFetching = false;
            state.register.error = false;
            state.register.success = true;
        },
        registerFailed: (state) => {
            state.register.isFetching = false;
            state.register.error = true;
            state.register.success = false;
        },
        extensionCheck: (state, action) => {
            state.isActiveExtension = action.payload
        }
    }
})
export const {
    loginStart,
    loginSuccess,
    loginFailed,
    registerFailed,
    registerSuccess,
    registerStart,
    fbLoginStart,
    fbLoginEnd,
    igLoginStart,
    igLoginEnd,
    extensionCheck,
} = authSlice.actions;
export default authSlice.reducer;