/* eslint-disable prettier/prettier */
import { createSlice } from "@reduxjs/toolkit";

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState:{
        workspaceValue: window.localStorage.getItem('workspaceId') ? window.localStorage.getItem('workspaceId') : 1,
        workspaceData: [],
    },
    reducers:{
        getWorkSpaceSuccess: (state, action) => {
            state.workspaceData = action.payload;
            state.workspaceValue = action.payload;
        },
        getWorkSpaceValueSuccess: (state, action) => {
            state.workspaceValue = action.payload;
        },
        changeWorkSpaceValue: (state,action) => {
            state.workspaceValue = action.payload;
        },
        addWorkSpaceSuccess: (state, action) => {
            state.workspaceData = [...state.workspaceData,action.payload];
        },
        removeWorkSpaceSuccess: (state, action) => {
            state.workspaceData = state.workspaceData.filter(item => item.id !== action.payload);
        }
    }
});
export const {
    changeWorkSpaceValue,
    getWorkSpaceSuccess,
    getWorkSpaceValueSuccess,
    addWorkSpaceSuccess,
    removeWorkSpaceSuccess,
} = workspaceSlice.actions;
export default workspaceSlice.reducer;