import React, { FC } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from '../../components/icon/Icon';
import Logo from '../../components/Logo';

interface IBrandProps {
	asideStatus: boolean;
	setAsideStatus(...args: unknown[]): unknown;
}
const Brand: FC<IBrandProps> = ({ asideStatus, setAsideStatus }) => {
	return (
		<div className='brand d-flex'>
			<div className='brand-logo'>
				<h1 className='ms-3 brand-title d-flex align-items-end'>
					<Link to='/System' aria-label='Logo'>
					<Logo width={120} />
					{/* <div style={{ color: '#003B67' }} className='fs-5 fw-bold'>
						Amai Content
					</div> */}
					</Link>
				</h1>
			</div>
			<button
				type='button'
				className='btn brand-aside-toggle mt-2'
				aria-label='Toggle Aside'
				onClick={() => setAsideStatus(!asideStatus)}>
				<Icon
					style={{ color: '#fff' }}
					// style={{ color: '#003B67' }}
					icon='FirstPage'
					className='brand-aside-toggle-close'
				/>
				<Icon
					style={{ color: '#fff' }}
					// style={{ color: '#003B67' }}
					icon='LastPage'
					className='brand-aside-toggle-open'
				/>
			</button>
		</div>
	);
};
Brand.propTypes = {
	asideStatus: PropTypes.bool.isRequired,
	setAsideStatus: PropTypes.func.isRequired,
};

export default Brand;
