import React from 'react';
import './forgot-password.css';
import auth_image from '../../../assets/img/login/auth-image.png';
import amai_logo from '../../../assets/img/login/coco-logo.png';
import { useMedia } from 'react-use';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { sendPost } from '../../../api/axiosClients';
import showNotification from '../../../components/extras/showNotification';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { sendMailForgotPassword } from '../../../api/auth';
import axios from 'axios';

const ForgotPassword = () => {
	const isWide = useMedia('(min-width: 600px)');
	const location = useLocation();
	const navigate = useNavigate();

	const isHaveToken = location?.search.substring(1).startsWith('token=') ? true : false;
	const formikForgotPassword = useFormik({
		enableReinitialize: true,
		initialValues: {
			email: '',
			token: '',
			password: '',
			confirmPassword: '',
		},
	});

	const sendMail = async () => {
		// showNotification('', input.email, 'danger');
		const input = {
			email: formikForgotPassword.values.email,
		};
		try {
			const res = await sendPost('/auth/forgot-pass', input);
			if (res.status === 200) {
				showNotification('Thành công', res?.message, 'success');
			} else {
				showNotification('Lỗi', res?.message, 'danger');
			}
		} catch (error) {}
	};

	const handleChangePassword = async () => {
		if (formikForgotPassword.values.password === formikForgotPassword.values.confirmPassword) {
			try {
				const params = new URLSearchParams();

				params.append('token', location.search.split('token=').pop().split('&')[0]);
				params.append('email', location.search.split('email=').pop().split(' ')[0]);
				params.append('password', formikForgotPassword.values.password);
				params.append('password_confirm', formikForgotPassword.values.confirmPassword);
				const res = await axios.post(`/auth/reset-pass`, params, {
					baseURL: process.env.REACT_APP_API_URL,
					headers: { 'content-type': 'application/x-www-form-urlencoded' },
				});
				// const res = await sendPost(`/auth/reset-pass`, params);

				// `/auth/reset-pass?${location?.search.substring(1)}&password=${
				// 	formikForgotPassword.values.password
				// }&password_confirm=${formikForgotPassword.values.confirmPassword}`,

				if (res?.data?.status === 200) {
					showNotification('', res?.data?.message, 'success');
					navigate('/login');
				} else {
					showNotification('', res?.data?.message, 'danger');
				}
			} catch (error) {}
		} else {
			showNotification('', 'Mật khẩu bạn nhập chưa trùng nhau', 'danger');
		}
	};

	return (
		<div>
			<div className={`forgot-password-body`}>
				<div className='col-xl-6 col-lg-6 col-md-12 shadow-3d-container auth-input-form'>
					<div className='auth-heading'>Quên mật khẩu?</div>
					<div className='forgot-password-form'>
						{isHaveToken ? (
							<>
								<FormGroup id='password' className='mb-3'>
									<p className='auth-label'>Mật khẩu mới</p>
									<Input
										placeholder='Nhập mật khẩu mới'
										id='password'
										type='password'
										autoComplete='password'
										onChange={formikForgotPassword.handleChange}
										value={formikForgotPassword.values.password}
									/>
								</FormGroup>
								<FormGroup id='confirmPassword' className='mb-3'>
									<p className='auth-label'>Nhập lại mật khẩu</p>
									<Input
										placeholder='Nhập lại mật khẩu mới'
										id='confirmPassword'
										type='password'
										autoComplete='password'
										onChange={formikForgotPassword.handleChange}
										value={formikForgotPassword.values.confirmPassword}
									/>
								</FormGroup>
							</>
						) : (
							<FormGroup id='email' className='mb-3'>
								<p className='auth-label'>Email</p>
								<Input
									placeholder='Nhập Email'
									id='email'
									type='email'
									autoComplete='email'
									onChange={formikForgotPassword.handleChange}
									value={formikForgotPassword.values.email}
								/>
							</FormGroup>
						)}
						<Button
							color='info'
							className='forgot-password-action-button'
							onClick={() => {
								if (isHaveToken) {
									handleChangePassword();
								} else {
									sendMail();
								}
							}}>
							{isHaveToken ? 'Đổi mật khẩu' : 'Lấy lại mật khẩu'}
						</Button>
						<Link to={'/login'} className='forgot-password-link mt-5 text-center'>
							Quay trở lại màn Đăng nhập
						</Link>
					</div>
				</div>
				<div className='col-xl-6 col-lg-6 col-md-12'>
					<div className='image-div'>
						<img src={auth_image} className='auth-image' alt='' />
						<img src={amai_logo} alt='' className='amai-logo' />
					</div>
					<div className='text-center mt-3'>
						<h1 className='mb-2'>Amai Content</h1>
						<p className='fs-5'>
							Giải pháp Tối ưu hóa Nội dung và Quản lý Mạng Xã Hội hoàn hảo
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgotPassword;
