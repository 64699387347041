import React, { createContext, useEffect, useState } from 'react';
import { sendGet } from '../api/axiosClients';

export const NotificationContext = createContext({});

const NotificationsContextProvider = ({ children }) => {
	const [listNotifications, setListNotifications] = useState([]);
	const [selectedNotifications, setSelectedNotifications] = useState(null);
	const [filterType, setFilterType] = useState('All');
	const [unseenNotifications, setUnseenNotifications] = useState(0);

	return (
		<NotificationContext.Provider
			value={{
				listNotifications,
				setListNotifications,
				selectedNotifications,
				setSelectedNotifications,
				filterType,
				setFilterType,
				unseenNotifications,
				setUnseenNotifications,
			}}>
			{children}
		</NotificationContext.Provider>
	);
};

export default NotificationsContextProvider;
