export function test() {
	return null;
}
export function getDate(time: any) {
	const today = new Date();
	const oneWeekAgo = new Date(today);
	if (time) {
		oneWeekAgo.setDate(today.getDate() - time);
	}
	const dd = String(oneWeekAgo.getDate()).padStart(2, '0');
	const mm = String(oneWeekAgo.getMonth() + 1).padStart(2, '0');
	const yyyy = oneWeekAgo.getFullYear();

	return yyyy + '-' + mm + '-' + dd;
}
export function getCurrentDate(time: any) {
	let ngayGoc;
	if (time) {
		ngayGoc = new Date(time);
	} else {
		ngayGoc = new Date();
	}
	ngayGoc.setHours(0, 0, 0, 0);
	// Lấy ngày hiện tại
	const ngayHienTai = new Date();

	// Kiểm tra xem time có phải là ngày hôm nay hay không
	if (
		ngayGoc.getDate() === ngayHienTai.getDate() &&
		ngayGoc.getMonth() === ngayHienTai.getMonth() &&
		ngayGoc.getFullYear() === ngayHienTai.getFullYear()
	) {
		// Thêm 5 phút vào thời gian hiện tại
		ngayHienTai.setMinutes(ngayHienTai.getMinutes() + 1);
		ngayGoc.setTime(ngayHienTai.getTime()); // Sử dụng thời gian đã được cập nhật
	}

	const ngayDaDinhDang =
		ngayGoc.getFullYear() +
		'-' +
		(ngayGoc.getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		ngayGoc.getDate().toString().padStart(2, '0') +
		' ' +
		ngayGoc.getHours().toString().padStart(2, '0') +
		':' +
		ngayGoc.getMinutes().toString().padStart(2, '0');
	return ngayDaDinhDang;
}
export function getOneWeekAgo() {
	const today = new Date();
	const oneWeekAgo = new Date(today);
	oneWeekAgo.setDate(today.getDate() - 7);

	const dd = String(oneWeekAgo.getDate()).padStart(2, '0');
	const mm = String(oneWeekAgo.getMonth() + 1).padStart(2, '0');
	const yyyy = oneWeekAgo.getFullYear();

	return yyyy + '-' + mm + '-' + dd;
}

export function getOS() {
	const { userAgent } = window.navigator;
	const { platform } = window.navigator;
	const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
	const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
	const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
	let os = null;

	if (macosPlatforms.indexOf(platform) !== -1) {
		os = 'MacOS';
	} else if (iosPlatforms.indexOf(platform) !== -1) {
		os = 'iOS';
	} else if (windowsPlatforms.indexOf(platform) !== -1) {
		os = 'Windows';
	} else if (/Android/.test(userAgent)) {
		os = 'Android';
	} else if (!os && /Linux/.test(platform)) {
		os = 'Linux';
	}

	// @ts-ignore
	document.documentElement.setAttribute('os', os);
	return os;
}

export const hasNotch = () => {
	/**
	 * For storybook test
	 */
	const storybook = window.location !== window.parent.location;
	// @ts-ignore
	const iPhone = /iPhone/.test(navigator.userAgent) && !window.MSStream;
	const aspect = window.screen.width / window.screen.height;
	const aspectFrame = window.innerWidth / window.innerHeight;
	return (
		(iPhone && aspect.toFixed(3) === '0.462') ||
		(storybook && aspectFrame.toFixed(3) === '0.462')
	);
};

export const mergeRefs = (refs: any[]) => {
	return (value: any) => {
		refs.forEach((ref) => {
			if (typeof ref === 'function') {
				ref(value);
			} else if (ref != null) {
				ref.current = value;
			}
		});
	};
};

export const randomColor = () => {
	const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger'];

	const color = Math.floor(Math.random() * colors.length);

	return colors[color];
};

export const priceFormat = (price: number) => {
	// return price.toLocaleString('en-US', {
	// 	style: 'currency',
	// 	currency: 'USD',
	// });
	return price?.toLocaleString('de-DE', {
		style: 'currency',
		currency: 'VND',
	});
};

export const average = (array: any[]) => array.reduce((a, b) => a + b) / array.length;

export const percent = (value1: number, value2: number) =>
	Number(((value1 / value2 - 1) * 100).toFixed(2));

export const getFirstLetter = (text: string, letterCount = 2): string =>
	// @ts-ignore
	text
		.toUpperCase()
		.match(/\b(\w)/g)
		.join('')
		.substring(0, letterCount);

export const debounce = (func: (arg0: any) => void, wait = 1000) => {
	let timeout: string | number | NodeJS.Timeout | undefined;

	return function executedFunction(...args: any[]) {
		const later = () => {
			clearTimeout(timeout);
			// @ts-ignore
			func(...args);
		};

		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
	};
};
export function formatTime(time: any) {
	// let ngayGoc = new Date(time);

	// var ngayDaDinhDang =
	//   ngayGoc.getFullYear() +
	//   "-" +
	//   (ngayGoc.getMonth() + 1).toString().padStart(2, "0") +
	//   "-" +
	//   ngayGoc.getDate().toString().padStart(2, "0") +
	//   " " +
	//   ngayGoc.getHours().toString().padStart(2, "0") +
	//   ":" +
	//   ngayGoc.getMinutes().toString().padStart(2, "0");
	// return ngayDaDinhDang;
	// const ngayGoc = new Date(time);
	let ngayGoc;
	if (time) {
		ngayGoc = new Date(time);
	} else {
		ngayGoc = new Date();
	}
	// Lấy ngày hiện tại
	const ngayHienTai = new Date();

	// Kiểm tra xem time có phải là ngày hôm nay hay không
	if (
		ngayGoc.getDate() === ngayHienTai.getDate() &&
		ngayGoc.getMonth() === ngayHienTai.getMonth() &&
		ngayGoc.getFullYear() === ngayHienTai.getFullYear()
	) {
		// Thêm 5 phút vào thời gian hiện tại
		ngayHienTai.setMinutes(ngayHienTai.getMinutes() + 1);
		ngayGoc.setTime(ngayHienTai.getTime()); // Sử dụng thời gian đã được cập nhật
	}

	const ngayDaDinhDang =
		ngayGoc.getFullYear() +
		'-' +
		(ngayGoc.getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		ngayGoc.getDate().toString().padStart(2, '0') +
		' ' +
		ngayGoc.getHours().toString().padStart(2, '0') +
		':' +
		ngayGoc.getMinutes().toString().padStart(2, '0');

	return ngayDaDinhDang;
}
export function formatDate(time: any) {
	// let ngayGoc = new Date(time);

	// var ngayDaDinhDang =
	//   ngayGoc.getFullYear() +
	//   "-" +
	//   (ngayGoc.getMonth() + 1).toString().padStart(2, "0") +
	//   "-" +
	//   ngayGoc.getDate().toString().padStart(2, "0") +
	//   " " +
	//   ngayGoc.getHours().toString().padStart(2, "0") +
	//   ":" +
	//   ngayGoc.getMinutes().toString().padStart(2, "0");
	// return ngayDaDinhDang;
	// const ngayGoc = new Date(time);
	let ngayGoc;
	if (time) {
		ngayGoc = new Date(time);
	} else {
		ngayGoc = new Date();
	}
	// Lấy ngày hiện tại
	const ngayHienTai = new Date();

	// Kiểm tra xem time có phải là ngày hôm nay hay không
	if (
		ngayGoc.getDate() === ngayHienTai.getDate() &&
		ngayGoc.getMonth() === ngayHienTai.getMonth() &&
		ngayGoc.getFullYear() === ngayHienTai.getFullYear()
	) {
		// Thêm 5 phút vào thời gian hiện tại
		ngayHienTai.setMinutes(ngayHienTai.getMinutes() + 1);
		ngayGoc.setTime(ngayHienTai.getTime()); // Sử dụng thời gian đã được cập nhật
	}

	const ngayDaDinhDang =
		ngayGoc.getFullYear() +
		'-' +
		(ngayGoc.getMonth() + 1).toString().padStart(2, '0') +
		'-' +
		ngayGoc.getDate().toString().padStart(2, '0');

	return ngayDaDinhDang;
}
export function formatDateString(inputDate: any) {
	// Chuyển đổi chuỗi ngày từ "yyy-mm-dd" thành đối tượng Date
	const dateParts = inputDate.split('-');
	const year = dateParts[0];
	const month = dateParts[1];
	const day = dateParts[2];
	const dateObject = new Date(year, month - 1, day); // Tháng trong JavaScript bắt đầu từ 0, nên trừ 1.

	// Lấy ngày, tháng và năm
	const formattedDay = dateObject.getDate();
	const formattedMonth = dateObject.getMonth() + 1; // Tháng trong định dạng dd/mm/yyyy bắt đầu từ 1
	const formattedYear = dateObject.getFullYear();

	// Tạo chuỗi định dạng "dd/mm/yyyy"
	const formattedDate = `${formattedDay}/${formattedMonth}/${formattedYear}`;

	return formattedDate;
}

// Inbox functionnnnn
export function formatTimestamp(timestamp : any) {
	const currentDate = new Date();
	const date = new Date(timestamp);

	if (currentDate.toDateString() === date.toDateString()) {
		// Trường hợp trong ngày
		const hours = date.getHours().toString().padStart(2, '0');
		const minutes = date.getMinutes().toString().padStart(2, '0');
		return `${hours}:${minutes}`;
	} else if (
		currentDate.getFullYear().toLocaleString() == date.getFullYear().toLocaleString()
	) {
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const year = date.getFullYear();
		return `${day.toString().padStart(2, '0')} / ${month.toString().padStart(2, '0')}`;
	} else {
		// Trường hợp ngoài ngày
		const month = date.getMonth() + 1;
		const day = date.getDate();
		const year = date.getFullYear();
		return `${day.toString().padStart(2, '0')} / ${month.toString().padStart(2, '0')}`;
	}
}
export function divideArrayByDate(objects: any) {
	let dividedArray = [];
	let currentDate = null;

	for (let i = 0; i < objects.length; i++) {
		const objectDate = new Date(objects[i].created_time);
		if (!currentDate || !isSameDate(currentDate, objectDate)) {
			// Nếu đây là object đầu tiên hoặc ngày tạo của object hiện tại khác với ngày trước đó
			// Thêm object "divide" với createdAt là ngày của object hiện tại
			if (currentDate) {
				const convertDate = new Date(currentDate);

				// Sao chép currentDate để tránh thay đổi trực tiếp
				const nextDay = new Date(convertDate);

				// Tăng ngày lên 1
				nextDay.setDate(convertDate.getDate() + 1);

				// Chuyển đổi thành chuỗi ISO
				const nextDayISOString = nextDay.toISOString();
				const index = dividedArray.findIndex((item) => item.id == objects[i].id);
				if (index == -1) {
					dividedArray.push({
						divide: true,
						createdAt: nextDayISOString, // hoặc bạn có thể format ngày theo cách bạn muốn
					});
				}
			}
			dividedArray.push(objects[i]);
			currentDate = objectDate;
		} else {
			// Nếu ngày tạo của object hiện tại giống với ngày tạo của object trước đó
			// Thêm object vào mảng mà không cần thêm object "divide"
			dividedArray.push(objects[i]);
		}
	}

	return dividedArray;
}
export function isSameDate(date1:any, date2:any) {
	return date1.toDateString() === date2.toDateString();
}
export const replaceClosestSlash = (replacementString:any, textInput: any ) => {
	const indexOfLastSlash = textInput.lastIndexOf('/');
	if (indexOfLastSlash !== -1) {
		const newString =
			textInput.substring(0, indexOfLastSlash) +
			replacementString +
			textInput.substring(indexOfLastSlash + 1);
		return newString;
	}
	return textInput;
};
export const getStringAfterLastSlash = (inputString: any) => {
	const indexOfLastSlash = inputString.lastIndexOf('/');
	if (indexOfLastSlash !== -1) {
		return inputString.substring(indexOfLastSlash + 1);
	} else {
		// Trả về chuỗi ban đầu nếu không có dấu '/' trong chuỗi
		return inputString;
	}
};
export function chunkArray(array:any, chunkSize: any) {
	const result = [];
	for (let i = 0; i < array.length; i += chunkSize) {
		result.push(array.slice(i, i + chunkSize));
	}
	return result;
}