/* eslint-disable prettier/prettier */
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ThemeContext from '../../contexts/themeContext';
import { adminMenu, dashboardMenu, trendingMenu } from '../../menu';
import Brand from '../Brand/Brand';
import Navigation, { NavigationLine } from '../Navigation/Navigation';

import { useDispatch, useSelector } from 'react-redux';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import useDarkMode from '../../hooks/useDarkMode';

const Aside = () => {
	const token = window.localStorage.getItem('accessToken');
	const dispatch = useDispatch();
	const newDashboardMenu = {
		...dashboardMenu,
	};
	const newAdmindMenu = {
		...dashboardMenu,
	};
	delete newAdmindMenu.landing;
	delete newDashboardMenu.adminManger;
	delete newDashboardMenu.landing;
	// useEffect(() => {
	// 	getUser(token, dispatch);
	// 	console.log(dashboardMenu);
	// }, []);
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	const userProfile = useSelector((state) => state.user.userProfile);
	const userCurrent = userProfile.currentUser;
	const userCurrentRole = userCurrent?.roles[0];
	function filterObject(obj, keysToRemove) {
		return Object.fromEntries(
			Object.entries(obj).filter(([key, value]) => !keysToRemove.includes(key)),
		);
	}
	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					<NavigationLine />
					{userCurrentRole === 'admin' && (
						<>
							<Navigation menu={adminMenu} id='admin-menu' />
							<NavigationLine />
						</>
					)}
					<Navigation menu={userCurrentRole == 'client' ? filterObject(trendingMenu, ['competitor']) : trendingMenu} id='trending-menu' />
					{userCurrentRole === 'admin' ? (
						<Navigation menu={newAdmindMenu} id='aside-dashboard' />
					) : (
						<Navigation
							menu={
								userCurrentRole == 'staff'
									? filterObject(newDashboardMenu, ['memberpage', 'paymentpage'])
									: (userCurrentRole == 'client'
									? filterObject(newDashboardMenu, [
											'memberpage',
											'paymentpage',
											'competitor',
											'followlistpage',
											'bonus',
											'autocomment',
											'uploadpage',
											'postauto',
											'autoreaction',
									  ])
									: newDashboardMenu)
							}
							id='aside-dashboard'
						/>
					)}
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
